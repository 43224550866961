import create from 'zustand';
import { compact, filter, flatMap, pipe, toArray } from '@fxts/core';

import {
    CCAdministrativeQuery,
    CCAdministrativeRo,
    CCServiceRo,
    CC_FAMILY,
    CC_STATUS,
    CollectConfigQuery,
    CollectRo,
    CollectorRo,
    CustomerRo,
    LandfillRo,
    PlanningGetAllQuery,
    PlanningRo,
    PlanningShiftStepService,
    ProductRo,
    TruckRo,
    AdminRo,
    LandfillQuery,
    PlanningRoFront,
    PlanningCalculateDto,
    EPlanningCalculMethod,
    CalculatedPlanningRo,
    IKafkaQuery,
    ZoneRo,
    ZoneGetAllQuery,
    PRODUCT_DUMPSTER_TYPE,
    EPlanningType,
    PlanningShiftStepAdministrative
} from '@bbng/util/types';

import { urlApiBuilder } from '../../common/urlBuilder';
import { useRequest } from '../../hooks/StatelessRequest';
import { initialState as planningPageInitialState, PlanningPageState } from '../../pages/Planning/helpers';
import equal from 'fast-deep-equal/react';
import { mapify } from '@bbng/util/misc';
import { EPlanningFormSubmitAction, mapApiDataToState, PlanningFormState } from '../../modules/planning-form/helpers';

export type PlanningV2Store = {
    plannings: Record<string, PlanningRo>;
    trucks: Record<string, TruckRo>;
    admins: Record<string, AdminRo>;
    trucksByPlanning: Record<string /*planning id*/, TruckRo>;
    collectors: Record<string, CollectorRo>;
    collectorsByPlanning: Record<string /*planning id*/, CollectorRo>;
    customers: Record<string, CustomerRo>;
    landfills: Record<string, LandfillRo>;
    ccsService: Record<string, CCServiceRo>;
    ccsAdministrative: Record<string, CCAdministrativeRo>;
    collects: Record<string, CollectRo>;
    products: Record<string, ProductRo>;
    zones: Record<string, ZoneRo>;

    getPlannings: (req: ReturnType<typeof useRequest>, fromCalculate?: boolean) => Promise<void>;
    getCCService: (req: ReturnType<typeof useRequest>) => Promise<void>;
    getCCAdministrative: (req: ReturnType<typeof useRequest>) => Promise<void>;
    getTrucks: (req: ReturnType<typeof useRequest>) => Promise<void>;
    getAdmins: (req: ReturnType<typeof useRequest>) => Promise<void>;
    getCollectors: (req: ReturnType<typeof useRequest>) => Promise<void>;
    getCustomers: (req: ReturnType<typeof useRequest>) => Promise<void>;
    getLandfills: (req: ReturnType<typeof useRequest>) => Promise<void>;
    getCollects: (req: ReturnType<typeof useRequest>) => Promise<void>;
    getProducts: (req: ReturnType<typeof useRequest>) => Promise<void>;
    getZones: (req: ReturnType<typeof useRequest>) => Promise<void>;

    calculatePlannings: (req: ReturnType<typeof useRequest>, dto: PlanningCalculateDto) => Promise<void>;

    fetchAll: (req: ReturnType<typeof useRequest>) => Promise<void>;

    unnassignedCCService: CCServiceRo[];
    unnassignedCCAdministrative: CCAdministrativeRo[];
    getUnnassignedCCService: () => void;
    getUnnassignedCCAdministrative: () => void;

    planningPageState: PlanningPageState;
    setPlanningPageState: React.Dispatch<React.SetStateAction<PlanningPageState>>;

    calculationTriggered: boolean;
    setCalculationTriggered: (state: boolean) => void;

    lastAction: EPlanningFormSubmitAction;
    setLastAction: (action: EPlanningFormSubmitAction) => void;

    calculatedPlanningsState: PlanningFormState;
    setCalculatedPlanningsState: (state: PlanningFormState) => void;

    isCompactMode: boolean;
    setCompactMode: (state: boolean) => void;

    dumpsterTypes: PRODUCT_DUMPSTER_TYPE[];
    setDumpsterTypes: (state: PRODUCT_DUMPSTER_TYPE[]) => void;

    additionalInfoIsOpen: boolean;
    setAdditionalInfoIsOpen: (state: boolean) => void;
};

export const usePlanningV2Store = create<PlanningV2Store>((set, get) => ({
    plannings                   : {},
    trucks                      : {},
    admins                      : {},
    trucksByPlanning            : {},
    collectors                  : {},
    collectorsByPlanning        : {},
    customers                   : {},
    constructionSites           : {},
    landfills                   : {},
    ccsService                  : {},
    ccsAdministrative           : {},
    collects                    : {},
    unnassignedCCService        : [],
    unnassignedCCAdministrative : [],
    products                    : {},
    zones                       : {},
    planningPageState           : planningPageInitialState,
    dumpsterTypes               : [],
    setDumpsterTypes            : (state) => {
        set({
            dumpsterTypes: state
        });
    },
    setPlanningPageState: (state) => {
        const { planningPageState } = get();
        const newState = typeof state === 'function' ? state(planningPageState) : state;
        if (!equal(planningPageState, newState)) {
            let updateDumpsterType = false;
            let newDumpsterType: PRODUCT_DUMPSTER_TYPE[] = [];
            /**
             * Reset dumpster types each time planning type changes.
             */
            if (newState.type !== planningPageState.type) {
                updateDumpsterType = true;
                if (newState.type === EPlanningType.BIG_BAG) {
                    newDumpsterType = [];
                } else {
                    newDumpsterType = Object.values(PRODUCT_DUMPSTER_TYPE);
                }
            }
            set({
                planningPageState: newState,
                ...(updateDumpsterType && {
                    dumpsterTypes: newDumpsterType
                })
            });
        }
    },
    getPlannings: async (req, fromCalculate = false) => {
        const { planningPageState } = get();
        const plannings = await req<PlanningRo[]>({
            sync    : false,
            method  : 'GET',
            url     : urlApiBuilder.planningGetAll(),
            payload : {
                queryParams: {
                    region            : planningPageState.region,
                    type              : planningPageState.type,
                    day               : planningPageState.day,
                    redis_version_key : planningPageState.redis_version_description.key,
                    no_limit          : true
                } as PlanningGetAllQuery
            },
            options      : { toastifySuccess: false },
            retryPolling : 1000
        });
        if (plannings.success && plannings.response && plannings.response.data && plannings.response.data.ro) {
            const planningsRo = plannings.response.data.ro;
            if (planningsRo.length > 0) {
                const version = (planningsRo[0] as PlanningRoFront).planning_version_description;
                if (version) {
                    set({
                        planningPageState: {
                            ...planningPageState,
                            redis_version_description: version
                        }
                    });
                }
            }
            const formattedPlanning = planningsRo.reduce((acc, planning) => {
                acc[planning.id] = planning;
                return acc;
            }, {} as Record<string, PlanningRo>);
            set({ plannings: formattedPlanning });
            if (fromCalculate) {
                get().getUnnassignedCCService();
                get().getUnnassignedCCAdministrative();
            }
        }
    },
    getTrucks: async (req) => {
        const plannings = get().plannings;
        const truckId = Object.values(plannings).flatMap((p) => p.truck_id);
        const uniqueIds = [...new Set<string>(truckId)];

        if (uniqueIds.length > 0) {
            const trucks = await req<TruckRo[]>({
                sync    : true,
                method  : 'POST',
                url     : urlApiBuilder.truckGetMany(),
                payload : {
                    body: {
                        ids: uniqueIds
                    }
                },
                options: { toastifySuccess: false }
            });
            if (trucks.success && trucks.response && trucks.response.data && trucks.response.data.ro) {
                const fastAccessTrucks = mapify(trucks.response.data.ro, 'id');
                const trucksByPlanningId = Object.values(plannings).reduce((acc, planning) => {
                    const truckInPlanning = fastAccessTrucks[planning.truck_id[0]];
                    truckInPlanning && (acc[planning.id] = truckInPlanning);
                    return acc;
                }, {} as Record<string, TruckRo>);
                set({
                    trucks           : fastAccessTrucks,
                    trucksByPlanning : trucksByPlanningId
                });
            }
        } else {
            set({
                trucks           : {},
                trucksByPlanning : {}
            });
        }
    },
    getAdmins: async (req) => {
        const response = await req<AdminRo[]>({
            sync    : true,
            method  : 'GET',
            url     : urlApiBuilder.adminGetAll(),
            options : { toastifySuccess: false },
            payload : {
                queryParams: {
                    no_limit: true
                } as IKafkaQuery
            }
        });
        if (response.success && response.response && response.response.data && response.response.data.ro) {
            const adminList = response.response.data.ro;
            const fastAccessAdmins = mapify(adminList, 'id');
            set({
                admins: fastAccessAdmins
            });
        }
    },
    getCollectors: async (req) => {
        const plannings = get().plannings;
        const collectorId = Object.values(plannings).flatMap((p) => p.collector_id);
        const uniqueIds = [...new Set<string>(collectorId)];

        if (uniqueIds.length > 0) {
            const collectors = await req<CollectorRo[]>({
                sync    : true,
                method  : 'POST',
                url     : urlApiBuilder.collectorGetMany(),
                payload : {
                    body: {
                        ids: uniqueIds
                    }
                },
                options: { toastifySuccess: false }
            });
            if (collectors.success && collectors.response && collectors.response.data && collectors.response.data.ro) {
                const fastAccessCollectors = mapify(collectors.response.data.ro, 'id');
                const collectorsByPlanningId = Object.values(plannings).reduce((acc, planning) => {
                    const collectorInPlanning = fastAccessCollectors[planning.collector_id[0]];
                    collectorInPlanning && (acc[planning.id] = collectorInPlanning);
                    return acc;
                }, {} as Record<string, CollectorRo>);
                set({
                    collectors           : fastAccessCollectors,
                    collectorsByPlanning : collectorsByPlanningId
                });
            }
        } else {
            set({
                collectors           : {},
                collectorsByPlanning : {}
            });
        }
    },
    getCCService: async (req) => {
        const { planningPageState } = get();
        const ccsService = await req<CCServiceRo[]>({
            sync    : true,
            method  : 'GET',
            url     : urlApiBuilder.collectConfigReadAll(),
            payload : {
                queryParams: {
                    region      : planningPageState.region,
                    type        : planningPageState.type,
                    day         : planningPageState.day,
                    no_delivery : true,
                    uncancelled : true,
                    no_archived : true,
                    no_limit    : true
                } as CollectConfigQuery
            },
            options: { toastifySuccess: false }
        });

        if (ccsService.success && ccsService.response && ccsService.response.data && ccsService.response.data.ro) {
            const formattedCCsService = mapify(ccsService.response.data.ro, 'id');
            set({ ccsService: formattedCCsService });
            get().getUnnassignedCCService();
        }
    },
    getCCAdministrative: async (req) => {
        const { planningPageState } = get();
        const ccsAdministrative = await req<CCAdministrativeRo[]>({
            sync    : true,
            method  : 'GET',
            url     : urlApiBuilder.ccAdministrativeReadAll(),
            payload : {
                queryParams: {
                    region      : planningPageState.region,
                    type        : planningPageState.type,
                    day         : planningPageState.day,
                    no_archived : true,
                    no_limit    : true
                } as CCAdministrativeQuery
            },
            options: { toastifySuccess: false }
        });
        if (
            ccsAdministrative.success &&
            ccsAdministrative.response &&
            ccsAdministrative.response.data &&
            ccsAdministrative.response.data.ro
        ) {
            const formattedCCsAdministrative = mapify(ccsAdministrative.response.data.ro, 'id');
            set({ ccsAdministrative: formattedCCsAdministrative });
            get().getUnnassignedCCAdministrative();
        }
    },
    getCustomers: async (req) => {
        const ccs = get().ccsService;
        const customerId = Object.values(ccs).flatMap((cc) => cc.customer_id);
        const uniqueIds = [...new Set<string>(customerId)];

        if (uniqueIds.length > 0) {
            const customers = await req<CustomerRo[]>({
                sync    : true,
                method  : 'POST',
                url     : urlApiBuilder.customerGetMany(),
                payload : {
                    body: {
                        ids: uniqueIds
                    }
                },
                options: { toastifySuccess: false }
            });
            if (customers.success && customers.response && customers.response.data && customers.response.data.ro) {
                const formattedCustomers = mapify(customers.response.data.ro, 'id');
                set({ customers: formattedCustomers });
            }
        } else {
            set({ customers: {} });
        }
    },
    getLandfills: async (req) => {
        const { planningPageState } = get();
        const landfills = await req<LandfillRo[]>({
            sync    : true,
            method  : 'GET',
            url     : urlApiBuilder.landfillGetAll(),
            payload : {
                queryParams: {
                    region   : planningPageState.region,
                    type     : planningPageState.type,
                    no_limit : true
                } as LandfillQuery
            },
            options: { toastifySuccess: false }
        });

        if (landfills.success && landfills.response && landfills.response.data && landfills.response.data.ro) {
            const formattedLandfills = mapify(landfills.response.data.ro, 'id');
            set({ landfills: formattedLandfills });
        }
    },
    getZones: async (req) => {
        const { planningPageState } = get();
        const zones = await req<ZoneRo[]>({
            sync    : true,
            method  : 'GET',
            url     : urlApiBuilder.zoneGetAll(),
            payload : {
                queryParams: {
                    metropole : planningPageState.region,
                    no_limit  : true
                } as ZoneGetAllQuery
            },
            options: { toastifySuccess: false }
        });

        if (zones.success && zones.response && zones.response.data && zones.response.data.ro) {
            const formattedZones = mapify(zones.response.data.ro, 'id');
            set({ zones: formattedZones });
        }
    },
    getCollects: async (req) => {
        const plannings = get().plannings;
        const collects = Object.values(plannings).flatMap((p) => {
            const allSteps = [
                ...p.shift.steps_emptying,
                ...p.shift.steps_service,
                ...p.shift.steps_administrative,
                ...p.shift.steps_driver
            ]
                .map((s) => s.collect_id)
                .filter((c): c is string => !!c);
            return allSteps;
        });
        const uniqueIds = [...new Set<string>(collects)];

        if (uniqueIds.length > 0) {
            const collectsRo = await req<CollectRo[]>({
                sync    : true,
                method  : 'POST',
                url     : urlApiBuilder.collectGetMany(),
                payload : {
                    body: {
                        ids: uniqueIds
                    }
                },
                options: { toastifySuccess: false }
            });

            if (collectsRo.success && collectsRo.response && collectsRo.response.data && collectsRo.response.data.ro) {
                const formattedCollects = mapify(collectsRo.response.data.ro, 'id');
                set({ collects: formattedCollects });
            }
        } else {
            set({ collects: {} });
        }
    },

    calculatePlannings: async (req, dto) => {
        get().setCalculationTriggered(true);
        get().setLastAction(EPlanningFormSubmitAction.CALCULATE);

        const response = await req<CalculatedPlanningRo[]>({
            method : 'PATCH',
            url    :
                dto.calcul_method === EPlanningCalculMethod.Classic
                    ? urlApiBuilder.planningCalculate()
                    : urlApiBuilder.planningCustomCalculate(),
            sync         : false,
            retryPolling : 1000,
            payload      : {
                body: dto
            }
        });

        if (response.success && response.response?.data.ro) {
            const calculatedPlannings = response.response.data.ro;

            if (calculatedPlannings?.length > 0) {
                const version = calculatedPlannings[0].planning_version_description;
                version &&
                    get().setPlanningPageState((curr) => ({
                        ...curr,
                        redis_version_description: version
                    }));
            }
            set({ plannings: mapify(calculatedPlannings, 'id') });

            await get().getCollectors(req); // collectors must be fetched again as we may have added a new one on the last calculation
            get().getUnnassignedCCService();
            get().getUnnassignedCCAdministrative();
        }
    },

    fetchAll: async (req) => {
        await Promise.all([
            get().getPlannings(req),
            get().getCCService(req),
            get().getCCAdministrative(req),
            get().getProducts(req),
            get().getAdmins(req)
        ]);
        if (Object.keys(get().plannings).length > 0) {
            await Promise.all([
                get().getTrucks(req),
                get().getCollectors(req),
                get().getCustomers(req),
                get().getCollects(req),
                get().getZones(req)
            ]);
            await Promise.all([get().getLandfills(req)]);
        }

        /**
         * This must be called after planning & cc fetch as it depends on them
         */
        get().getUnnassignedCCService();
        get().getUnnassignedCCAdministrative();

        console.log({
            plannings                   : get().plannings,
            admins                      : get().admins,
            collectors                  : get().collectors,
            trucks                      : get().trucks,
            ccsService                  : get().ccsService,
            ccsAdministrative           : get().ccsAdministrative,
            customers                   : get().customers,
            landfills                   : get().landfills,
            collects                    : get().collects,
            unnassignedCCService        : get().unnassignedCCService,
            unnassignedCCAdministrative : get().unnassignedCCAdministrative,
            products                    : get().products
        });
    },
    getUnnassignedCCService: () => {
        const ccs = Object.values(get().ccsService);
        const plannings = Object.values(get().plannings);

        if (!ccs) return [];

        const { STEP_SERVICES, STEP_SPLITTED_SERVICES } = plannings.reduce(
            (acc, cur) => {
                const assigned_service_ccs = cur.shift?.steps_service || [];
                assigned_service_ccs.forEach((step) => {
                    if (step.is_splitted) {
                        acc.STEP_SPLITTED_SERVICES.push(step);
                    } else {
                        acc.STEP_SERVICES.push(step);
                    }
                });
                return acc;
            },
            {
                STEP_SERVICES          : [] as PlanningShiftStepService[],
                STEP_SPLITTED_SERVICES : [] as PlanningShiftStepService[]
            }
        );

        const forbiddenStatus = [
            CC_STATUS.CANCELED,
            CC_STATUS.FINISHED,
            CC_STATUS.HAZARD,
            CC_STATUS.ORDER_TO_PAY,
            CC_STATUS.WAITING_FOR_APPROVAL
        ];

        const TO_PLAN_SERVICES = pipe(
            ccs,
            filter((cc): cc is CCServiceRo => cc.family !== CC_FAMILY.ADMINISTRATIVE),
            filter((cc) => !forbiddenStatus.includes(cc.status as any)),
            filter((cc) => cc.status !== CC_STATUS.SPLITTED),
            filter((cc) => {
                const found = STEP_SERVICES.find((step) => step.collect_config_id === cc.id);
                return !found;
            }),
            toArray
        );

        const TO_PLAN_SPLITTED_SERVICE = pipe(
            ccs,
            filter((cc): cc is CCServiceRo => cc.family !== CC_FAMILY.ADMINISTRATIVE),
            filter((cc) => !forbiddenStatus.includes(cc.status as any)),
            filter((cc) => cc.status === CC_STATUS.SPLITTED),
            flatMap((cc) => {
                return cc.splitted_informations.map((splitted_info) => {
                    return {
                        ...cc,
                        products     : splitted_info.products,
                        status       : splitted_info.status,
                        splitted_idx : splitted_info.idx,
                        is_splitted  : true
                    };
                });
            }),
            filter((cc) => !forbiddenStatus.includes(cc.status as any)),
            flatMap((cc) => {
                const foundInAssigned = STEP_SPLITTED_SERVICES.find(
                    (step) =>
                        step.collect_config_id === cc.id &&
                        step.is_splitted === true &&
                        step.splitted_idx === cc.splitted_idx
                );
                if (!foundInAssigned) {
                    return [cc];
                }
                return null;
            }),
            compact,
            toArray
        );

        set({ unnassignedCCService: [...TO_PLAN_SERVICES, ...TO_PLAN_SPLITTED_SERVICE] });
        return;
    },

    getUnnassignedCCAdministrative: () => {
        const ccs = Object.values(get().ccsAdministrative);
        const plannings = Object.values(get().plannings);

        if (!ccs) return [];

        const STEP_ADMINS = plannings.reduce((acc, cur) => {
            const assigned_admin_ccs = cur.shift?.steps_administrative || [];
            assigned_admin_ccs.forEach((step) => {
                acc.push(step);
            });
            return acc;
        }, [] as PlanningShiftStepAdministrative[]);

        const forbiddenStatus = [
            CC_STATUS.CANCELED,
            CC_STATUS.FINISHED,
            CC_STATUS.HAZARD,
            CC_STATUS.ORDER_TO_PAY,
            CC_STATUS.WAITING_FOR_APPROVAL
        ];

        const TO_PLAN_ADMINS = pipe(
            ccs,
            filter((cc): cc is CCAdministrativeRo => cc.family === CC_FAMILY.ADMINISTRATIVE),
            filter((cc) => !forbiddenStatus.includes(cc.status as any)),
            filter((cc) => {
                const found = STEP_ADMINS.find((step) => step.collect_config_id === cc.id);
                return !found;
            }),
            toArray
        );

        set({ unnassignedCCAdministrative: TO_PLAN_ADMINS });
        return;
    },

    getProducts: async (req) => {
        const products = await req<ProductRo[]>({
            sync    : true,
            method  : 'GET',
            url     : urlApiBuilder.productGetAll(''),
            payload : {
                queryParams: {
                    no_limit    : true,
                    no_archived : true
                }
            }
        });

        if (products.success && products.response && products.response.data && products.response.data.ro) {
            const formattedProducts = mapify(products.response.data.ro, 'id');
            set({ products: formattedProducts });
        }
    },
    lastAction    : EPlanningFormSubmitAction.SAVE,
    setLastAction : (action) => {
        set({
            lastAction: action
        });
    },
    calculationTriggered: false,
    setCalculationTriggered(state) {
        set({
            calculationTriggered: state
        });
    },
    calculatedPlanningsState    : mapApiDataToState([], {}, {}),
    setCalculatedPlanningsState : (val) => {
        set({
            calculatedPlanningsState: val
        });
    },
    isCompactMode  : true,
    setCompactMode : (val) => {
        set({
            isCompactMode: val
        });
    },
    additionalInfoIsOpen    : false,
    setAdditionalInfoIsOpen : (val) => {
        set({
            additionalInfoIsOpen: val
        });
    }
}));
