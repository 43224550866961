import moment from 'moment';
import { ContextMenu } from 'primereact/contextmenu';
import { MenuItem, MenuItemCommandParams } from 'primereact/menuitem';
import React, { useContext, useMemo, useRef, useState } from 'react';

import { getVolumeFromProducts } from '@bbng/util/misc';
import {
    AddressRo,
    CC_FAMILY,
    CC_STATUS,
    CollectService,
    DumpsterToTake,
    ECollectCharacteristic,
    EPlanningType,
    PlanningShiftStepCategory,
    isCCServiceFront,
    PLANNING_CALCULATE_KEY_PUBLISHED,
    PlanningShiftStepService,
    PlanningShiftStep,
    CollectRo,
    TruckRo,
    CCRo,
    isCCService,
    PlanningRemoveStepDto
} from '@bbng/util/types';

import { TIME_STEP } from '.';
import { config } from '../../../common/env';
import { StepOverview, collectConfigDescription, collectDescription } from '../../../components/StepOverview';
import { Dialog } from '../../../components/Dialog';
import CollectConfigCancelForm from '../../collect-config-cancel-form';
import CollectConfigForm from '../../planning-collect-config-form';
import CollectConfigSplitForm from '../../collect-config-split-form';
import CollectCreateForm from '../../collect-create-form';
import {
    EPlanningFormSubmitAction,
    baseStepClassName,
    commentStepClassName,
    customerStepClassName,
    narrowStepClassName,
    outOfScheduleStepClassName,
    familyStepClassName,
    mapVolumeToStepClassName
} from '../../planning-form/helpers';
import {
    associateColorToStep,
    associateIconToCollect,
    displayIconIfRotation,
    getBorderFromStatus,
    isStepEmptying,
    isStepService,
    isStepServiceOrAdmin,
    collectStatus
} from './helpers';
import { GridItem } from './layout';
import { ShiftContentTooltip } from './shiftContentTooltip';
import { PlanningPageState } from '../../../pages/Planning/helpers';
import { usePlanningV2Store } from '../../../hooks/PlanningStoreV2';
import { useRequest } from '../../../hooks/StatelessRequest';
import { CollectConfigUnassignForm } from '../../collect-config-unassigned-form';
import { PlanningContext } from '../../../context/Planning';
import {
    Badge,
    BadgeContainer,
    BadgeText,
    NarrowVerticalContainer,
    PostalCode,
    ProgramContentItem
} from './shiftContent.style';
import { toast } from '../../../components/Toast';
import { urlApiBuilder, urlBuilder } from '../../../common/urlBuilder';
import './keyframe.css';
import { CollectConfigReplanForm } from '../../collect-config-replan-form';
import { SmsModal } from './smsModal';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';

export type ShiftContentProps = {
    stepId: string;
    step: PlanningShiftStep;
    readOnly: boolean;
    startDate: Date;
};

export const ShiftContent: React.FC<ShiftContentProps> = ({
    stepId,
    step,
    readOnly,
    startDate
}: ShiftContentProps): JSX.Element => {
    const { planning_id } = useContext(PlanningContext);
    const {
        getPlannings,
        getCCAdministrative,
        getCCService,
        collects,
        trucksByPlanning,
        collectorsByPlanning,
        ccsService,
        ccsAdministrative,
        landfills,
        lastAction,
        planningPageState,
        customers
    } = usePlanningV2Store();
    const collect = step.collect_id ? collects[step.collect_id] : undefined;
    const collector = collectorsByPlanning[planning_id];
    const truck = trucksByPlanning[planning_id];
    const cc: CCRo | undefined = isStepServiceOrAdmin(step)
        ? ccsService[step.collect_config_id] ?? ccsAdministrative[step.collect_config_id]
        : undefined;

    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });
    const [smsDialogIsOpen, setSMSDialogIsOpen] = useState<boolean>(false);
    const columnStart = step.category === PlanningShiftStepCategory.BREAK ? 3 : 1;
    const columnEnd = step.category === PlanningShiftStepCategory.BREAK ? 6 : -1;
    const rawRowStart =
        Math.floor(moment(collect?.arrived_at ?? step.scheduled_at).diff(moment(startDate), 'minutes') / TIME_STEP) + 2;
    const rowStart = rawRowStart < 0 ? 2 : rawRowStart;
    const rowEnd =
        Math.floor(
            Math.abs(moment(collect?.completed_at ?? step.scheduled_end_at).diff(moment(startDate), 'minutes')) /
                TIME_STEP
        ) + 2;

    const stepServiceOrAdmin = isStepServiceOrAdmin(step) ? step : undefined;
    const stepService = isStepService(step) ? step : undefined;
    const stepEmptying = isStepEmptying(step) ? step : undefined;

    const [detailDialogIsOpen, setDetailDialogIsOpen] = useState(false);
    const [splitDialogIsOpen, setSplitDialogIsOpen] = useState(false);
    const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
    const [completeOrHazardDialogIsOpen, setCompleteOrHazardDialogIsOpen] = useState(false);
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
    const [replanDialogIsOpen, setReplanDialogIsOpen] = useState(false);
    const [unassignedIsOpen, setUnassignedIsOpen] = useState(false);
    const cardRef = useRef<ContextMenu>(null);

    const ccService = isCCService(cc) ? cc : undefined;
    const status = collectStatus(cc, collect);
    const splitted = cc?.status === CC_STATUS.SPLITTED || false;
    const hazard = status === CC_STATUS.HAZARD;
    const completed = status === CC_STATUS.FINISHED;
    const narrow = ccService?.characteristics?.includes(ECollectCharacteristic.NARROW_STREET) || false;
    const isBreak = step.category === PlanningShiftStepCategory.BREAK;
    const isCanceled = cc?.status === CC_STATUS.CANCELED;
    const isInternal = [
        PlanningShiftStepCategory.BREAK,
        PlanningShiftStepCategory.DRIVER_HOUSE_END,
        PlanningShiftStepCategory.DRIVER_HOUSE_START,
        PlanningShiftStepCategory.EMPTYING,
        PlanningShiftStepCategory.ADMINISTRATIVE
    ].includes(step.category as PlanningShiftStepCategory);
    const products =
        stepService?.is_splitted && stepService.splitted_idx !== undefined
            ? ccService?.splitted_informations[stepService.splitted_idx].products
            : ccService?.products;
    const landfill = stepEmptying ? landfills[stepEmptying.landfill_id] : undefined;
    const customer = ccService?.customer_id[0] ? customers[ccService.customer_id[0]] : undefined;
    const replanCount = ccService?.replan_count ?? 0;

    const isOutOfSchedule = useMemo(() => {
        if (!stepService || !ccService) return false;
        /**
         * Out of schedule means either:
         * - arriving before the start of the scheduled slot
         * - arriving after the end of the scheduled slot
         */
        return (
            moment(stepService.scheduled_at).isBefore(ccService.from_date) ||
            moment(stepService.scheduled_at).isAfter(ccService.to_date)
        );
    }, [stepService, ccService]);

    const hasComment = useMemo(() => {
        if (!cc) return false;
        return !!cc.comment && cc.comment.length > 0;
    }, [cc]);

    const isSingleFlow = useMemo(() => {
        if (!ccService) return false;
        const keywordsArray: Array<string> = [
            'tri',
            'tris',
            'trie',
            'tries',
            'trié',
            'triés',
            'triée',
            'triées',
            'trier',
            'triers',
            'trient'
        ];
        const productsContainOneOfKeyword: boolean = ccService.products.reduce((acc, product) => {
            const productName = product.name.toLowerCase();
            const productNameArray = productName.split(' ');
            const productNameArrayContainKeyword = keywordsArray.some((keyword) => productNameArray.includes(keyword));
            return acc || productNameArrayContainKeyword;
        }, false);

        return productsContainOneOfKeyword;
    }, [ccService]);

    const openEditCollectPage = () => {
        if (!collect) return;
        window.open(urlBuilder.collectEdit(collect.id), '_blank');
    };

    const classNames = useMemo(() => {
        const classes = [`programbox-${stepId.replace(':', '')}`, baseStepClassName];
        if (customer) classes.push(customerStepClassName(customer.id));
        if (isOutOfSchedule) classes.push(outOfScheduleStepClassName);
        if (hasComment) classes.push(commentStepClassName);
        if (narrow) classes.push(narrowStepClassName);
        if (cc) classes.push(familyStepClassName(cc.family));
        if (cc && cc.products) {
            const volumes = cc.products.map((line) => line.volume_m3);
            const uniqueVolumes = [...new Set(volumes)];
            uniqueVolumes.forEach((volume) => {
                classes.push(mapVolumeToStepClassName(volume));
            });
        }
        return classes.join(' ');
    }, [stepId, isOutOfSchedule, hasComment, narrow, cc]);

    const ItemToEvacuate = () => {
        if (stepServiceOrAdmin === undefined) return null;

        if (!stepService?.is_splitted && isCCService(cc)) {
            return cc.products.map((line) => {
                return (
                    <p key={line.id} style={{ fontSize: '1rem', margin: 0, textAlign: 'center' }}>
                        {line.volume_m3} x {line.quantity} {displayIconIfRotation(line, cc)}
                    </p>
                );
            });
        } else if (stepService?.is_splitted === true && stepService.splitted_idx !== undefined && isCCService(cc)) {
            return cc.splitted_informations[stepService.splitted_idx!].products.map((line) => {
                return (
                    <p key={line.id} style={{ fontSize: '1.5rem', margin: 0, textAlign: 'center' }}>
                        {line.volume_m3 * line.quantity} {displayIconIfRotation(line, cc)}
                    </p>
                );
            });
        }
        return null;
    };

    const Pictograms = () => {
        return (
            <BadgeContainer style={{ position: 'absolute' }}>
                {isOutOfSchedule && (
                    <Badge backgroundColor="var(--orange-200)">
                        <i style={{ fontSize: '12px' }} className="pi pi-clock" />
                    </Badge>
                )}
                {hasComment && (
                    <Badge backgroundColor="var(--teal-200)">
                        <i style={{ fontSize: '12px' }} className="pi pi-comments" />
                    </Badge>
                )}
                {replanCount > 0 && (
                    <Badge backgroundColor="var(--yellow-200)">
                        <BadgeText>{replanCount}</BadgeText>
                        <i style={{ fontSize: '12px' }} className="pi pi-refresh" />
                    </Badge>
                )}
                {isSingleFlow && (
                    <Badge backgroundColor="transparent">
                        <span
                            role="img"
                            aria-label="recycling"
                            style={{
                                fontSize  : '14px',
                                animation : 'anim-popoutin 1s infinite'
                            }}
                        >
                            ♻️
                        </span>
                    </Badge>
                )}
            </BadgeContainer>
        );
    };

    const removeStep = async (ccId: string) => {
        await bbngRequest({
            sync    : true,
            method  : 'PATCH',
            url     : urlApiBuilder.planningRemoveStep(),
            payload : {
                body: {
                    cc_id  : ccId,
                    day    : planningPageState?.day,
                    region : planningPageState?.region,
                    type   : planningPageState?.type
                } as PlanningRemoveStepDto
            }
        });
        window.location.reload();
    };

    if (isStepServiceOrAdmin(step) && !cc) {
        toast({
            severity : 'error',
            summary  : 'Erreur de données',
            detail   : (
                <span>
                    La configuration de collecte{' '}
                    <a target="_blank" rel="noreferrer" href={urlBuilder.collectConfigView(step.collect_config_id)}>
                        {step.collect_config_id}
                    </a>{' '}
                    a été déplacée à un autre jour ou annulée.
                    <br />
                    <Button
                        label="Corriger l'anomalie"
                        className="p-button-text"
                        onClick={() =>
                            confirmDialog({
                                message:
                                    "Voulez-vous vraiment corriger l'anomalie ? La page sera rechargée à la fin de la correction.",
                                header : 'Confirmation',
                                icon   : 'pi pi-exclamation-triangle',
                                accept : () => removeStep(step.collect_config_id),
                                reject : () => {
                                    // Do nothing
                                }
                            })
                        }
                    />
                </span>
            ),
            life: 15_000
        });
        return <></>;
    }

    return (
        <GridItem columnStart={columnStart} columnEnd={columnEnd} rowStart={rowStart} rowEnd={rowEnd}>
            <span
                style={{ cursor: 'pointer', height: '100%' }}
                onClick={() => setDetailDialogIsOpen(true)}
                onContextMenu={(e) => cardRef?.current?.show(e)}
            >
                <ShiftContentTooltip
                    step={step}
                    stepId={stepId}
                    narrow={narrow}
                    splitted={splitted}
                    hasComment={hasComment}
                    isOutOfSchedule={isOutOfSchedule}
                    isSingleFlow={isSingleFlow}
                />
                <div
                    className={classNames}
                    style={{
                        height: '100%'
                    }}
                    key={stepId}
                >
                    <ProgramContentItem
                        backgroundColor={associateColorToStep(step?.category)}
                        borderColor={getBorderFromStatus(isCanceled ? status : status)}
                        onClick={() => setDetailDialogIsOpen(true)}
                    >
                        {Pictograms()}
                        {ccService && (
                            <div>
                                <p style={{ fontSize: 9 }}>{ccService?.order_number}</p>
                                <p style={{ fontSize: 9 }}>{ccService?.number}</p>
                            </div>
                        )}
                        <i
                            className={`pi ${associateIconToCollect(step, cc)}`}
                            style={{ fontSize: isBreak ? '0.8rem' : '1.5rem', textAlign: 'center' }}
                        />
                        {!isBreak && (
                            <>
                                {ItemToEvacuate()}
                                <NarrowVerticalContainer>
                                    <PostalCode>{cc?.address?.components['postal_code']}</PostalCode>
                                </NarrowVerticalContainer>
                                {narrow && <p style={{ textAlign: 'center', margin: 0 }}>(Etroit)</p>}
                                {splitted && <p style={{ textAlign: 'center', margin: 0 }}>(Scindée)</p>}
                                {(completed || hazard) && (
                                    <i
                                        className={`pi ${completed ? 'pi-check' : 'pi-exclamation-triangle'}`}
                                        style={{ fontSize: '1.5rem', textAlign: 'center' }}
                                    />
                                )}
                            </>
                        )}
                    </ProgramContentItem>
                </div>
            </span>
            {!isBreak && (
                <>
                    <ContextMenu
                        model={contextMenuItems({
                            step,
                            collect,
                            truck,
                            status,
                            cc,
                            setEditDialog       : setEditDialogIsOpen,
                            setDetailDialog     : setDetailDialogIsOpen,
                            setSplitDialog      : setSplitDialogIsOpen,
                            setCompleteDialog   : setCompleteOrHazardDialogIsOpen,
                            setCancelDialog     : setCancelDialogIsOpen,
                            setUnassignedDialog : setUnassignedIsOpen,
                            setSMSDialog        : setSMSDialogIsOpen,
                            setReplanDialog     : setReplanDialogIsOpen,
                            openEditCollectPage,
                            readOnly,
                            isSplitted          : splitted,
                            lastAction,
                            planningPageState
                        })}
                        ref={cardRef}
                    />
                    <Dialog
                        draggable={false}
                        resizable={false}
                        blockScroll
                        header={collectDescription(step, cc as CCRo, customer, landfill)}
                        visible={detailDialogIsOpen}
                        onHide={() => setDetailDialogIsOpen(false)}
                        contentStyle={{ overflow: 'scroll' }}
                    >
                        <StepOverview
                            collectorId={collector?.id}
                            customerId={customer?.id ?? cc?.customer_id?.[0]}
                            family={cc?.family}
                            planned_start_date={step.scheduled_at}
                            planned_end_date={step.scheduled_end_at}
                            planned_duration={step.scheduled_service_time}
                            planned_products={products}
                            actual_start_date={collect?.arrived_at}
                            actual_end_date={collect?.completed_at}
                            actual_products={(collect as CollectService)?.informations?.collected_items}
                            status={status}
                            address={(cc?.address || landfill?.address || truck?.garage_address) as AddressRo}
                            title={cc?.title as string}
                            comment={cc?.comment as string}
                            characteristics={ccService?.characteristics}
                            isSplitted={splitted}
                            hazardComment={collect?.hazard_comment ?? undefined}
                            hazardReason={collect?.hazard_reason ?? undefined}
                            number={ccService?.number}
                            order_number={ccService?.order_number as string}
                            dumpster_to_take={stepEmptying?.dumpster_to_take as DumpsterToTake}
                            slot={{
                                start : cc?.from_date as string,
                                end   : cc?.to_date as string
                            }}
                            cons_site_contacts={ccService?.construction_site_contact}
                            replanCount={replanCount}
                        />
                    </Dialog>
                    <Dialog
                        visible={editDialogIsOpen}
                        onHide={() => setEditDialogIsOpen(false)}
                        header={collectConfigDescription(cc)}
                        draggable={false}
                        resizable={false}
                        blockScroll
                        contentStyle={{ overflow: 'scroll' }}
                    >
                        <CollectConfigForm
                            edit={true}
                            setModalIsOpen={setEditDialogIsOpen}
                            dataId={cc?.id}
                            isInternal={isInternal}
                            isAdministrative={cc?.family === CC_FAMILY.ADMINISTRATIVE}
                        />
                    </Dialog>
                    <Dialog
                        visible={splitDialogIsOpen}
                        onHide={() => setSplitDialogIsOpen(false)}
                        header={collectConfigDescription(cc)}
                        draggable={false}
                        resizable={false}
                        blockScroll
                        contentStyle={{ overflow: 'scroll' }}
                    >
                        <CollectConfigSplitForm setModalIsOpen={setSplitDialogIsOpen} dataId={cc?.id} />
                    </Dialog>
                    <Dialog
                        visible={completeOrHazardDialogIsOpen}
                        onHide={() => {
                            setCompleteOrHazardDialogIsOpen(false);
                        }}
                        header={collectConfigDescription(cc)}
                        draggable={false}
                        resizable={false}
                        blockScroll
                        contentStyle={{ overflow: 'scroll' }}
                    >
                        <CollectCreateForm
                            setModalIsOpen={setCompleteOrHazardDialogIsOpen}
                            isInternal={isInternal}
                            category={step.category}
                            step={step}
                            cc_id={(step as PlanningShiftStepService)?.collect_config_id}
                            showLandfill={step.category === PlanningShiftStepCategory.EMPTYING}
                            truck_id={truck?.id}
                            collector_id={collector?.id}
                        />
                    </Dialog>
                    <Dialog
                        visible={cancelDialogIsOpen}
                        onHide={() => setCancelDialogIsOpen(false)}
                        header={`Annulation - ${collectConfigDescription(cc)}`}
                        draggable={false}
                        resizable={false}
                        blockScroll
                        contentStyle={{ overflow: 'scroll' }}
                    >
                        <CollectConfigCancelForm
                            submitCallback={async () => {
                                /**
                                 * Once the cancel is done, we need to refresh the planning and ccs
                                 */
                                await Promise.all([
                                    getPlannings(bbngRequest),
                                    getCCAdministrative(bbngRequest),
                                    getCCService(bbngRequest)
                                ]);
                            }}
                            setModalIsOpen={setCancelDialogIsOpen}
                            dataId={cc?.id as string}
                            isAdministrative={cc?.family === CC_FAMILY.ADMINISTRATIVE}
                        />
                    </Dialog>
                    <Dialog
                        visible={unassignedIsOpen}
                        onHide={() => setUnassignedIsOpen(false)}
                        header={`Désassigner - ${collectConfigDescription(cc)}`}
                        draggable={false}
                        resizable={false}
                        blockScroll
                        contentStyle={{ overflow: 'scroll' }}
                    >
                        <CollectConfigUnassignForm
                            setModalIsOpen={setUnassignedIsOpen}
                            data={step as PlanningShiftStepService}
                        />
                    </Dialog>
                    <Dialog
                        visible={smsDialogIsOpen}
                        onHide={() => setSMSDialogIsOpen(false)}
                        header="Envoyer un SMS"
                        draggable={false}
                        resizable={false}
                        blockScroll
                    >
                        <SmsModal
                            ccId={cc?.id as string}
                            contactList={
                                (cc?.log_contact ?? []).length > 0
                                    ? cc?.log_contact
                                          ?.filter((contact) => contact.phone_number)
                                          ?.map((contact) => contact.phone_number) ?? ['']
                                    : ['']
                            }
                            onClose={() => setSMSDialogIsOpen(false)}
                        />
                    </Dialog>
                    <Dialog
                        visible={replanDialogIsOpen}
                        onHide={() => setReplanDialogIsOpen(false)}
                        header={`Replanifier - ${collectConfigDescription(cc)}`}
                        draggable={false}
                        resizable={false}
                        blockScroll
                        contentStyle={{ overflow: 'scroll' }}
                    >
                        <CollectConfigReplanForm
                            setModalIsOpen={setReplanDialogIsOpen}
                            data={step as PlanningShiftStepService}
                            collector_id={collector?.id}
                            truck_id={truck?.id}
                        />
                    </Dialog>
                </>
            )}
        </GridItem>
    );
};

type ContextMenuItemsParams = {
    step: PlanningShiftStep;
    collect?: CollectRo;
    cc?: CCRo;
    truck: TruckRo;
    status: CC_STATUS;
    setEditDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setDetailDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setSplitDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setCompleteDialog: React.Dispatch<React.SetStateAction<boolean>>;
    openEditCollectPage: () => void;
    setCancelDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setUnassignedDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setReplanDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setSMSDialog: React.Dispatch<React.SetStateAction<boolean>>;
    readOnly: boolean;
    isSplitted: boolean;
    lastAction?: EPlanningFormSubmitAction;
    planningPageState?: PlanningPageState;
};

const contextMenuItems = ({
    step,
    collect,
    cc,
    truck,
    status,
    setEditDialog,
    setDetailDialog,
    setSplitDialog,
    setCompleteDialog,
    setCancelDialog,
    setReplanDialog,
    setUnassignedDialog,
    openEditCollectPage,
    setSMSDialog,
    readOnly,
    isSplitted,
    lastAction,
    planningPageState
}: ContextMenuItemsParams): MenuItem[] => {
    const category = step.category as PlanningShiftStepCategory;
    const uneditable = [
        PlanningShiftStepCategory.BREAK,
        PlanningShiftStepCategory.DRIVER_HOUSE_END,
        PlanningShiftStepCategory.DRIVER_HOUSE_START,
        PlanningShiftStepCategory.EMPTYING
    ].includes(category as PlanningShiftStepCategory);
    const isFinished = [CC_STATUS.FINISHED, CC_STATUS.HAZARD].includes(status as any);
    const ccHasMoreVolumeThanTruckCapacity =
        cc &&
        isCCServiceFront(cc) &&
        (truck.characteristics.bigbag_capacity || 0) < (getVolumeFromProducts(cc?.products, cc?.family) || 0);
    const isSaved = planningPageState?.redis_version_description.key === PLANNING_CALCULATE_KEY_PUBLISHED;
    const isCanceled = status === CC_STATUS.CANCELED;
    const isToday = moment.utc(planningPageState?.day).isSame(moment.utc(), 'day');

    return [
        {
            label   : 'Voir',
            icon    : 'pi pi-eye',
            command : (e: MenuItemCommandParams) => {
                setDetailDialog(true);
            }
        },
        {
            label   : collect ? 'Modifier la collecte finale' : 'Terminer',
            icon    : 'pi pi-check',
            command : (e: MenuItemCommandParams) => {
                if (collect) {
                    openEditCollectPage();
                } else {
                    setCompleteDialog(true);
                }
            },
            disabled:
                (isFinished && step.category !== PlanningShiftStepCategory.SERVICE) ||
                !isSaved ||
                (config.nodeEnv !== 'dev' && moment.utc(planningPageState?.day).isAfter(moment.utc(), 'day')) ||
                isCanceled
        },
        {
            label   : 'Editer',
            icon    : 'pi pi-cog',
            command : (e: MenuItemCommandParams) => {
                setEditDialog(true);
            },
            disabled: !cc || readOnly || isFinished || uneditable || isSplitted || !isSaved || isCanceled
        },
        {
            label   : 'Replanifier',
            icon    : 'pi pi-calendar',
            command : (e: MenuItemCommandParams) => {
                setReplanDialog(true);
            },
            disabled: !cc || readOnly || isFinished || uneditable || !isSaved || isCanceled || !isToday
        },
        {
            label   : 'Désassigner',
            icon    : 'pi pi-user-minus',
            command : (e: MenuItemCommandParams) => {
                setUnassignedDialog(true);
            },
            disabled: !cc || isFinished || !isSaved || !isSaved || isCanceled
        },
        {
            label   : 'Scinder',
            icon    : 'pi pi-clone',
            command : (e: MenuItemCommandParams) => {
                setSplitDialog(true);
            },
            disabled:
                isSplitted ||
                readOnly ||
                planningPageState?.type !== EPlanningType.BIG_BAG ||
                isFinished ||
                uneditable ||
                category === PlanningShiftStepCategory.ADMINISTRATIVE ||
                !ccHasMoreVolumeThanTruckCapacity ||
                isCanceled ||
                !isSaved
        },
        {
            label   : 'Annuler',
            icon    : 'pi pi-exclamation-triangle',
            command : (e: MenuItemCommandParams) => {
                setCancelDialog(true);
            },
            disabled: !cc || readOnly || isFinished || uneditable || !isSaved || isCanceled
        },
        {
            label   : 'Envoyer un SMS',
            icon    : 'pi pi-envelope',
            command : (e: MenuItemCommandParams) => {
                setSMSDialog(true);
            },
            disabled: !cc || readOnly || isFinished || uneditable || isCanceled || !isSaved
        }
    ];
};
