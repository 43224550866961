import { DumpsterOnSite, DumpsterOnSite_History } from '@prisma/client/collect';
import { CCServiceRo } from '../collect-config';
import { FrontRo } from '../dto';

import { IKafkaQuery } from '../kafka';
import { ETrashType, ISODate, MaybeArray } from '../misc';
import { ProductRo } from '../product';

export type DumpsterOnSiteCreateDto = {
    deposited_at: ISODate;
    retrieved_at?: ISODate;
    comment?: string;

    product_id: string;
    construction_site_id: string;
    deposit_collect_id?: string;
    retrieval_collect_id?: string;
    retrieval_collect_config_id?: MaybeArray<string>;
};

export type DumpsterOnSiteSynchronizeDto = {
    dumpsters: DumpsterOnSiteCreateActionDto[];
};
export const DUMPSTER_ON_SITE_SYNC_KEY = 'DUMPSTER:ON:SITE:SYNC';

export type DumpsterOnSiteCreateActionDto = Omit<DumpsterOnSiteCreateDto, 'product_id'> & {
    id?: string;
    product: ProductRo;
    construction_site_id: string;
    customer_id: string;
};

export type DumpsterOnSiteEditDto = {
    deposited_at?: ISODate;
    retrieved_at?: ISODate;
    comment?: string;

    product_id?: string;
    construction_site_id?: string;
    deposit_collect_id?: string;
    retrieval_collect_id?: string;
};

export type DumpsterOnSiteEditActionDto = Omit<DumpsterOnSiteEditDto, 'retrieved_at' | 'product_id'> & {
    retrieved_at?: ISODate | null; // null to reset value on revert
    product?: ProductRo;
    construction_site_id?: string;
    customer_id?: string;
    archived?: boolean;
    retrieval_collect_config_id?: MaybeArray<string>;
};

export type DumpsterOnSiteRo = {
    id: string;

    product: ProductRo;
    deposited_at: ISODate;
    retrieved_at: ISODate | null;
    comment: string | null;

    construction_site_id: string[];
    customer_id: string[];
    deposit_collect_id: string[];
    retrieval_collect_id: string[];
    retrieval_collect_config_id: string[];

    archived: boolean;
    created_at: ISODate;
    updated_at: ISODate;
};

export type DumpsterOnSitePrisma = DumpsterOnSite & { history: DumpsterOnSite_History };

export type DumpsterOnSiteQuery = IKafkaQuery & {
    volume_m3?: number;
    trash_type?: ETrashType;
    construction_site_id?: string;
    customer_id?: string;
    archived?: boolean;
    min_deposited_at?: ISODate;
    max_deposited_at?: ISODate;
    exclude_retrieved?: boolean;
    exclude_planned_for_retrieval?: boolean;
    min_retrieved_at?: ISODate;
    max_retrieved_at?: ISODate;
    collect_id?: string;
    deposit_collect_id?: string;
    retrieval_collect_id?: string;
    retrieval_collect_config_id?: string;
    order_by_deposited_at?: 'asc' | 'desc';
};

export const isDumpsterOnSiteRo = (data: DumpsterOnSiteRo | CCServiceRo): data is DumpsterOnSiteRo => {
    return (data as DumpsterOnSiteRo)?.product !== undefined;
};

export const isDumpsterOnSiteFrontRo = (
    data: FrontRo<DumpsterOnSiteRo | CCServiceRo>
): data is FrontRo<DumpsterOnSiteRo> => {
    return (data as FrontRo<DumpsterOnSiteRo>)?.product !== undefined;
};
