import { AddressRo } from '@bbng/util/types';

import { AddressMapButton } from '../AddressDisplayButton';
import { AddressOverviewContainer, VerticalContainer } from './style';

export type AddressOverviewProps = { address: AddressRo; showButton?: boolean; flex?: boolean };

export const AddressOverview: React.FC<AddressOverviewProps> = ({
    address,
    showButton = false,
    flex = true
}: AddressOverviewProps): JSX.Element => {
    return (
        <AddressOverviewContainer flex={flex}>
            {showButton && <AddressMapButton lat={address.coordinates.latitude} lng={address.coordinates.longitude} />}
            <VerticalContainer>
                <div>
                    {address.components['street_number']} {address.components['route']}
                </div>
                <div>
                    {address.components['postal_code']} {address.components['locality']}
                </div>
            </VerticalContainer>
        </AddressOverviewContainer>
    );
};
