import { CCServiceRoFront, CC_FAMILY, ECollectCharacteristic, EPlanningType } from '@bbng/util/types';
import { useMemo } from 'react';
import { useDrop } from 'react-dnd';

import { CardCC } from './cc-card';
import { CardType, CardUnassignedId, MonitorItem, useGlobalConfigContext } from './provider';

export const UnassignedCcsBucket = () => {
    const { unassignedCcs, unassignedCard, dumpsterTypes, type } = useGlobalConfigContext();
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept  : CardType.CC,
        collect : (monitor) => ({
            isOver  : monitor.isOver(),
            canDrop : monitor.canDrop()
        }),
        canDrop: (item, monitor) => {
            const dragItem = monitor.getItem<MonitorItem>();
            const can = dragItem.columnId !== CardUnassignedId && dragItem.type === CardType.CC;
            return can;
        },
        drop: (item, monitor) => {
            if (!item || (item as MonitorItem).columnId === CardUnassignedId) return;
            unassignedCard(item as MonitorItem);
        }
    }));

    const displayedUnassignedCcs = useMemo(() => {
        if (type === EPlanningType.BIG_BAG) return unassignedCcs;
        else {
            return unassignedCcs.filter((cc) => {
                if (cc.cc.family === CC_FAMILY.ADMINISTRATIVE) return true;
                return dumpsterTypes.some((type) =>
                    (cc.cc as CCServiceRoFront).characteristics.includes(type as ECollectCharacteristic)
                );
            });
        }
    }, [JSON.stringify(unassignedCcs), dumpsterTypes, type]);

    return (
        <section id="board-cc-bucket" style={{ marginBottom: '8px' }}>
            <h2 style={{ margin: '8px 0 4px' }}>Étapes non affectées</h2>
            <div
                ref={drop}
                style={{
                    display         : 'flex',
                    flexWrap        : 'wrap',
                    gap             : '8px',
                    padding         : '8px',
                    backgroundColor : '#EFEFEF',
                    minHeight       : '100px',
                    maxHeight       : '250px',
                    overflowY       : 'auto',
                    border          : `2px solid ${isOver && canDrop ? '#4A5CFC' : 'transparent'}`
                }}
            >
                {displayedUnassignedCcs.map((cc, i) => (
                    <CardCC
                        key={cc.gc_id}
                        item={cc}
                        columnId={CardUnassignedId}
                        columnIndex={i}
                        disableDropOver
                        disableOverAnimation
                    />
                ))}
            </div>
        </section>
    );
};
