import produce, { setAutoFreeze } from 'immer';

import { getVolumeFromProducts } from '@bbng/util/misc';
import {
    BaseType,
    CC_FAMILY,
    CC_STATUS,
    EPlanningType,
    PlanningRo,
    PlanningShiftStepService,
    ProductInCCOrCO,
    CCServiceRo,
    CCAdministrativeRo
} from '@bbng/util/types';

setAutoFreeze(false);

export type PlanningMetrics = {
    nb_cc_service: number;
    nb_cc_service_splitted: number;
    nb_cc_administrative: number;
    nb_cc_total: number;

    nb_cc_service_to_plan: number;
    nb_cc_service_planned: number;
    nb_cc_service_finished: number;
    nb_cc_service_hazard: number;
    nb_cc_service_to_pay: number;

    nb_cc_administrative_to_plan: number;
    nb_cc_administrative_planned: number;
    nb_cc_administrative_hazard: number;
    nb_cc_administrative_finished: number;

    volume_cc_service: number;
    volume_cc_service_to_plan: number;
    volume_cc_service_planned: number;
    volume_cc_service_finished: number;
    volume_cc_service_hazard: number;
    volume_cc_service_to_pay: number;

    nb_dumpster_deposit: number;
    nb_dumpster_retrieval: number;
    nb_dumpster_rotation: number;
    nb_dumpster_loadwait: number;
    nb_dumpster_total: number;

    nb_dumpster_volume: Record<number, number>;
};

export const getPlanningMetrics = (
    plannings: PlanningRo[],
    ccs: (CCServiceRo | CCAdministrativeRo)[],
    planningBaseData: BaseType,
    unasiggnedCcs: (CCServiceRo | CCAdministrativeRo)[]
): PlanningMetrics => {
    const initialMetrics: PlanningMetrics = {
        nb_cc_service          : 0,
        nb_cc_service_splitted : 0,
        nb_cc_administrative   : 0,
        nb_cc_total            : 0,

        nb_cc_service_to_plan  : 0,
        nb_cc_service_planned  : 0,
        nb_cc_service_finished : 0,
        nb_cc_service_hazard   : 0,
        nb_cc_service_to_pay   : 0,

        nb_cc_administrative_to_plan  : 0,
        nb_cc_administrative_planned  : 0,
        nb_cc_administrative_hazard   : 0,
        nb_cc_administrative_finished : 0,

        volume_cc_service          : 0,
        volume_cc_service_to_plan  : 0,
        volume_cc_service_planned  : 0,
        volume_cc_service_finished : 0,
        volume_cc_service_hazard   : 0,
        volume_cc_service_to_pay   : 0,

        nb_dumpster_deposit   : 0,
        nb_dumpster_retrieval : 0,
        nb_dumpster_rotation  : 0,
        nb_dumpster_loadwait  : 0,
        nb_dumpster_total     : 0,
        nb_dumpster_volume    : {}
    };
    if (!ccs || Object.keys(ccs).length === 0) return initialMetrics;

    const { SERVICE_TO_PAY, ADMINISTRATIVE, SERVICE_NOT_SPLITTED, SERVICE_SPLITTED } = ccs.reduce(
        (acc, cc) => {
            if (cc.status === CC_STATUS.ORDER_TO_PAY) {
                acc.SERVICE_TO_PAY.push(cc as CCServiceRo);
                return acc;
            }
            if (cc.family === CC_FAMILY.ADMINISTRATIVE) {
                acc.ADMINISTRATIVE.push(cc as CCAdministrativeRo);
                return acc;
            }
            if (cc.status === CC_STATUS.SPLITTED) {
                acc.SERVICE_SPLITTED.push(cc as CCServiceRo);
                return acc;
            }
            acc.SERVICE_NOT_SPLITTED.push(cc as CCServiceRo);
            return acc;
        },
        {
            SERVICE_TO_PAY       : [],
            SERVICE_NOT_SPLITTED : [],
            SERVICE_SPLITTED     : [],
            ADMINISTRATIVE       : []
        } as {
            SERVICE_TO_PAY: CCServiceRo[];
            SERVICE_NOT_SPLITTED: CCServiceRo[];
            SERVICE_SPLITTED: CCServiceRo[];
            ADMINISTRATIVE: CCAdministrativeRo[];
        }
    );

    const {
        ADMINISTRAIVE_FINISHED,
        ADMINISTRAIVE_HAZARD,
        ADMINISTRAIVE_PLANNED,
        SERVICE_FINISHED,
        SERVICE_HAZARD,
        SERVICE_PLANNED,
        SERVICE_SPLITTED_FINISHED,
        SERVICE_SPLITTED_HAZARD,
        SERVICE_SPLITTED_PLANNED
    } = plannings.reduce(
        (acc, planning) => {
            planning.shift.steps_service.forEach((step) => {
                if (step.is_splitted) {
                    const splittedStep = step as PlanningShiftStepService & { splitted_idx: number };
                    if (splittedStep.collect_id) {
                        const correspondingCC = SERVICE_SPLITTED.find((cc) => cc.id === step.collect_config_id);
                        if (!correspondingCC) return;
                        const status = correspondingCC?.splitted_informations.find(
                            (splitted) => splitted.idx === splittedStep.splitted_idx
                        )?.status;
                        if (status === CC_STATUS.FINISHED)
                            acc.SERVICE_SPLITTED_FINISHED.push({
                                cc           : correspondingCC,
                                splitted_idx : splittedStep.splitted_idx
                            });
                        if (status === CC_STATUS.HAZARD)
                            acc.SERVICE_SPLITTED_HAZARD.push({
                                cc           : correspondingCC,
                                splitted_idx : splittedStep.splitted_idx
                            });
                    } else {
                        acc.SERVICE_SPLITTED_PLANNED.push({
                            cc           : ccs.find((cc) => cc.id === step.collect_config_id) as CCServiceRo,
                            splitted_idx : splittedStep.splitted_idx
                        });
                    }
                } else {
                    if (step.collect_id === undefined) {
                        const correspondingCc = ccs.find((cc) => cc.id === step.collect_config_id) as CCServiceRo;
                        correspondingCc && acc.SERVICE_PLANNED.push(correspondingCc);
                    }
                    if (step.collect_id) {
                        const correspondingCC = SERVICE_NOT_SPLITTED.find((cc) => cc.id === step.collect_config_id);
                        if (correspondingCC) {
                            if (correspondingCC.status === CC_STATUS.FINISHED)
                                acc.SERVICE_FINISHED.push(correspondingCC);
                            if (correspondingCC.status === CC_STATUS.HAZARD) acc.SERVICE_HAZARD.push(correspondingCC);
                        }
                    }
                }
            });
            planning.shift.steps_administrative.forEach((step) => {
                if (step.collect_id === undefined)
                    acc.ADMINISTRAIVE_PLANNED.push(
                        ccs.find((cc) => cc.id === step.collect_config_id) as CCAdministrativeRo
                    );
                if (step.collect_id) {
                    const correspondingCC = ADMINISTRATIVE.find((cc) => cc.id === step.collect_config_id);
                    if (correspondingCC) {
                        if (correspondingCC.status === CC_STATUS.FINISHED)
                            acc.ADMINISTRAIVE_FINISHED.push(correspondingCC);
                        if (correspondingCC.status === CC_STATUS.HAZARD) acc.ADMINISTRAIVE_HAZARD.push(correspondingCC);
                    }
                }
            });

            return acc;
        },
        {
            SERVICE_PLANNED           : [],
            SERVICE_FINISHED          : [],
            SERVICE_HAZARD            : [],
            SERVICE_SPLITTED_PLANNED  : [],
            SERVICE_SPLITTED_FINISHED : [],
            SERVICE_SPLITTED_HAZARD   : [],
            ADMINISTRAIVE_PLANNED     : [],
            ADMINISTRAIVE_FINISHED    : [],
            ADMINISTRAIVE_HAZARD      : []
        } as {
            SERVICE_PLANNED: CCServiceRo[];
            SERVICE_FINISHED: CCServiceRo[];
            SERVICE_HAZARD: CCServiceRo[];
            SERVICE_SPLITTED_PLANNED: { cc: CCServiceRo; splitted_idx: number }[];
            SERVICE_SPLITTED_FINISHED: { cc: CCServiceRo; splitted_idx: number }[];
            SERVICE_SPLITTED_HAZARD: { cc: CCServiceRo; splitted_idx: number }[];
            ADMINISTRAIVE_PLANNED: CCAdministrativeRo[];
            ADMINISTRAIVE_FINISHED: CCAdministrativeRo[];
            ADMINISTRAIVE_HAZARD: CCAdministrativeRo[];
        }
    );

    return produce(initialMetrics, (draft) => {
        draft.nb_cc_service = SERVICE_NOT_SPLITTED.length;
        draft.nb_cc_service_splitted = SERVICE_SPLITTED.map((cc) => cc.splitted_informations.length).reduce(
            (a, b) => a + b,
            0
        );
        draft.nb_cc_administrative = ADMINISTRATIVE.length;
        draft.nb_cc_total = draft.nb_cc_service + draft.nb_cc_service_splitted + draft.nb_cc_administrative;

        draft.nb_cc_service_to_plan = unasiggnedCcs.filter((cc) => cc.family !== CC_FAMILY.ADMINISTRATIVE).length;
        draft.nb_cc_service_planned = SERVICE_PLANNED.length + SERVICE_SPLITTED_PLANNED.length;
        draft.nb_cc_service_finished = SERVICE_FINISHED.length + SERVICE_SPLITTED_FINISHED.length;
        draft.nb_cc_service_hazard = SERVICE_HAZARD.length + SERVICE_SPLITTED_HAZARD.length;
        draft.nb_cc_service_to_pay = SERVICE_TO_PAY.length;

        draft.nb_cc_administrative_to_plan = unasiggnedCcs.filter(
            (cc) => cc.family === CC_FAMILY.ADMINISTRATIVE
        ).length;
        draft.nb_cc_administrative_planned = ADMINISTRAIVE_PLANNED.length;
        draft.nb_cc_administrative_hazard = ADMINISTRAIVE_HAZARD.length;
        draft.nb_cc_administrative_finished = ADMINISTRAIVE_FINISHED.length;

        if (planningBaseData.type === EPlanningType.BIG_BAG) {
            draft.volume_cc_service = [...SERVICE_NOT_SPLITTED, ...SERVICE_SPLITTED].reduce((acc, cc) => {
                cc.products.forEach((product) => {
                    acc = acc + product.volume_m3 * product.quantity;
                });
                return acc;
            }, 0);

            const { volumeServiceToPlan } = unasiggnedCcs.reduce(
                (acc, cc) => {
                    if ('products' in cc) {
                        if (cc.status === CC_STATUS.SPLITTED) {
                            cc.products.forEach((product) => {
                                acc.volumeServiceToPlan =
                                    acc.volumeServiceToPlan + product.volume_m3 * product.quantity;
                            });
                            acc.ccIds.push(cc.id);
                        } else {
                            cc.products.forEach((product) => {
                                acc.volumeServiceToPlan =
                                    acc.volumeServiceToPlan + product.volume_m3 * product.quantity;
                            });
                        }
                    }
                    return acc;
                },
                { volumeServiceToPlan: 0, ccIds: [] } as { volumeServiceToPlan: number; ccIds: string[] }
            );

            draft.volume_cc_service_to_plan = volumeServiceToPlan;

            const volumeServicePlanned = SERVICE_PLANNED.reduce((acc, cc) => {
                cc.products.forEach((product) => {
                    acc = acc + product.volume_m3 * product.quantity;
                });
                return acc;
            }, 0);
            const { volumeServiceSplitted } = SERVICE_SPLITTED_PLANNED.reduce(
                (acc, { cc }) => {
                    if (acc.ccIds.includes(cc.id)) return acc;
                    acc.ccIds.push(cc.id);
                    cc.splitted_informations.forEach((info) => {
                        if (info.status === CC_STATUS.PLANNED) {
                            info.products.forEach((product) => {
                                acc.volumeServiceSplitted =
                                    acc.volumeServiceSplitted + product.volume_m3 * product.quantity;
                            });
                        }
                    });
                    return acc;
                },
                { volumeServiceSplitted: 0, ccIds: [] } as { volumeServiceSplitted: number; ccIds: string[] }
            );
            draft.volume_cc_service_planned += volumeServicePlanned + volumeServiceSplitted;

            const volumeServiceFinished = SERVICE_FINISHED.reduce((acc, cc) => {
                cc.products.forEach((product) => {
                    acc = acc + product.volume_m3 * product.quantity;
                });
                return acc;
            }, 0);
            const { volumeServiceSplittedFinished } = SERVICE_SPLITTED_FINISHED.reduce(
                (acc, { cc }) => {
                    if (acc.ccIds.includes(cc.id)) return acc;
                    acc.ccIds.push(cc.id);
                    cc.products.forEach((product) => {
                        acc.volumeServiceSplittedFinished =
                            acc.volumeServiceSplittedFinished + product.volume_m3 * product.quantity;
                    });
                    return acc;
                },
                { volumeServiceSplittedFinished: 0, ccIds: [] } as {
                    volumeServiceSplittedFinished: number;
                    ccIds: string[];
                }
            );
            draft.volume_cc_service_finished = volumeServiceFinished + volumeServiceSplittedFinished;

            draft.volume_cc_service_hazard = SERVICE_HAZARD.reduce((acc, cc) => {
                cc.products.forEach((product) => {
                    acc = acc + product.volume_m3 * product.quantity;
                });
                return acc;
            }, 0);

            draft.volume_cc_service_to_pay = SERVICE_TO_PAY.reduce((acc, cc) => {
                if ('products' in cc) {
                    cc.products.forEach((product) => {
                        acc = acc + product.volume_m3 * product.quantity;
                    });
                }
                return acc;
            }, 0);
        } else {
            draft.nb_dumpster_deposit = SERVICE_NOT_SPLITTED.reduce((acc, cc) => {
                if (cc.family === CC_FAMILY.COLLECT_DUMPSTER_DEPOSIT) {
                    cc.products.forEach((product) => {
                        acc = acc + product.quantity;
                    });
                }
                return acc;
            }, 0);

            draft.nb_dumpster_retrieval = SERVICE_NOT_SPLITTED.reduce((acc, cc) => {
                if (cc.family === CC_FAMILY.COLLECT_DUMPSTER_RETRIEVAL) {
                    cc.products.forEach((product) => {
                        acc = acc + product.quantity;
                    });
                }
                return acc;
            }, 0);

            draft.nb_dumpster_rotation = SERVICE_NOT_SPLITTED.reduce((acc, cc) => {
                if (cc.family === CC_FAMILY.COLLECT_DUMPSTER_ROTATION) {
                    // do not check every products, just the first one, if you have 2 rotation, the retrieval or deposit will be 2 anyway
                    acc = acc + (cc.products[0]?.quantity ?? 0);
                }
                return acc;
            }, 0);

            draft.nb_dumpster_loadwait = SERVICE_NOT_SPLITTED.reduce((acc, cc) => {
                if (cc.family === CC_FAMILY.COLLECT_DUMPSTER_LOAD_WAIT) {
                    cc.products.forEach((product) => {
                        acc = acc + product.quantity;
                    });
                }
                return acc;
            }, 0);

            draft.nb_dumpster_total =
                draft.nb_dumpster_deposit +
                draft.nb_dumpster_retrieval +
                draft.nb_dumpster_rotation +
                draft.nb_dumpster_loadwait;

            draft.nb_dumpster_volume = SERVICE_NOT_SPLITTED.reduce((acc, cc) => {
                cc.products.forEach((product) => {
                    if (acc[product.volume_m3] === undefined) acc[product.volume_m3] = 0;
                    acc[product.volume_m3] = acc[product.volume_m3] + product.quantity;
                });

                return acc;
            }, {} as Record<number, number>);
        }
    });
};

export const getVolumeOrQuantity = (products: ProductInCCOrCO[], family: CC_FAMILY, type: EPlanningType): number => {
    if (type === EPlanningType.BIG_BAG) return getVolumeFromProducts(products, family);
    else return products.reduce((acc, p) => acc + p.quantity, 0);
};
