import styled from 'styled-components';

export const AddressOverviewContainer = styled.div<{ flex?: boolean }>`
    display: ${(props) => (props.flex ? 'flex' : 'block')};
    gap: 8px;
`;

export const VerticalContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
