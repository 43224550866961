export interface IKafkaMessage<DtoType = Record<string, any>> {
    key: string;
    request: IKafkaRequest<DtoType>;
    from: ETopicName;
    sagaName: ESagaName;
    jwt: string;
    action: EActionName;
    action_id: string;
    next_step?: string;
    redisActionKey: string;
}

export interface IKafkaRequest<DtoType = Record<string, any>> {
    payload: IKafkaPayload<DtoType>;
    [customKeys: string]: any;
}

export interface IKafkaPayload<DtoType = any> {
    query?: IKafkaQuery;
    params?: IKafkaParams;
    body?: DtoType;
    jwt?: string;
    [customKeys: string]: any;
}

export interface IKafkaQuery {
    page?: number;
    limit?: number;
    no_limit?: boolean;
    force_revert?: true;
    no_archived?: true;
    archived_only?: true;
    [key: string]: any;
}

export interface IKafkaParams {
    id?: string;
    appears_on_list?: boolean;
    [key: string]: any;
}

export enum ETopicName {
    /**
     * Generic name for default values in functions.
     **/
    NO_TOPIC_NAME = 'NO_TOPIC_NAME',

    /**
     * @LANDFILL_TOPIC
     **/
    LANDFILL_ORCHESTRATION = 'LANDFILL_ORCHESTRATION',
    LANDFILL_PLAYER = 'LANDFILL_PLAYER',

    /**
     * @USER_TOPIC
     **/
    USER_ORCHESTRATION = 'USER_ORCHESTRATION',
    USER_PLAYER = 'USER_PLAYER',

    /**
     * @COLLECTOR_TOPIC
     **/
    COLLECTOR_ORCHESTRATION = 'COLLECTOR_ORCHESTRATION',
    COLLECTOR_PLAYER = 'COLLECTOR_PLAYER',

    /**
     * @PRESTA_TOPIC
     **/
    PRESTA_ORCHESTRATION = 'PRESTA_ORCHESTRATION',
    PRESTA_PLAYER = 'PRESTA_PLAYER',

    /**
     * @ADMIN_TOPIC
     **/
    ADMIN_ORCHESTRATION = 'ADMIN_ORCHESTRATION',
    ADMIN_PLAYER = 'ADMIN_PLAYER',

    /**
     * @RETAILER_TOPIC
     **/
    CUSTOMER_ORCHESTRATION = 'CUSTOMER_ORCHESTRATION',
    CUSTOMER_PLAYER = 'CUSTOMER_PLAYER',

    /**
     * @WEBSOCKET_TOPIC
     **/
    WEBSOCKET_ORCHESTRATION = 'WEBSOCKET_ORCHESTRATION',

    // /**
    //  * @ADMIN_TOPIC
    //  **/
    // ADMIN_ORCHESTRATION = 'ADMIN_ORCHESTRATION',

    /**
     * @ITEM_TOPIC
     **/
    ITEM_ORCHESTRATION = 'ITEM_ORCHESTRATION',
    ITEM_PLAYER = 'ITEM_PLAYER',

    /**
     * @ELASTICSEARCH_TOPIC
     **/
    ELASTICSEARCH_PLAYER = 'ELASTICSEARCH_PLAYER',

    /**
     * @AUTH0_TOPIC
     **/
    AUTH0_PLAYER = 'AUTH0_PLAYER',

    /**
     * @DISCOUNT_TOPIC
     **/
    DISCOUNT_ORCHESTRATION = 'DISCOUNT_ORCHESTRATION',
    DISCOUNT_PLAYER = 'DISCOUNT_PLAYER',

    /**
     * @CONSTRUCTION_SITE_TOPIC
     **/
    CONSTRUCTION_SITE_ORCHESTRATION = 'CONSTRUCTION_SITE_ORCHESTRATION',
    CONSTRUCTION_SITE_PLAYER = 'CONSTRUCTION_SITE_PLAYER',

    /**
     * @TRUCK_TOPIC
     **/
    TRUCK_ORCHESTRATION = 'TRUCK_ORCHESTRATION',
    TRUCK_PLAYER = 'TRUCK_PLAYER',

    /**
     * @GOCARDLESS_PLAYER_TOPIC
     **/
    GOCARDLESS_PLAYER = 'GOCARDLESS_PLAYER',

    // /**
    //  * @COLLECTOR_TOPIC
    //  **/
    // COLLECTOR_ORCHESTRATION = 'COLLECTOR_ORCHESTRATION',

    // /**
    //  * @USER_TOPIC
    //  **/
    // USER_ORCHESTRATION = 'USER_ORCHESTRATION',

    /**
     * @ORDER_TOPIC
     **/
    ORDER_ORCHESTRATION = 'ORDER_ORCHESTRATION',
    ORDER_PLAYER = 'ORDER_PLAYER',

    /**
     * @COLLECT_TOPIC
     **/
    COLLECT_ORCHESTRATION = 'COLLECT_ORCHESTRATION',
    COLLECT_PLAYER = 'COLLECT_PLAYER',

    /**
     * @DUMPSTER_TOPIC
     **/
    DUMPSTER_ORCHESTRATION = 'DUMPSTER_ORCHESTRATION',
    DUMPSTER_PLAYER = 'DUMPSTER_PLAYER',

    /**
     * @ZOHO_TOPIC
     **/
    ZOHO_PLAYER = 'ZOHO_PLAYER',

    /**
     * @EMAIL_TOPIC
     **/
    EMAIL_PLAYER = 'EMAIL_PLAYER',

    /**
     * @DOCUMENT_TOPIC
     **/
    DOCUMENT_PLAYER = 'DOCUMENT_PLAYER',

    // /**
    //  * @PLANNING_CONFIG_TOPIC
    //  **/
    // PLANNING_CONFIG_PLAYER = 'PLANNING_CONFIG_PLAYER',

    /**
     * @VRP_ALGORITHM_TOPIC
     **/
    VRP_ALGORITHM_PLAYER = 'VRP_ALGORITHM_PLAYER',

    /**
     * @COLLECT_CONFIG_TOPIC
     **/
    COLLECT_CONFIG_ORCHESTRATION = 'COLLECT_CONFIG_ORCHESTRATION',
    COLLECT_CONFIG_PLAYER = 'COLLECT_CONFIG_PLAYER',

    /**
     * @PLANNING_TOPIC
     **/
    PLANNING_ORCHESTRATION = 'PLANNING_ORCHESTRATION',
    PLANNING_PLAYER = 'PLANNING_PLAYER',

    /**
     * @INVOICE_TOPIC
     **/
    INVOICE_ORCHESTRATION = 'INVOICE_ORCHESTRATION',
    INVOICE_PLAYER = 'INVOICE_PLAYER',

    /**
     * @PERMISSION_TOPIC
     **/
    PERMISSION_ORCHESTRATION = 'PERMISSION_ORCHESTRATION',
    PERMISSION_PLAYER = 'PERMISSION_PLAYER',

    /**
     * @STRIPE_TOPIC
     **/
    STRIPE_PLAYER = 'STRIPE_PLAYER',

    /**
     * @BSD
     */
    BSD_ORCHESTRATION = 'BSD_ORCHESTRATION',

    /**
     * @HERE
     */
    HERE_PLAYER = 'HERE_PLAYER',

    /**
     * @PRODUCT
     **/
    PRODUCT_ORCHESTRATION = 'PRODUCT_ORCHESTRATION',
    PRODUCT_PLAYER = 'PRODUCT_PLAYER',

    /**
     * @ZONE
     **/
    ZONE_ORCHESTRATION = 'ZONE_ORCHESTRATION',
    ZONE_PLAYER = 'ZONE_PLAYER',

    /**
     * @KPI
     **/
    KPI_ORCHESTRATION = 'KPI_ORCHESTRATION',

    /**
     * @GOOGLE_DRIVE_TOPIC
     **/
    GOOGLE_DRIVE_PLAYER = 'GOOGLE_DRIVE_PLAYER',

    /**
     * @CUSTOMER_APP_VERSION
     */
    CUSTOMER_APP_VERSION_PLAYER = 'CUSTOMER_APP_VERSION_PLAYER',

    /**
     * @AUTHENTICATION
     */
    AUTHENTICATION_PLAYER = 'AUTHENTICATION_PLAYER',

    /**
     * @SMS
     */
    SMS_PLAYER = 'SMS_PLAYER',
    SMS_ORCHESTRATION = 'SMS_ORCHESTRATION'
}

export enum ESagaName {
    /**
     * Generic name for default values in functions.
     **/
    NO_SAGA_NAME = 'NO_SAGA_NAME',

    /**
     * @LANDFILL_SAGA
     **/
    LANDFILL_POST = 'LANDFILL_POST',
    LANDFILL_GET_MANY = 'LANDFILL_GET_MANY',
    LANDFILL_PATCH = 'LANDFILL_PATCH',
    LANDFILL_ARCHIVE = 'LANDFILL_ARCHIVE',
    LANDFILL_GET = 'LANDFILL_GET',
    LANDFILL_GET_ALL = 'LANDFILL_GET_ALL',
    LANDFILL_DELETE = 'LANDFILL_DELETE',
    LANDFILL_GET_HISTORY = 'LANDFILL_GET_HISTORY',
    LANDFILL_REVALORISATION_CHARACTERISTICS_POST = 'LANDFILL_REVALORISATION_CHARACTERISTICS_POST',
    LANDFILL_REVALORISATION_CHARACTERISTICS_GET = 'LANDFILL_REVALORISATION_CHARACTERISTICS_GET',
    LANDFILL_REVALORISATION_CHARACTERISTICS_GET_ALL_BY_LANDFILL_ID = 'LANDFILL_REVALORISATION_CHARACTERISTICS_GET_ALL_BY_LANDFILL_ID',
    LANDFILL_REVALORISATION_CHARACTERISTICS_PATCH = 'LANDFILL_REVALORISATION_CHARACTERISTICS_PATCH',

    /**
     * @USER_SAGA
     **/
    USER_POST = 'USER_POST',
    USER_GET_MANY = 'USER_GET_MANY',
    USER_PATCH = 'USER_PATCH',
    USER_ARCHIVE = 'USER_ARCHIVE',
    USER_GET = 'USER_GET',
    USER_GET_ALL = 'USER_GET_ALL',
    USER_DELETE = 'USER_DELETE',
    USER_GET_HISTORY = 'USER_GET_HISTORY',

    /**
     * @COLLECTOR_SAGA
     **/
    COLLECTOR_POST = 'COLLECTOR_POST',
    COLLECTOR_GET_MANY = 'COLLECTOR_GET_MANY',
    COLLECTOR_PATCH = 'COLLECTOR_PATCH',
    COLLECTOR_ARCHIVE = 'COLLECTOR_ARCHIVE',
    COLLECTOR_GET = 'COLLECTOR_GET',
    COLLECTOR_GET_ALL = 'COLLECTOR_GET_ALL',
    COLLECTOR_DELETE = 'COLLECTOR_DELETE',
    COLLECTOR_GET_HISTORY = 'COLLECTOR_GET_HISTORY',

    /**
     * @PRESTA_SAGA
     **/
    PRESTA_POST = 'PRESTA_POST',
    PRESTA_GET_MANY = 'PRESTA_GET_MANY',
    PRESTA_PATCH = 'PRESTA_PATCH',
    PRESTA_ARCHIVE = 'PRESTA_ARCHIVE',
    PRESTA_GET = 'PRESTA_GET',
    PRESTA_GET_ALL = 'PRESTA_GET_ALL',
    PRESTA_DELETE = 'PRESTA_DELETE',
    PRESTA_GET_HISTORY = 'PRESTA_GET_HISTORY',

    /**
     * @ADMIN_SAGA
     **/
    ADMIN_POST = 'ADMIN_POST',
    ADMIN_GET_MANY = 'ADMIN_GET_MANY',
    ADMIN_PATCH = 'ADMIN_PATCH',
    ADMIN_ARCHIVE = 'ADMIN_ARCHIVE',
    ADMIN_GET = 'ADMIN_GET',
    ADMIN_GET_ALL = 'ADMIN_GET_ALL',
    ADMIN_DELETE = 'ADMIN_DELETE',
    ADMIN_GET_HISTORY = 'ADMIN_GET_HISTORY',
    ADMIN_INITIATE_LOGIN = 'ADMIN_INITIATE_LOGIN',
    ADMIN_LOGIN = 'ADMIN_LOGIN',
    ADMIN_CHECK_SESSION = 'ADMIN_CHECK_SESSION',
    ADMIN_CREATE_SESSION = 'ADMIN_CREATE_SESSION',
    ADMIN_SIGNOUT = 'ADMIN_SIGNOUT',

    /**
     * @CUSTOMER_SAGA
     **/
    CUSTOMER_POST = 'CUSTOMER_POST',
    CUSTOMER_GET_MANY = 'CUSTOMER_GET_MANY',
    CUSTOMER_PATCH = 'CUSTOMER_PATCH',
    CUSTOMER_ARCHIVE = 'CUSTOMER_ARCHIVE',
    CUSTOMER_GET = 'CUSTOMER_GET',
    CUSTOMER_GET_ALL = 'CUSTOMER_GET_ALL',
    CUSTOMER_EXPORT = 'CUSTOMER_EXPORT',
    CUSTOMER_DELETE = 'CUSTOMER_DELETE',
    CUSTOMER_SETUP_MANDATE = 'CUSTOMER_SETUP_MANDATE',
    CUSTOMER_GET_HISTORY = 'CUSTOMER_GET_HISTORY',
    CUSTOMER_PATCH_FROM_ZOHO_CUSTOMER_ID = 'CUSTOMER_PATCH_FROM_ZOHO_CUSTOMER_ID',
    CUSTOMER_BILLING_MODE_INVOICE_GENERATE = 'CUSTOMER_BILLING_MODE_INVOICE_GENERATE',
    CUSTOMER_UPLOAD_BSD_ON_GOOGLE_DRIVE = 'CUSTOMER_UPLOAD_BSD_ON_GOOGLE_DRIVE',
    CUSTOMER_GD_CREATE_MISSING_GOOGLE_DRIVE = 'CUSTOMER_GD_CREATE_MISSING_GOOGLE_DRIVE',
    CUSTOMER_GD_CREATE_MISSING_MONTH_FOLDER = 'CUSTOMER_GD_CREATE_MISSING_MONTH_FOLDER',
    CUSTOMER_GD_CREATE_MISSING_CONS_SITE_FOLDER = 'CUSTOMER_GD_CREATE_MISSING_CONS_SITE_FOLDER',
    CUSTOMER_GD_UPLOAD_MISSING_BSD = 'CUSTOMER_GD_UPLOAD_MISSING_BSD',
    CUSTOMER_CREATE_ZOHO = 'CUSTOMER_CREATE_ZOHO',
    CUSTOMER_CHECK_SIRET = 'CUSTOMER_CHECK_SIRET',
    CUSTOMER_CREATE_MISSING_GOOGLE_DRIVE = 'CUSTOMER_CREATE_MISSING_GOOGLE_DRIVE',
    CUSTOMER_UPLOAD_BLC_ON_GOOGLE_DRIVE = 'CUSTOMER_UPLOAD_BLC_ON_GOOGLE_DRIVE',
    CUSTOMER_CREATE_MANDATE_FLOW_GOCARDLESS = 'CUSTOMER_CREATE_MANDATE_FLOW_GOCARDLESS',
    CUSTOMER_GET_ALL_MANDATES = 'CUSTOMER_GET_ALL_MANDATES',
    CUSTOMER_HANDLE_WEBHOOK_MANDATES_GO_CARDLESS = 'CUSTOMER_HANDLE_WEBHOOK_MANDATES_GO_CARDLESS',
    CUSTOMER_CREATE_INVITATION = 'CUSTOMER_CREATE_INVITATION',
    CUSTOMER_ACCEPT_INVITATION = 'CUSTOMER_ACCEPT_INVITATION',
    CUSTOMER_DELETE_INVITATION = 'CUSTOMER_DELETE_INVITATION',
    CUSTOMER_EXPORT_REVALORISATION = 'CUSTOMER_EXPORT_REVALORISATION',
    CUSTOMER_GET_ALL_ORDER_CONFIRMATION_EMAIL_BY_CUSTOMER = 'CUSTOMER_GET_ALL_ORDER_CONFIRMATION_EMAIL_BY_CUSTOMER',
    CUSTOMER_GET_MY_KPI_DATA = 'CUSTOMER_GET_MY_KPI_DATA',
    CUSTOMER_GET_MY_REVALO = 'CUSTOMER_GET_MY_REVALO',
    CUSTOMER_REMOVE_USER = 'CUSTOMER_REMOVE_USER',

    // /**
    //  * @ADMIN_SAGA
    //  **/
    // ADMIN_SET_ROLE = 'ADMIN_SET_ROLE',
    // ADMIN_POST = 'ADMIN_POST',
    // ADMIN_GET = 'ADMIN_GET',
    // ADMIN_GET_ALL = 'ADMIN_GET_ALL',
    // ADMIN_GET_ALL_BY_CUSTOMER = 'ADMIN_GET_ALL_BY_CUSTOMER',
    // ADMIN_PATCH = 'ADMIN_PATCH',
    // ADMIN_ARCHIVE = 'ADMIN_ARCHIVE',
    // ADMIN_DELETE = 'ADMIN_DELETE',
    // ADMIN_GET_HISTORY = 'ADMIN_GET_HISTORY',

    /**
     * @ITEM_SAGAw
     **/
    ITEM_POST = 'ITEM_POST',
    ITEM_GET_MANY = 'ITEM_GET_MANY',
    ITEM_UPLOAD = 'ITEM_UPLOAD',
    ITEM_GET = 'ITEM_GET',
    ITEM_GET_ALL = 'ITEM_GET_ALL',
    ITEM_PATCH = 'ITEM_PATCH',
    ITEM_DELETE = 'ITEM_DELETE',
    ITEM_ARCHIVE = 'ITEM_ARCHIVE',
    ITEM_GET_HISTORY = 'ITEM_GET_HISTORY',

    /**
     * @DISCOUNT_SAGA
     **/
    DISCOUNT_POST = 'DISCOUNT_POST',
    DISCOUNT_GET_MANY = 'DISCOUNT_GET_MANY',
    DISCOUNT_GET = 'DISCOUNT_GET',
    DISCOUNT_GET_ALL = 'DISCOUNT_GET_ALL',
    DISCOUNT_PATCH = 'DISCOUNT_PATCH',
    DISCOUNT_DELETE = 'DISCOUNT_DELETE',
    DISCOUNT_ARCHIVE = 'DISCOUNT_ARCHIVE',
    DISCOUNT_GET_HISTORY = 'DISCOUNT_GET_HISTORY',

    /**
     * @CONSTRUCTION_SITE_SAGA
     **/
    CONSTRUCTION_SITE_POST = 'CONSTRUCTION_SITE_POST',
    CONSTRUCTION_SITE_GET_MANY = 'CONSTRUCTION_SITE_GET_MANY',
    CONSTRUCTION_SITE_GET = 'CONSTRUCTION_SITE_GET',
    CONSTRUCTION_SITE_GET_ALL = 'CONSTRUCTION_SITE_GET_ALL',
    CONSTRUCTION_SITE_GET_ALL_BY_USER = 'CONSTRUCTION_SITE_GET_ALL_BY_USER',
    CONSTRUCTION_SITE_PATCH = 'CONSTRUCTION_SITE_PATCH',
    CONSTRUCTION_SITE_DELETE = 'CONSTRUCTION_SITE_DELETE',
    CONSTRUCTION_SITE_ARCHIVE = 'CONSTRUCTION_SITE_ARCHIVE',
    CONSTRUCTION_SITE_GET_HISTORY = 'CONSTRUCTION_SITE_GET_HISTORY',
    CONSTRUCTION_SITE_CHECK_BY_CUSTOMER = 'CONSTRUCTION_SITE_CHECK_BY_CUSTOMER',

    /**
     * @TRUCK_SAGA
     **/
    TRUCK_POST = 'TRUCK_POST',
    TRUCK_GET_MANY = 'TRUCK_GET_MANY',
    TRUCK_GET = 'TRUCK_GET',
    TRUCK_GET_ALL = 'TRUCK_GET_ALL',
    TRUCK_PATCH = 'TRUCK_PATCH',
    TRUCK_DELETE = 'TRUCK_DELETE',
    TRUCK_ARCHIVE = 'TRUCK_ARCHIVE',
    TRUCK_GET_HISTORY = 'TRUCK_GET_HISTORY',

    // /**
    //  * @COLLECTOR_SAGA
    //  **/
    // COLLECTOR_POST = 'COLLECTOR_POST',
    // COLLECTOR_GET = 'COLLECTOR_GET',
    // COLLECTOR_GET_ALL = 'COLLECTOR_GET_ALL',
    // COLLECTOR_PATCH = 'COLLECTOR_PATCH',
    // COLLECTOR_DELETE = 'COLLECTOR_DELETE',
    // COLLECTOR_ARCHIVE = 'COLLECTOR_ARCHIVE',
    // COLLECTOR_GET_HISTORY = 'COLLECTOR_GET_HISTORY',

    // /**
    //  * @USER_SAGA
    //  **/
    // USER_POST = 'USER_POST',
    // USER_GET = 'USER_GET',
    // USER_GET_ALL = 'USER_GET_ALL',
    // USER_GET_ALL_BY_CUSTOMER_ID = 'USER_GET_ALL_BY_CUSTOMER_ID',
    // USER_PATCH = 'USER_PATCH',
    // USER_PATCH_FROM_ZOHO_EMAIL = 'USER_PATCH_FROM_ZOHO_EMAIL',
    // USER_DELETE = 'USER_DELETE',
    // USER_ARCHIVE = 'USER_ARCHIVE',
    // USER_ASSIGN_SPECIFIC_ROLE = 'USER_ASSIGN_SPECIFIC_ROLE',
    // USER_ASSIGN_CUSTOM_ROLE = 'USER_ASSIGN_CUSTOM_ROLE',
    // USER_GET_HISTORY = 'USER_GET_HISTORY',

    /**
     * @ORDER_SAGA
     **/
    ORDER_POST = 'ORDER_POST',
    ORDER_PATCH = 'ORDER_PATCH',
    ORDER_GET_MANY = 'ORDER_GET_MANY',
    ORDER_GET = 'ORDER_GET',
    ORDER_PAY = 'ORDER_PAY',
    ORDER_GET_ALL = 'ORDER_GET_ALL',
    ORDER_DELETE = 'ORDER_DELETE',
    ORDER_GET_HISTORY = 'ORDER_GET_HISTORY',
    ORDER_ARCHIVE = 'ORDER_ARCHIVE',
    ORDER_RESEND_LINK = 'ORDER_RESEND_LINK',
    ORDER_INSTANT_DELIVERY = 'ORDER_INSTANT_DELIVERY',
    ORDER_CONFIRM = 'ORDER_CONFIRM',
    ORDER_CALCULATE_CART_PRICE = 'ORDER_CALCULATE_CART_PRICE',
    ORDER_GET_NEXT_AVAILABLE_DAY = 'ORDER_GET_NEXT_AVAILABLE_DAY',

    /**
     * @COLLECT_SAGA
     **/
    COLLECT_POST_SERVICE_SUCCESS = 'COLLECT_POST_SERVICE_SUCCESS',
    COLLECT_POST_SERVICE_HAZARD = 'COLLECT_POST_SERVICE_HAZARD',
    COLLECT_POST_ADMINISTRATIVE = 'COLLECT_POST_ADMINISTRATIVE',
    COLLECT_POST_BREAK = 'COLLECT_POST_BREAK',
    COLLECT_POST_DRIVER = 'COLLECT_POST_DRIVER',
    COLLECT_POST_EMPTYING = 'COLLECT_POST_EMPTYING',
    COLLECT_POST_EMPTYING_HAZARD = 'COLLECT_POST_EMPTYING_HAZARD',
    COLLECT_GET = 'COLLECT_GET',
    COLLECT_GET_MANY = 'COLLECT_GET_MANY',
    COLLECT_GET_ALL = 'COLLECT_GET_ALL',
    COLLECT_GET_ALL_WITH_RELATIONS = 'COLLECT_GET_ALL_WITH_RELATIONS',
    COLLECT_DELETE = 'COLLECT_DELETE',
    COLLECT_ARCHIVE = 'COLLECT_ARCHIVE',
    COLLECT_POST_SERVICE_NOT_PLANNED = 'COLLECT_POST_SERVICE_NOT_PLANNED',
    // COLLECT_PATCH = 'COLLECT_PATCH', // REPLACE BY COMPLETE/HAZARD
    // COLLECT_COMPLETE = 'COLLECT_COMPLETE',
    // COLLECT_HAZARD = 'COLLECT_HAZARD',
    COLLECT_GET_HISTORY = 'COLLECT_GET_HISTORY',
    COLLECT_SPLITTED_CALCULATE_PRICE = 'COLLECT_SPLITTED_CALCULATE_PRICE',
    COLLECT_PATCH_SERVICE = 'COLLECT_PATCH_SERVICE',
    COLLECT_FIND_DUMPSTER = 'COLLECT_FIND_DUMPSTER',

    /**
     * @DUMPSTER_ON_SITE_SAGA (in collect orchestrator)
     */
    DUMPSTER_ON_SITE_POST = 'DUMPSTER_ON_SITE_POST',
    DUMPSTER_ON_SITE_PATCH = 'DUMPSTER_ON_SITE_PATCH',
    DUMPSTER_ON_SITE_GET = 'DUMPSTER_ON_SITE_GET',
    DUMPSTER_ON_SITE_GET_MANY = 'DUMPSTER_ON_SITE_GET_MANY',
    DUMPSTER_ON_SITE_GET_ALL = 'DUMPSTER_ON_SITE_GET_ALL',
    DUMPSTER_ON_SITE_ARCHIVE = 'DUMPSTER_ON_SITE_ARCHIVE',
    DUMPSTER_ON_SITE_GET_HISTORY = 'DUMPSTER_ON_SITE_GET_HISTORY',
    DUMPSTER_ON_SITE_PREPARE_SYNC = 'DUMPSTER_ON_SITE_PREPARE_SYNC',
    DUMPSTER_ON_SITE_EXECUTE_SYNC = 'DUMPSTER_ON_SITE_EXECUTE_SYNC',

    /**
     * @DUMPSTER_SAGA
     **/
    DUMPSTER_POST = 'DUMPSTER_POST',
    DUMPSTER_GET_MANY = 'DUMPSTER_GET_MANY',
    DUMPSTER_GET = 'DUMPSTER_GET',
    DUMPSTER_GET_ALL = 'DUMPSTER_GET_ALL',
    DUMPSTER_PATCH = 'DUMPSTER_PATCH',
    DUMPSTER_DELETE = 'DUMPSTER_DELETE',
    DUMPSTER_ARCHIVE = 'DUMPSTER_ARCHIVE',
    DUMPSTER_GET_HISTORY = 'DUMPSTER_GET_HISTORY',

    /**
     * @COLLECT_CONFIG_SAGA
     **/
    COLLECT_CONFIG_GET_MANY = 'COLLECT_CONFIG_GET_MANY',
    COLLECT_CONFIG_GET = 'COLLECT_CONFIG_GET',
    COLLECT_CONFIG_GET_ALL = 'COLLECT_CONFIG_GET_ALL',
    COLLECT_CONFIG_GET_ALL_BY_USER = 'COLLECT_CONFIG_GET_ALL_BY_USER',
    COLLECT_CONFIG_PATCH = 'COLLECT_CONFIG_PATCH',
    COLLECT_CONFIG_DELETE = 'COLLECT_CONFIG_DELETE',
    COLLECT_CONFIG_ARCHIVE = 'COLLECT_CONFIG_ARCHIVE',
    COLLECT_CONFIG_MERGE = 'COLLECT_CONFIG_MERGE',
    COLLECT_CONFIG_SPLIT = 'COLLECT_CONFIG_SPLIT',
    COLLECT_CONFIG_VALIDATE = 'COLLECT_CONFIG_VALIDATE',
    COLLECT_CONFIG_CANCEL = 'COLLECT_CONFIG_CANCEL',
    COLLECT_CONFIG_GET_HISTORY = 'COLLECT_CONFIG_GET_HISTORY',
    COLLECT_CONFIG_FINISH = 'COLLECT_CONFIG_FINISH',
    COLLECT_CONFIG_POSTPONE = 'COLLECT_CONFIG_POSTPONE',
    COLLECT_CONFIG_DENSITY = 'COLLECT_CONFIG_DENSITY',
    COLLECT_CONFIG_REPLAN = 'COLLECT_CONFIG_REPLAN',
    COLLECT_CONFIG_EXPORT = 'COLLECT_CONFIG_EXPORT',

    CC_REPLAN_ACTIVITY_GET_ALL = 'CC_REPLAN_ACTIVITY_GET_ALL',

    CC_ADMINISTRATIVE_POST = 'CC_ADMINISTRATIVE_POST',
    CC_ADMINISTRATIVE_GET_MANY = 'CC_ADMINISTRATIVE_GET_MANY',
    CC_ADMINISTRATIVE_GET = 'CC_ADMINISTRATIVE_GET',
    CC_ADMINISTRATIVE_GET_ALL = 'CC_ADMINISTRATIVE_GET_ALL',
    CC_ADMINISTRATIVE_PATCH = 'CC_ADMINISTRATIVE_PATCH',
    CC_ADMINISTRATIVE_DELETE = 'CC_ADMINISTRATIVE_DELETE',
    CC_ADMINISTRATIVE_ARCHIVE = 'CC_ADMINISTRATIVE_ARCHIVE',
    CC_ADMINISTRATIVE_CANCEL = 'CC_ADMINISTRATIVE_CANCEL',
    CC_ADMINISTRATIVE_GET_HISTORY = 'CC_ADMINISTRATIVE_GET_HISTORY',

    CC_NOTIFY_SMS_BEGIN_COLLECT = 'CC_NOTIFY_SMS_BEGIN_COLLECT',
    CC_PUBLIC_INFORMATIONS_SMS_PREVIEW = 'CC_PUBLIC_INFORMATIONS_SMS_PREVIEW',
    CC_PUBLIC_INFORMATION_GET = 'CC_PUBLIC_INFORMATION_GET',

    /**
     * @PLANNING_SAGA
     **/
    PLANNING_POST = 'PLANNING_POST',
    PLANNING_GET_MANY = 'PLANNING_GET_MANY',
    PLANNING_CALCULATE = 'PLANNING_CALCULATE',
    PLANNING_MANUAL_SHIFT = 'PLANNING_MANUAL_SHIFT',
    PLANNING_GET_ALL = 'PLANNING_GET_ALL',
    PLANNING_DELETE = 'PLANNING_DELETE',
    PLANNING_ARCHIVE = 'PLANNING_ARCHIVE',
    PLANNING_GET_HISTORY = 'PLANNING_GET_HISTORY',
    PLANNING_CONSTANT_PATCH = 'PLANNING_CONSTANT_PATCH',
    PLANNING_CONSTANT_GET_ALL = 'PLANNING_CONSTANT_GET_ALL',
    PLANNING_CONSTANT_GET = 'PLANNING_CONSTANT_GET',
    PLANNING_SAVE = 'PLANNING_SAVE',
    PLANNING_ME = 'PLANNING_ME',
    PLANNING_UNASSIGN = 'PLANNING_UNASSIGN',
    PLANNING_GET_PRICE = 'PLANNING_GET_PRICE',
    PLANNING_RECALCULATE = 'PLANNING_RECALCULATE',
    PLANNING_EXPORT = 'PLANNING_EXPORT',
    PLANNING_FLATTEN = 'PLANNING_FLATTEN',
    PLANNING_UNASSIGN_CC_FROM_PLANNING = 'PLANNING_UNASSIGN_CC_FROM_PLANNING',
    PLANNING_GET_INSTANCE = 'PLANNING_GET_INSTANCE',
    PLANNING_CUSTOM_CALCULATE = 'PLANNING_CUSTOM_CALCULATE',
    PLANNING_GET_VERSIONS = 'PLANNING_GET_VERSIONS',
    PLANNING_REMOVE_STEP = 'PLANNING_REMOVE_STEP',

    /**
     * @INVOICE_SAGA
     **/
    INVOICE_GENERATE_FORMAT = 'INVOICE_GENERATE_FORMAT',
    INVOICE_GENERATE_EXECUTE = 'INVOICE_GENERATE_EXECUTE',
    INVOICE_PRE_GENERATE = 'INVOICE_PRE_GENERATE',
    INVOICE_EMAIL = 'INVOICE_EMAIL',
    INVOICE_GET = 'INVOICE_GET',
    INVOICE_GET_MANY = 'INVOICE_GET_MANY',
    INVOICE_GET_ALL = 'INVOICE_GET_ALL',
    INVOICE_PATCH_FROM_ZOHO_INVOICE_ID = 'INVOICE_PATCH_FROM_ZOHO_INVOICE_ID',
    INVOICE_ARCHIVE = 'INVOICE_ARCHIVE',
    INVOICE_AFTER_COLLECT_GENERATE = 'INVOICE_AFTER_COLLECT_GENERATE',
    INVOICE_AFTER_DELIVERY_GENERATE = 'INVOICE_AFTER_DELIVERY_GENERATE',
    INVOICE_AFTER_MANUAL_PAYMENT_GENERATE = 'INVOICE_AFTER_MANUAL_PAYMENT_GENERATE',
    INVOICE_AFTER_PAID_LATE_CANCELATION = 'INVOICE_AFTER_PAID_LATE_CANCELATION',
    INVOICE_DEBIT = 'INVOICE_DEBIT',
    INVOICE_DOWNLOAD = 'INVOICE_DOWNLOAD',
    INVOICE_UPSERT = 'INVOICE_UPSERT',

    /**
     * @BSD_SAGA
     */
    BSD_GENERATE = 'BSD_GENERATE',
    BSD_MANUAL_GENERATE = 'BSD_MANUAL_GENERATE',
    BLC_GENERATE = 'BLC_GENERATE',

    /**
     * @PRODUCT_SAGA
     **/
    PRODUCT_GET = 'PRODUCT_GET',
    PRODUCT_PUBLIC_GET_ALL = 'PRODUCT_PUBLIC_GET_ALL',
    PRODUCT_PUBLIC_GET_ALL_WITH_PRICE = 'PRODUCT_PUBLIC_GET_ALL_WITH_PRICE',
    PRODUCT_GET_ALL = 'PRODUCT_GET_ALL',
    PRODUCT_GET_MANY = 'PRODUCT_GET_MANY',
    PRODUCT_POST = 'PRODUCT_POST',
    PRODUCT_PATCH = 'PRODUCT_PATCH',
    PRODUCT_DELETE = 'PRODUCT_DELETE',
    PRODUCT_ARCHIVE = 'PRODUCT_ARCHIVE',
    PRODUCT_GET_HISTORY = 'PRODUCT_GET_HISTORY',
    PRODUCT_SET_VISIBILITY = 'PRODUCT_SET_VISIBILITY',
    PRODUCT_GET_CANCELATION_AMOUNT = 'PRODUCT_GET_CANCELATION_AMOUNT',

    /**
     * @ZONE_SAGA
     **/
    ZONE_GET = 'ZONE_GET',
    ZONE_GET_ALL = 'ZONE_GET_ALL',
    ZONE_GET_MANY = 'ZONE_GET_MANY',
    ZONE_POST = 'ZONE_POST',
    ZONE_PATCH = 'ZONE_PATCH',
    ZONE_DELETE = 'ZONE_DELETE',
    ZONE_ARCHIVE = 'ZONE_ARCHIVE',
    ZONE_GET_HISTORY = 'ZONE_GET_HISTORY',

    /**
     * @SMS_SAGA
     */
    SMS_GET_ALL = 'SMS_GET_ALL',

    /**
     * @KPI_SAGA
     **/
    KPI_GET = 'KPI_GET'
}

export enum EActionName {
    /**
     * Generic name for default values in functions.
     **/
    NO_ACTION_NAME = 'NO_ACTION_NAME',

    /**
     * Generic name for bulk actions.
     */
    BULK = 'BULK',

    /**
     * @LANDFILL_ACTION
     **/
    LANDFILL_CREATE = 'LANDFILL_CREATE',
    LANDFILL_READ = 'LANDFILL_READ',
    LANDFILL_READ_MANY = 'LANDFILL_READ_MANY',
    LANDFILL_READ_ALL = 'LANDFILL_READ_ALL',
    LANDFILL_UPDATE = 'LANDFILL_UPDATE',
    LANDFILL_VIEW_ON_LIST = 'LANDFILL_VIEW_ON_LIST',
    LANDFILL_ADD_RELATION = 'LANDFILL_ADD_RELATION',
    LANDFILL_REMOVE_RELATION = 'LANDFILL_REMOVE_RELATION',
    LANDFILL_REMOVE = 'LANDFILL_REMOVE',
    LANDFILL_READ_HISTORY = 'LANDFILL_READ_HISTORY',
    LANDFILL_REVALORISATION_CREATE = 'LANDFILL_REVALORISATION_CREATE',
    LANDFILL_REVALORISATION_UPDATE = 'LANDFILL_REVALORISATION_UPDATE',
    LANDFILL_REVALORISATION_READ = 'LANDFILL_REVALORISATION_READ',
    LANDFILL_REVALORISATION_READ_ALL = 'LANDFILL_REVALORISATION_READ_ALL',
    LANDFILL_REVALORISATION_REMOVE = 'LANDFILL_REVALORISATION_REMOVE',
    LANDFILL_REVALORISATION_READ_MANY_BY_LANDFILL_IDS = 'LANDFILL_REVALORISATION_READ_MANY_BY_LANDFILL_IDS',

    /**
     * @USER_ACTION
     **/
    USER_CREATE = 'USER_CREATE',
    USER_READ_BY_EMAIL = 'USER_READ_BY_EMAIL',
    USER_READ = 'USER_READ',
    USER_READ_MANY = 'USER_READ_MANY',
    USER_READ_ALL = 'USER_READ_ALL',
    USER_UPDATE = 'USER_UPDATE',
    USER_VIEW_ON_LIST = 'USER_VIEW_ON_LIST',
    USER_ADD_RELATION = 'USER_ADD_RELATION',
    USER_REMOVE_RELATION = 'USER_REMOVE_RELATION',
    USER_REMOVE = 'USER_REMOVE',
    USER_READ_HISTORY = 'USER_READ_HISTORY',
    USER_REMOVE_FROM_CUSTOMER = 'USER_REMOVE_FROM_CUSTOMER',

    /**
     * @COLLECTOR_ACTION
     **/
    COLLECTOR_CREATE = 'COLLECTOR_CREATE',
    COLLECTOR_READ = 'COLLECTOR_READ',
    COLLECTOR_READ_BY_EMAIL = 'COLLECTOR_READ_BY_EMAIL',
    COLLECTOR_READ_MANY = 'COLLECTOR_READ_MANY',
    COLLECTOR_READ_ALL = 'COLLECTOR_READ_ALL',
    COLLECTOR_UPDATE = 'COLLECTOR_UPDATE',
    COLLECTOR_VIEW_ON_LIST = 'COLLECTOR_VIEW_ON_LIST',
    COLLECTOR_ADD_RELATION = 'COLLECTOR_ADD_RELATION',
    COLLECTOR_REMOVE_RELATION = 'COLLECTOR_REMOVE_RELATION',
    COLLECTOR_REMOVE = 'COLLECTOR_REMOVE',
    COLLECTOR_READ_HISTORY = 'COLLECTOR_READ_HISTORY',

    /**
     * @PRESTA_ACTION
     **/
    PRESTA_CREATE = 'PRESTA_CREATE',
    PRESTA_READ = 'PRESTA_READ',
    PRESTA_READ_BY_EMAIL = 'PRESTA_READ_BY_EMAIL',
    PRESTA_READ_MANY = 'PRESTA_READ_MANY',
    PRESTA_READ_ALL = 'PRESTA_READ_ALL',
    PRESTA_UPDATE = 'PRESTA_UPDATE',
    PRESTA_VIEW_ON_LIST = 'PRESTA_VIEW_ON_LIST',
    PRESTA_ADD_RELATION = 'PRESTA_ADD_RELATION',
    PRESTA_REMOVE_RELATION = 'PRESTA_REMOVE_RELATION',
    PRESTA_REMOVE = 'PRESTA_REMOVE',
    PRESTA_READ_HISTORY = 'PRESTA_READ_HISTORY',

    /**
     * @ADMIN_ACTION
     **/
    ADMIN_CREATE = 'ADMIN_CREATE',
    ADMIN_READ = 'ADMIN_READ',
    ADMIN_READ_BY_EMAIL = 'ADMIN_READ_BY_EMAIL',
    ADMIN_VERIFY = 'ADMIN_VERIFY',
    ADMIN_READ_MANY = 'ADMIN_READ_MANY',
    ADMIN_READ_ALL = 'ADMIN_READ_ALL',
    ADMIN_UPDATE = 'ADMIN_UPDATE',
    ADMIN_VIEW_ON_LIST = 'ADMIN_VIEW_ON_LIST',
    ADMIN_ADD_RELATION = 'ADMIN_ADD_RELATION',
    ADMIN_REMOVE_RELATION = 'ADMIN_REMOVE_RELATION',
    ADMIN_REMOVE = 'ADMIN_REMOVE',
    ADMIN_READ_HISTORY = 'ADMIN_READ_HISTORY',

    /**
     * @CUSTOMER_ACTION
     **/
    CUSTOMER_CREATE = 'CUSTOMER_CREATE',
    CUSTOMER_READ = 'CUSTOMER_READ',
    CUSTOMER_READ_BY_SIRET = 'CUSTOMER_READ_BY_SIRET',
    CUSTOMER_READ_MANY = 'CUSTOMER_READ_MANY',
    CUSTOMER_READ_ALL = 'CUSTOMER_READ_ALL',
    CUSTOMER_UPDATE = 'CUSTOMER_UPDATE',
    CUSTOMER_VIEW_ON_LIST = 'CUSTOMER_VIEW_ON_LIST',
    CUSTOMER_ADD_RELATION = 'CUSTOMER_ADD_RELATION',
    CUSTOMER_REMOVE_RELATION = 'CUSTOMER_REMOVE_RELATION',
    CUSTOMER_REMOVE = 'CUSTOMER_REMOVE',
    CUSTOMER_SET_DEFAULT_PAYMENT_METHOD = 'CUSTOMER_SET_DEFAULT_PAYMENT_METHOD',
    CUSTOMER_READ_HISTORY = 'CUSTOMER_READ_HISTORY',
    CUSTOMER_CHECK_EXISTING_BY_SIRET = 'CUSTOMER_CHECK_EXISTING_BY_SIRET',

    CUSTOMER_MANDATE_CREATE = 'CUSTOMER_MANDATE_CREATE',
    CUSTOMER_MANDATE_READ = 'CUSTOMER_MANDATE_READ',
    CUSTOMER_MANDATE_READ_MANY = 'CUSTOMER_MANDATE_READ_MANY',
    CUSTOMER_MANDATE_READ_ALL = 'CUSTOMER_MANDATE_READ_ALL',
    CUSTOMER_MANDATE_UPDATE = 'CUSTOMER_MANDATE_UPDATE',
    CUSTOMER_MANDATE_REMOVE = 'CUSTOMER_MANDATE_REMOVE',

    CUSTOMER_ORDER_CONFIRMATION_EMAIL_READ_ALL_BY_CUSTOMER = 'CUSTOMER_ORDER_CONFIRMATION_EMAIL_READ_ALL_BY_CUSTOMER',
    CUSTOMER_ORDER_CONFIRMATION_EMAIL_INSERT = 'CUSTOMER_ORDER_CONFIRMATION_EMAIL_INSERT', // not create to avoid reveal
    CUSTOMER_ORDER_CONFIRMATION_EMAIL_REMOVE = 'CUSTOMER_ORDER_CONFIRMATION_EMAIL_REMOVE',

    /**
     * @ELASTICSEARCH_ACTION
     **/
    ELASTICSEARCH_FIND = 'ELASTICSEARCH_FIND',
    ELASTICSEARCH_QUERY = 'ELASTICSEARCH_QUERY',

    /**
     * @ITEM_ACTION
     **/
    ITEM_CREATE = 'ITEM_CREATE',
    ITEM_READ = 'ITEM_READ',
    ITEM_READ_MANY = 'ITEM_READ_MANY',
    ITEM_READ_ALL = 'ITEM_READ_ALL',
    ITEM_UPDATE = 'ITEM_UPDATE',
    ITEM_ADD_RELATION = 'ITEM_ADD_RELATION',
    ITEM_REMOVE_RELATION = 'ITEM_REMOVE_RELATION',
    ITEM_REMOVE = 'ITEM_REMOVE',
    ITEM_VIEW_ON_LIST = 'ITEM_VIEW_ON_LIST',
    ITEM_READ_HISTORY = 'ITEM_READ_HISTORY',

    /**
     * @AUTH0_ACTION
     **/
    AUTH0_PROVIDER_USER_CREATE = 'AUTH0_PROVIDER_USER_CREATE',
    AUTH0_PROVIDER_COLLECTOR_CREATE = 'AUTH0_PROVIDER_COLLECTOR_CREATE',
    AUTH0_INTERNAL_CREATE = 'AUTH0_INTERNAL_CREATE',
    AUTH0_PROVIDER_UPDATE = 'AUTH0_PROVIDER_UPDATE',
    AUTH0_PROVIDER_READ = 'AUTH0_PROVIDER_READ',
    AUTH0_INTERNAL_READ = 'AUTH0_INTERNAL_READ',
    AUTH0_PROVIDER_USER_READ_ALL = 'AUTH0_PROVIDER_USER_READ_ALL',
    AUTH0_PROVIDER_ADMIN_READ_ALL = 'AUTH0_PROVIDER_ADMIN_READ_ALL',
    AUTH0_PROVIDER_COLLECTOR_READ_ALL = 'AUTH0_PROVIDER_COLLECTOR_READ_ALL',
    AUTH0_INTERNAL_READ_ALL = 'AUTH0_INTERNAL_READ_ALL',
    AUTH0_PROVIDER_VIEW_ON_LIST = 'AUTH0_PROVIDER_VIEW_ON_LIST',
    AUTH0_INTERNAL_VIEW_ON_LIST = 'AUTH0_INTERNAL_VIEW_ON_LIST',
    AUTH0_PROVIDER_USER_ROLES_READ = 'AUTH0_PROVIDER_USER_ROLES_READ',
    AUTH0_PROVIDER_USER_PERMISSIONS_READ = 'AUTH0_PROVIDER_USER_PERMISSIONS_READ',
    AUTH0_PROVIDER_ASSIGN_SPECIFIC_ROLE = 'AUTH0_PROVIDER_ASSIGN_SPECIFIC_ROLE',
    AUTH0_PROVIDER_ASSIGN_CUSTOM_ROLE = 'AUTH0_PROVIDER_ASSIGN_CUSTOM_ROLE',
    AUTH0_ADD_RELATION = 'AUTH0_ADD_RELATION',
    AUTH0_REMOVE_RELATION = 'AUTH0_REMOVE_RELATION',
    AUTH0_PROVIDER_REMOVE = 'AUTH0_PROVIDER_REMOVE',
    AUTH0_INTERNAL_REMOVE = 'AUTH0_INTERNAL_REMOVE',
    AUTH0_INTERNAL_READ_HISTORY = 'AUTH0_INTERNAL_READ_HISTORY',

    /**
     * @DISCOUNT_ACTION
     */
    DISCOUNT_CREATE = 'DISCOUNT_CREATE',
    DISCOUNT_READ = 'DISCOUNT_READ',
    DISCOUNT_READ_MANY = 'DISCOUNT_READ_MANY',
    DISCOUNT_READ_ALL = 'DISCOUNT_READ_ALL',
    DISCOUNT_UPDATE = 'DISCOUNT_UPDATE',
    DISCOUNT_ADD_RELATION = 'DISCOUNT_ADD_RELATION',
    DISCOUNT_REMOVE_RELATION = 'DISCOUNT_REMOVE_RELATION',
    DISCOUNT_REMOVE = 'DISCOUNT_REMOVE',
    DISCOUNT_VIEW_ON_LIST = 'DISCOUNT_VIEW_ON_LIST',
    DISCOUNT_READ_HISTORY = 'DISCOUNT_READ_HISTORY',

    /**
     * @CONSTRUCTION_SITE_ACTION
     */
    CONSTRUCTION_SITE_CREATE = 'CONSTRUCTION_SITE_CREATE',
    CONSTRUCTION_SITE_READ = 'CONSTRUCTION_SITE_READ',
    CONSTRUCTION_SITE_READ_MANY = 'CONSTRUCTION_SITE_READ_MANY',
    CONSTRUCTION_SITE_READ_ALL = 'CONSTRUCTION_SITE_READ_ALL',
    CONSTRUCTION_SITE_READ_ALL_BY_USER = 'CONSTRUCTION_SITE_READ_ALL_BY_USER',
    CONSTRUCTION_SITE_UPDATE = 'CONSTRUCTION_SITE_UPDATE',
    CONSTRUCTION_SITE_ADD_RELATION = 'CONSTRUCTION_SITE_ADD_RELATION',
    CONSTRUCTION_SITE_REMOVE_RELATION = 'CONSTRUCTION_SITE_REMOVE_RELATION',
    CONSTRUCTION_SITE_REMOVE = 'CONSTRUCTION_SITE_REMOVE',
    CONSTRUCTION_SITE_VIEW_ON_LIST = 'CONSTRUCTION_SITE_VIEW_ON_LIST',
    CONSTRUCTION_SITE_READ_HISTORY = 'CONSTRUCTION_SITE_READ_HISTORY',
    CONSTRUCTION_SITE_CHECK_BY_CUSTOMER = 'CONSTRUCTION_SITE_CHECK_BY_CUSTOMER',

    /**
     * @TRUCK_ACTION
     */
    TRUCK_CREATE = 'TRUCK_CREATE',
    TRUCK_READ = 'TRUCK_READ',
    TRUCK_READ_MANY = 'TRUCK_READ_MANY',
    TRUCK_READ_ALL = 'TRUCK_READ_ALL',
    TRUCK_UPDATE = 'TRUCK_UPDATE',
    TRUCK_ADD_RELATION = 'TRUCK_ADD_RELATION',
    TRUCK_REMOVE_RELATION = 'TRUCK_REMOVE_RELATION',
    TRUCK_REMOVE = 'TRUCK_REMOVE',
    TRUCK_VIEW_ON_LIST = 'TRUCK_VIEW_ON_LIST',
    TRUCK_READ_HISTORY = 'TRUCK_READ_HISTORY',

    /**
     * @PRODUCT_ACION
     **/
    PRODUCT_READ = 'PRODUCT_READ',
    PRODUCT_READ_ALL = 'PRODUCT_READ_ALL',
    PRODUCT_READ_MANY = 'PRODUCT_READ_MANY',
    PRODUCT_CREATE = 'PRODUCT_CREATE',
    PRODUCT_UPDATE = 'PRODUCT_UPDATE',
    PRODUCT_REMOVE = 'PRODUCT_REMOVE',
    PRODUCT_ADD_RELATION = 'PRODUCT_ADD_RELATION',
    PRODUCT_REMOVE_RELATION = 'PRODUCT_REMOVE_RELATION',
    PRODUCT_ARCHIVE = 'PRODUCT_ARCHIVE',
    PRODUCT_VIEW_ON_LIST = 'PRODUCT_VIEW_ON_LIST',
    PRODUCT_READ_HISTORY = 'PRODUCT_READ_HISTORY',
    PRODUCT_SET_VISIBILITY = 'PRODUCT_SET_VISIBILITY',
    /**
     * @ZONE_SAGA
     **/
    ZONE_READ = 'ZONE_READ',
    ZONE_READ_ALL = 'ZONE_READ_ALL',
    ZONE_READ_MANY = 'ZONE_READ_MANY',
    ZONE_CREATE = 'ZONE_CREATE',
    ZONE_UPDATE = 'ZONE_UPDATE',
    ZONE_REMOVE = 'ZONE_REMOVETE',
    ZONE_ARCHIVE = 'ZONE_ARCHIVE',
    ZONE_VIEW_ON_LIST = 'ZONE_VIEW_ON_LIST',
    ZONE_READ_HISTORY = 'ZONE_READ_HISTORY',

    // /**
    //  * @WEBSOCKET ACTION
    //  */
    // WEBSOCKET_SEND_USER_RESPONSE = 'WEBSOCKET_SEND_USER_RESPONSE',

    /**
     * @GOCARDLESS_ACTION
     */
    GOCARDLESS_BILLING_REQUEST_CREATE = 'GOCARDLESS_BILLING_REQUEST_CREATE',
    GOCARDLESS_BILLING_REQUEST_CANCEL = 'GOCARDLESS_BILLING_REQUEST_CANCEL',
    GOCARDLESS_BILLING_REQUEST_FLOW_CREATE = 'GOCARDLESS_BILLING_REQUEST_FLOW_CREATE',
    GOCARDLESS_MANDATE_READ_ALL = 'GOCARDLESS_MANDATE_READ_ALL',
    GOCARDLESS_MANDATE_READ = 'GOCARDLESS_MANDATE_READ',
    GOCARDLESS_MANDATE_UPDATE = 'GOCARDLESS_MANDATE_UPDATE',
    GOCARDLESS_CUSTOMER_READ = 'GOCARDLESS_CUSTOMER_READ',
    GOCARDLESS_CUSTOMER_UPDATE = 'GOCARDLESS_CUSTOMER_UPDATE',
    GOCARDLESS_PAYMENT_CREATE = 'GOCARDLESS_PAYMENT_CREATE',
    GOCARDLESS_PAYMENT_CANCEL = 'GOCARDLESS_PAYMENT_CANCEL',

    /**
     * @ORDER_ACTION
     **/
    ORDER_CREATE = 'ORDER_CREATE',
    ORDER_READ = 'ORDER_READ',
    ORDER_READ_MANY = 'ORDER_READ_MANY',
    ORDER_READ_ALL = 'ORDER_READ_ALL',
    ORDER_COUNT_ALL = 'ORDER_COUNT_ALL',
    ORDER_UPDATE = 'ORDER_UPDATE',
    ORDER_ADD_RELATION = 'ORDER_ADD_RELATION',
    ORDER_REMOVE_RELATION = 'ORDER_REMOVE_RELATION',
    ORDER_REMOVE = 'ORDER_REMOVE',
    ORDER_VIEW_ON_LIST = 'ORDER_VIEW_ON_LIST',
    ORDER_READ_HISTORY = 'ORDER_READ_HISTORY',

    /**
     * @COLLECT_ACTION
     **/
    COLLECT_CREATE = 'COLLECT_CREATE',
    COLLECT_READ = 'COLLECT_READ',
    COLLECT_READ_MANY = 'COLLECT_READ_MANY',
    COLLECT_READ_ALL = 'COLLECT_READ_ALL',
    COLLECT_UPDATE = 'COLLECT_UPDATE',
    COLLECT_ADD_RELATION = 'COLLECT_ADD_RELATION',
    COLLECT_REMOVE_RELATION = 'COLLECT_REMOVE_RELATION',
    COLLECT_REMOVE = 'COLLECT_REMOVE',
    COLLECT_VIEW_ON_LIST = 'COLLECT_VIEW_ON_LIST',
    COLLECT_READ_HISTORY = 'COLLECT_READ_HISTORY',
    COLLECT_READ_DUMPSTER_FINDING = 'COLLECT_READ_DUMPSTER_FINDING',
    COLLECT_READ_ALL_BY_CCS = 'COLLECT_READ_ALL_BY_CCS',
    COLLECT_READ_ALL_EMPTYING_BY_PLANNINGS = 'COLLECT_READ_ALL_EMPTYING_BY_PLANNINGS',

    /**
     * @DUMPSTER_ACTION
     **/
    DUMPSTER_CREATE = 'DUMPSTER_CREATE',
    DUMPSTER_READ = 'DUMPSTER_READ',
    DUMPSTER_READ_MANY = 'DUMPSTER_READ_MANY',
    DUMPSTER_READ_ALL = 'DUMPSTER_READ_ALL',
    DUMPSTER_UPDATE = 'DUMPSTER_UPDATE',
    DUMPSTER_ADD_RELATION = 'DUMPSTER_ADD_RELATION',
    DUMPSTER_REMOVE_RELATION = 'DUMPSTER_REMOVE_RELATION',
    DUMPSTER_REMOVE = 'DUMPSTER_REMOVE',
    DUMPSTER_VIEW_ON_LIST = 'DUMPSTER_VIEW_ON_LIST',
    DUMPSTER_READ_HISTORY = 'DUMPSTER_READ_HISTORY',

    /**
     * @DUMPSTER_ON_SITE_ACTION (in collect player)
     */
    DUMPSTER_ON_SITE_CREATE = 'DUMPSTER_ON_SITE_CREATE',
    DUMPSTER_ON_SITE_REMOVE = 'DUMPSTER_ON_SITE_REMOVE',
    DUMPSTER_ON_SITE_READ = 'DUMPSTER_ON_SITE_READ',
    DUMPSTER_ON_SITE_READ_MANY = 'DUMPSTER_ON_SITE_READ_MANY',
    DUMPSTER_ON_SITE_READ_ALL = 'DUMPSTER_ON_SITE_READ_ALL',
    DUMPSTER_ON_SITE_UPDATE = 'DUMPSTER_ON_SITE_UPDATE',
    DUMPSTER_ON_SITE_READ_HISTORY = 'DUMPSTER_ON_SITE_READ_HISTORY',

    /**
     * @ZOHO_ACTION
     **/
    ZOHO_CREATE_CUSTOMER = 'ZOHO_CREATE_CUSTOMER',
    ZOHO_CUSTOMER_READ = 'ZOHO_CUSTOMER_READ',
    ZOHO_CUSTOMER_READ_ALL = 'ZOHO_CUSTOMER_READ_ALL',
    ZOHO_CUSTOMER_UPDATE = 'ZOHO_CUSTOMER_UPDATE',
    ZOHO_CUSTOMER_REMOVE = 'ZOHO_CUSTOMER_REMOVE',
    ZOHO_INVOICE_GENERATE = 'ZOHO_INVOICE_GENERATE',
    ZOHO_INVOICE_READ = 'ZOHO_INVOICE_READ',
    ZOHO_INVOICE_DOWNLOAD = 'ZOHO_INVOICE_DOWNLOAD',
    ZOHO_INVOICE_READ_ALL = 'ZOHO_INVOICE_READ_ALL',
    ZOHO_INVOICE_UPDATE = 'ZOHO_INVOICE_UPDATE',
    ZOHO_INVOICE_REMOVE = 'ZOHO_INVOICE_REMOVE',
    ZOHO_INVOICE_EMAIL = 'ZOHO_INVOICE_EMAIL',
    ZOHO_CREATE_PAYMENT = 'ZOHO_CREATE_PAYMENT',
    ZOHO_PAYMENT_REMOVE = 'ZOHO_PAYMENT_REMOVE',

    /**
     * @EMAIL_ACTION
     **/
    EMAIL_CREATE = 'EMAIL_CREATE',
    EMAIL_READ = 'EMAIL_READ',
    EMAIL_READ_ALL = 'EMAIL_READ_ALL',
    EMAIL_SEND = 'EMAIL_SEND',
    EMAIL_SEND_BULK = 'EMAIL_SEND_BULK',
    EMAIL_VIEW_ON_LIST = 'EMAIL_VIEW_ON_LIST',

    /**
     * @DOCUMENT_ACTION
     **/
    DOCUMENT_CREATE = 'DOCUMENT_CREATE',
    DOCUMENT_READ = 'DOCUMENT_READ',
    DOCUMENT_READ_MANY = 'DOCUMENT_READ_MANY',
    DOCUMENT_READ_ALL = 'DOCUMENT_READ_ALL',
    DOCUMENT_ADD_RELATION = 'DOCUMENT_ADD_RELATION',
    DOCUMENT_REMOVE_RELATION = 'DOCUMENT_REMOVE_RELATION',
    DOCUMENT_REMOVE = 'DOCUMENT_REMOVE',
    DOCUMENT_VIEW_ON_LIST = 'DOCUMENT_VIEW_ON_LIST',
    DOCUMENT_UPLOAD_S3 = 'DOCUMENT_UPLOAD_S3',
    DOCUMENT_REMOVE_S3 = 'DOCUMENT_REMOVE_S3',
    DOCUMENT_DOWNLOAD_S3 = 'DOCUMENT_DOWNLOAD_S3',

    /**
     * @VRP_ALGORITHM_ACTION
     **/
    VRP_ALGORITHM_CALCULATE = 'VRP_ALGORITHM_CALCULATE',
    VRP_ALGORITHM_READ = 'VRP_ALGORITHM_READ',
    VRP_ALGORITHM_UPDATE = 'VRP_ALGORITHM_UPDATE',
    VRP_ALGORITHM_REMOVE = 'VRP_ALGORITHM_REMOVE',
    VRP_ALGORITHM_VIEW_ON_LIST = 'VRP_ALGORITHM_VIEW_ON_LIST',
    VRP_ALGORITHM_READ_HISTORY = 'VRP_ALGORITHM_READ_HISTORY',

    VRP_ALGORITHM_CONSTANT_READ = 'VRP_ALGORITHM_CONSTANT_READ',
    VRP_ALGORITHM_CONSTANT_READ_ALL = 'VRP_ALGORITHM_CONSTANT_READ_ALL',
    VRP_ALGORITHM_CONSTANT_UPDATE = 'VRP_ALGORITHM_CONSTANT_UPDATE',
    VRP_ALGORITHM_CONSTANT_READ_BY_TYPE = 'VRP_ALGORITHM_CONSTANT_READ_BY_TYPE',
    VRP_ALGORITHM_GET_PRICE = 'VRP_ALGORITHM_GET_PRICE',

    /**
     * @COLLECT_CONFIG_ACTION
     **/
    COLLECT_CONFIG_CREATE = 'COLLECT_CONFIG_CREATE',
    COLLECT_CONFIG_READ = 'COLLECT_CONFIG_READ',
    COLLECT_CONFIG_READ_MANY = 'COLLECT_CONFIG_READ_MANY',
    COLLECT_CONFIG_READ_ALL = 'COLLECT_CONFIG_READ_ALL',
    COLLECT_CONFIG_READ_ALL_BY_USER = 'COLLECT_CONFIG_READ_ALL_BY_USER',
    COLLECT_CONFIG_UPDATE = 'COLLECT_CONFIG_UPDATE',
    COLLECT_CONFIG_ADD_RELATION = 'COLLECT_CONFIG_ADD_RELATION',
    COLLECT_CONFIG_REMOVE_RELATION = 'COLLECT_CONFIG_REMOVE_RELATION',
    COLLECT_CONFIG_REMOVE = 'COLLECT_CONFIG_REMOVE',
    COLLECT_CONFIG_VIEW_ON_LIST = 'COLLECT_CONFIG_VIEW_ON_LIST',
    COLLECT_CONFIG_READ_HISTORY = 'COLLECT_CONFIG_READ_HISTORY',
    COLLECT_CONFIG_REPLAN_ACTIVITY_INSERT = 'COLLECT_CONFIG_REPLAN_ACTIVITY_INSERT', // insert to avoid reveal
    COLLECT_CONFIG_REPLAN_ACTIVITY_REMOVE = 'COLLECT_CONFIG_REPLAN_ACTIVITY_REMOVE',
    COLLECT_CONFIG_REPLAN_ACTIVITY_READ_ALL = 'COLLECT_CONFIG_REPLAN_ACTIVITY_READ_ALL',

    CC_ADMINISTRATIVE_CREATE = 'CC_ADMINISTRATIVE_CREATE',
    CC_ADMINISTRATIVE_READ = 'CC_ADMINISTRATIVE_READ',
    CC_ADMINISTRATIVE_READ_MANY = 'CC_ADMINISTRATIVE_READ_MANY',
    CC_ADMINISTRATIVE_READ_ALL = 'CC_ADMINISTRATIVE_READ_ALL',
    CC_ADMINISTRATIVE_UPDATE = 'CC_ADMINISTRATIVE_UPDATE',
    CC_ADMINISTRATIVE_ADD_RELATION = 'CC_ADMINISTRATIVE_ADD_RELATION',
    CC_ADMINISTRATIVE_REMOVE_RELATION = 'CC_ADMINISTRATIVE_REMOVE_RELATION',
    CC_ADMINISTRATIVE_REMOVE = 'CC_ADMINISTRATIVE_REMOVE',
    CC_ADMINISTRATIVE_VIEW_ON_LIST = 'CC_ADMINISTRATIVE_VIEW_ON_LIST',
    CC_ADMINISTRATIVE_READ_HISTORY = 'CC_ADMINISTRATIVE_READ_HISTORY',

    CC_PUBLIC_INFORMATIONS_UPDATE = 'CC_PUBLIC_INFORMATIONS_UPDATE',
    CC_PUBLIC_INFORMATIONS_CREATE = 'CC_PUBLIC_INFORMATIONS_CREATE',
    CC_PUBLIC_INFORMATIONS_REMOVE = 'CC_PUBLIC_INFORMATIONS_REMOVE',
    CC_PUBLIC_INFORMATIONS_READ = 'CC_PUBLIC_INFORMATIONS_READ',
    CC_PUBLIC_INFORMATIONS_READ_ALL = 'CC_PUBLIC_INFORMATIONS_READ_ALL',
    CC_PUBLIC_INFORMATIONS_VIEW_ON_LIST = 'CC_PUBLIC_INFORMATIONS_VIEW_ON_LIST',

    /**
     * @PLANNING_ACTION
     **/
    PLANNING_CREATE = 'PLANNING_CREATE',
    PLANNING_READ = 'PLANNING_READ',
    PLANNING_READ_MANY = 'PLANNING_READ_MANY',
    PLANNING_READ_ALL = 'PLANNING_READ_ALL',
    PLANNING_UPDATE = 'PLANNING_UPDATE',
    PLANNING_ADD_RELATION = 'PLANNING_ADD_RELATION',
    PLANNING_REMOVE_RELATION = 'PLANNING_REMOVE_RELATION',
    PLANNING_REMOVE = 'PLANNING_REMOVE',
    PLANNING_VIEW_ON_LIST = 'PLANNING_VIEW_ON_LIST',
    PLANNING_READ_HISTORY = 'PLANNING_READ_HISTORY',
    PLANNING_GET_MOST_RECENT_FROM_PAST = 'PLANNING_GET_MOST_RECENT_FROM_PAST',

    /**
     * @INVOICE_ACTION
     **/
    INVOICE_CREATE = 'INVOICE_CREATE',
    INVOICE_READ = 'INVOICE_READ',
    INVOICE_READ_MANY = 'INVOICE_READ_MANY',
    INVOICE_READ_ALL = 'INVOICE_READ_ALL',
    INVOICE_UPDATE = 'INVOICE_UPDATE',
    INVOICE_ADD_RELATION = 'INVOICE_ADD_RELATION',
    INVOICE_REMOVE_RELATION = 'INVOICE_REMOVE_RELATION',
    INVOICE_REMOVE = 'INVOICE_REMOVE',
    INVOICE_VIEW_ON_LIST = 'INVOICE_VIEW_ON_LIST',

    /**
     * @STRIPE_ACTION
     **/
    STRIPE_CREATE_CHECKOUT_SESSION = 'STRIPE_CREATE_CHECKOUT_SESSION',
    STRIPE_EXPIRE_CHECKOUT_SESSION = 'STRIPE_EXPIRE_CHECKOUT_SESSION',
    STRIPE_READ_CHECKOUT_SESSION = 'STRIPE_READ_CHECKOUT_SESSION',
    STRIPE_CREATE_CUSTOMER = 'STRIPE_CREATE_CUSTOMER',
    STRIPE_UPDATE_CUSTOMER = 'STRIPE_UPDATE_CUSTOMER',
    STRIPE_READ_CUSTOMER = 'STRIPE_READ_CUSTOMER',
    STRIPE_REMOVE_CUSTOMER = 'STRIPE_REMOVE_CUSTOMER',
    STRIPE_CREATE_COUPON = 'STRIPE_CREATE_COUPON',
    STRIPE_REMOVE_COUPON = 'STRIPE_REMOVE_COUPON',
    // STRIPE_CREATE_INVOICE = 'STRIPE_CREATE_INVOICE',
    // STRIPE_REMOVE_INVOICE = 'STRIPE_REMOVE_INVOICE',
    // STRIPE_CREATE_INVOICE_ITEM = 'STRIPE_CREATE_INVOICE_ITEM',
    // STRIPE_REMOVE_INVOICE_ITEM = 'STRIPE_REMOVE_INVOICE_ITEM',
    // STRIPE_SEND_INVOICE = 'STRIPE_SEND_INVOICE'

    /**
     * @HERE_ACTION
     */
    HERE_CALCULATE_DATA_MATRIX = 'HERE_CALCULATE_DATA_MATRIX',
    HERE_CALCULATE_PLANNING = 'HERE_CALCULATE_PLANNING',
    HERE_CALCULATE_ESTIMATED_TIME_ARRIVAL = 'HERE_CALCULATE_ESTIMATED_TIME_ARRIVAL',

    /**
     * @GOOGLE_DRIVE_ACTION
     **/
    GOOGLE_DRIVE_CREATE = 'GOOGLE_DRIVE_CREATE',
    GOOGLE_DRIVE_REMOVE = 'GOOGLE_DRIVE_REMOVE',
    GOOGLE_DRIVE_UPDATE = 'GOOGLE_DRIVE_UPDATE',
    GOOGLE_DRIVE_READ = 'GOOGLE_DRIVE_READ',
    GOOGLE_DRIVE_READ_ALL = 'GOOGLE_DRIVE_READ_ALL',
    GOOGLE_DRIVE_VIEW_ON_LIST = 'GOOGLE_DRIVE_VIEW_ON_LIST',
    GOOGLE_DRIVE_CREATE_FILE_OR_FOLDER = 'GOOGLE_DRIVE_CREATE_FILE_OR_FOLDER',
    GOOGLE_DRIVE_REMOVE_FILE_OR_FOLDER = 'GOOGLE_DRIVE_REMOVE_FILE_OR_FOLDER',
    GOOGLE_DRIVE_CREATE_PERM = 'GOOGLE_DRIVE_CREATE_PERM',
    GOOGLE_DRIVE_READ_ALL_FILE_OR_FOLDER = 'GOOGLE_DRIVE_READ_ALL_FILE_OR_FOLDER',

    /**
     * @AUTHENTICATION_ACTION
     */
    SESSION_CREATE = 'SESSION_CREATE',
    SESSION_UPDATE = 'SESSION_UPDATE',
    SESSION_REMOVE = 'SESSION_REMOVE',
    SESSION_READ = 'SESSION_READ',
    VERIFICATION_TOKEN_CREATE = 'VERIFICATION_TOKEN_CREATE',
    VERIFICATION_TOKEN_USE = 'VERIFICATION_TOKEN_USE',

    /**
     * @PERMISSION_ACTION
     */
    PERMISSION_READ_BY_ROLE = 'PERMISSION_READ_BY_ROLE',

    /**
     * @SMS_ACTION
     */

    SMS_SEND = 'SMS_SEND',
    SMS_GET_ALL = 'SMS_GET_ALL'
}
