import React from 'react';
import styled from 'styled-components';

import { CardErrors } from '@bbng/util/types';

import { generateInitialErrorState } from '../../common/form';
import Button from '../../components/Button';
import { Card } from '../../components/Card';
import Input from '../../components/Inputs';
import { useFormModule } from '../../hooks/FormModule';

export type OrderMiscProps = {
    readOnly?: boolean;
    value?: OrderMiscState;
    id: string;
    result: (value: OrderMiscState, errors: null | string[] | CardErrors<OrderMiscState>, id: string) => void;
    displayError?: boolean;
};

export type OrderMiscState = {
    comment: string;
    prepaid: boolean;
};
export type OrderMiscErrorState = CardErrors<OrderMiscState>;

export const initialState: OrderMiscState = {
    comment : '',
    prepaid : false
};
export const initialErrorState: OrderMiscErrorState = generateInitialErrorState(initialState);

export const OrderMisc: React.FC<OrderMiscProps> = ({
    readOnly = false,
    value = initialState,
    id,
    result,
    displayError
}: OrderMiscProps) => {
    const { val, setVal, err, setErr, handleChange } = useFormModule({
        id,
        initialValue : value,
        initialError : initialErrorState,
        result
    });

    return (
        <StyledCard title="Informations diverses">
            <FullLineInput>
                <Input.Textarea
                    label="Commentaires"
                    required={false}
                    id="comment"
                    readOnly={readOnly}
                    result={handleChange}
                    value={val.comment}
                    errors={err.comment}
                    displayError={displayError}
                    width="100%"
                />
            </FullLineInput>
            <Button.Checkbox
                id="prepaid"
                readOnly={readOnly}
                result={handleChange}
                value={val.prepaid}
                label="Commande prépayée"
                labelPosition="right"
            />
        </StyledCard>
    );
};

const FullLineInput = styled.div`
    width: 100%;
`;

const StyledCard = styled(Card)``;
