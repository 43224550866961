import moment from 'moment';
import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useState } from 'react';

import { formatE164ToInternational, getVolumeFromProducts } from '@bbng/util/misc';
import {
    AddressRo,
    CCAdministrativeRoFront,
    CCServiceRoFront,
    CC_FAMILY,
    CC_STATUS,
    CollectorRo,
    CustomerRo,
    DumpsterToTake,
    ECollectCharacteristic,
    ECollectHazardReason,
    ISODate,
    PlanningShiftStepCategory,
    ProductInCCOrCO,
    isCCServiceFront,
    PlanningShiftStep,
    PlanningShiftStepService,
    PlanningShiftStepEmptying,
    CCRo,
    LandfillRo,
    CCServiceRo,
    IContact
} from '@bbng/util/types';

import {
    mapCollectCategoryText,
    mapCollectConfigCharacteristic,
    mapCollectConfigFamilyText,
    mapCollectConfigStatus,
    mapCollectHazardReason,
    mapDumpsterToTake,
    mapTrashTypetext
} from '../../common/enumMapper';
import { urlApiBuilder } from '../../common/urlBuilder';
import { useRequest } from '../../hooks/StatelessRequest';
import { AddressOverview } from '../AddressOverview';
import {
    CollectDetailsTitle,
    DateContainer,
    HorizontalCenteredContainer,
    HorizontalContainer,
    NarrowVerticalContainer,
    OverviewContainer,
    Status,
    StyledDivider,
    StyledTitle,
    Time,
    TimeContainer,
    VerticalContainer
} from './style';
import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';
import { Divider } from 'primereact/divider';

export interface StepOverviewProps {
    collectorId?: string;
    customerId?: string;
    planned_start_date?: string;
    planned_end_date?: string;
    planned_duration?: string; // "HH:mm"
    planned_products?: ProductInCCOrCO[];
    actual_start_date?: string;
    actual_end_date?: string;
    actual_duration?: string; // "HH:mm"
    actual_products?: ProductInCCOrCO[];
    family?: CC_FAMILY;
    status?: CC_STATUS;
    address: AddressRo;
    title?: string;
    comment?: string;
    characteristics?: ECollectCharacteristic[];
    isSplitted?: boolean;
    hazardReason?: ECollectHazardReason;
    hazardComment?: string;
    number?: string;
    order_number?: string;
    dumpster_to_take?: DumpsterToTake;
    slot?: {
        start: ISODate;
        end: ISODate;
    };
    cons_site_contacts?: IContact[];
    replanCount?: number;
}

export const StepOverview: React.FC<StepOverviewProps> = ({
    collectorId,
    customerId,
    family,
    planned_start_date,
    planned_end_date,
    planned_duration,
    planned_products = [],
    actual_start_date,
    actual_end_date,
    actual_products = [],
    status,
    address,
    title,
    comment,
    characteristics,
    isSplitted,
    hazardReason,
    hazardComment,
    number,
    order_number,
    dumpster_to_take,
    slot = undefined,
    cons_site_contacts,
    replanCount
}: StepOverviewProps): JSX.Element => {
    const bbngRequest = useRequest();
    const { collectors, customers } = usePlanningV2Store();
    const [collector, setCollector] = useState<CollectorRo | undefined>();
    const [collectorLoading, setCollectorLoading] = useState<boolean>(false);

    const [customer, setCustomer] = useState<CustomerRo | undefined>();
    const [customerLoading, setCustomerLoading] = useState<boolean>(false);

    const diff = moment(actual_end_date).diff(moment(actual_start_date), 'minutes');
    const diffHours = Math.floor(diff / 60);
    const diffMinutes = diff % 60;
    const actual_duration = `${diffHours < 10 ? '0' : ''}${diffHours}:${diffMinutes < 10 ? '0' : ''}${
        diffMinutes % 60
    }`;
    const fetchCollector = async (collectorId: string): Promise<void> => {
        setCollectorLoading(true);
        const response = await bbngRequest<CollectorRo>({
            method : 'GET',
            url    : urlApiBuilder.collectorGet(collectorId)
        });

        if (response.success && response.response?.data?.ro) {
            setCollector(response.response.data.ro);
        }
        setCollectorLoading(false);
    };

    useEffect(() => {
        if (collectorId) {
            const collector = collectors[collectorId];
            /**
             * As manual steps can be created with collectors that are not in the store (not in the current planning),
             * it can happen that the collector is not in the store. In this case, we fetch it from the API.
             */
            if (!collector) {
                fetchCollector(collectorId);
            } else {
                setCollector(collector);
            }
        }
    }, [collectorId]);

    const isStepCustomerRelated = React.useMemo(
        () => family !== CC_FAMILY.ADMINISTRATIVE && customerId,
        [family, customerId]
    );
    const fetchCustomer = async (customerId: string): Promise<void> => {
        setCustomerLoading(true);
        const response = await bbngRequest<CustomerRo>({
            method : 'GET',
            url    : urlApiBuilder.customerGet(customerId)
        });

        if (response.success && response.response?.data?.ro) {
            setCustomer(response.response.data.ro);
        }
        setCustomerLoading(false);
    };

    useEffect(() => {
        if (isStepCustomerRelated && family !== CC_FAMILY.ADMINISTRATIVE && customerLoading === false && customerId) {
            const customer = customers[customerId];
            if (!customer) {
                fetchCustomer(customerId);
            } else {
                setCustomer(customer);
            }
        }
    }, [customerId, customerLoading, family]);

    return (
        <OverviewContainer>
            {number && (
                <>
                    <VerticalContainer>
                        <div>
                            Collecte {number} {order_number && `- Commande ${order_number}`}
                        </div>
                        {isStepCustomerRelated && (
                            <>{customerLoading ? <Skeleton width="100px" /> : <span>{customer?.name}</span>}</>
                        )}
                    </VerticalContainer>
                    <StyledDivider />
                </>
            )}
            <VerticalContainer>
                <DateContainer>Le {moment(planned_start_date).format('dddd DD MMMM YYYY')}</DateContainer>
                <NarrowVerticalContainer>
                    <Status>{mapCollectConfigStatus(status)}</Status>
                    <span>(sur la version sauvegardée)</span>
                </NarrowVerticalContainer>
                {slot && slot.start && slot.end && (
                    <DateContainer>{`Créneau demandé: ${moment(slot.start).format('HH:mm')} - ${moment(slot.end).format(
                        'HH:mm'
                    )}`}</DateContainer>
                )}
                {replanCount && replanCount > 0 && (
                    <DateContainer>
                        <i style={{ fontSize: '12px' }} className="pi pi-refresh" />
                        Replanifiée {replanCount} fois
                    </DateContainer>
                )}
            </VerticalContainer>
            <StyledDivider />
            <CollectDetailsTitle>Créneau et produits prévus</CollectDetailsTitle>
            <CollectDetails
                start={planned_start_date as string}
                end={planned_end_date as string}
                products={planned_products}
                duration={planned_duration}
                dumpster_to_take={dumpster_to_take}
            />
            {actual_start_date && actual_end_date && (
                <>
                    <StyledDivider />
                    <CollectDetailsTitle>Créneau et produits effectués</CollectDetailsTitle>
                    <CollectDetails
                        start={actual_start_date as string}
                        end={actual_end_date as string}
                        products={actual_products}
                        duration={actual_duration}
                    />
                </>
            )}
            <StyledDivider />
            <HorizontalCenteredContainer>
                <VerticalContainer>
                    <div>Chauffeur associé:</div>
                    {collectorLoading ? (
                        <Skeleton width="100px" />
                    ) : collector ? (
                        `${collector?.firstname} ${collector?.lastname}`
                    ) : (
                        'Aucun'
                    )}
                </VerticalContainer>
                {hazardReason && (
                    <>
                        <StyledDivider layout="vertical" />
                        <VerticalContainer>
                            <span>{mapCollectHazardReason(hazardReason)}</span>
                            <span>
                                {hazardComment && hazardComment.trim() !== ''
                                    ? hazardComment
                                    : 'Aucun commentaire aléa'}
                            </span>
                        </VerticalContainer>
                    </>
                )}
            </HorizontalCenteredContainer>
            <StyledDivider />
            <HorizontalContainer>
                <VerticalContainer>
                    {address && Object.keys(address).length > 0 && <AddressOverview address={address} showButton />}
                    <span>
                        {characteristics
                            ?.map((char) => mapCollectConfigCharacteristic(char as ECollectCharacteristic))
                            .join(' - ')}
                    </span>
                </VerticalContainer>
                <StyledDivider layout="vertical" />
                <VerticalContainer>
                    {title && <StyledTitle>{title}</StyledTitle>}
                    <span>{comment && comment.trim() !== '' ? comment : 'Aucun commentaire collecte'}</span>
                    {isSplitted && <span>Collecte scindée</span>}
                </VerticalContainer>
            </HorizontalContainer>
            {cons_site_contacts && (
                <VerticalContainer>
                    <h3>Contacts chantier:</h3>
                    {cons_site_contacts.length > 0 ? (
                        <VerticalContainer>
                            {cons_site_contacts.map((contact) => (
                                <span key={contact.phone_number}>
                                    {contact.firstname} {contact.firstname}{' '}
                                    {formatE164ToInternational(contact.phone_number)}
                                </span>
                            ))}
                        </VerticalContainer>
                    ) : (
                        <span>Aucun contact</span>
                    )}
                </VerticalContainer>
            )}
        </OverviewContainer>
    );
};

export const collectDescription = (
    step: PlanningShiftStep,
    cc: CCRo,
    customer?: CustomerRo,
    landfill?: LandfillRo
): string => {
    const info = [];

    const isService = (step: PlanningShiftStep): step is PlanningShiftStepService =>
        step.category === PlanningShiftStepCategory.SERVICE;
    const isEmptying = (step: PlanningShiftStep): step is PlanningShiftStepEmptying =>
        step.category === PlanningShiftStepCategory.EMPTYING;

    const stepService = isService(step) ? step : undefined;
    const stepEmptying = isEmptying(step) ? step : undefined;

    const family = [PlanningShiftStepCategory.SERVICE, PlanningShiftStepCategory.ADMINISTRATIVE].includes(
        stepService?.category as PlanningShiftStepCategory
    )
        ? mapCollectConfigFamilyText(cc.family, true)
        : undefined;
    family && info.push(family);
    const products = isService(step) ? (cc as CCServiceRo).products : [];
    const totalVolume =
        products.length > 0 ? `${getVolumeFromProducts(products, cc?.family as CC_FAMILY)}m³` : undefined;
    totalVolume && info.push(totalVolume);
    const category =
        step.category !== PlanningShiftStepCategory.SERVICE && mapCollectCategoryText(step.category, false);
    category && info.push(category);
    const customerName = customer?.name;
    customerName && info.push(customerName);
    const landfillName = landfill?.name;
    landfillName && info.push(landfillName);
    return info.join(' - ');
};

export const collectConfigDescription = (collect_config?: CCServiceRoFront | CCAdministrativeRoFront): string => {
    const ccService = collect_config && isCCServiceFront(collect_config) ? collect_config : undefined;
    const info = [];
    const family = collect_config?.family ? mapCollectConfigFamilyText(collect_config.family, true) : undefined;
    family && info.push(family);
    const totalVolume =
        (ccService?.products || [])?.length > 0
            ? `${getVolumeFromProducts(ccService?.products || [], collect_config?.family as CC_FAMILY)}m³`
            : undefined;
    totalVolume && info.push(totalVolume);
    const customerName =
        (ccService?.customer_id?.length && (ccService?.customer_id[0] as CustomerRo)?.name) || undefined;
    customerName && info.push(customerName);
    ccService?.number && info.push(`${ccService?.number}`);
    ccService?.order_number && info.push(`${ccService?.order_number}`);
    return info.join(' - ');
};

type CollectDetailsProps = {
    start: string;
    end: string;
    duration?: string; // "HH:mm"
    products: ProductInCCOrCO[];
    dumpster_to_take?: DumpsterToTake;
};
const CollectDetails: React.FC<CollectDetailsProps> = ({
    start,
    end,
    duration,
    products,
    dumpster_to_take
}): JSX.Element => {
    const nonNullDumpsters = dumpster_to_take ? Object.entries(dumpster_to_take).filter(([, value]) => value > 0) : [];
    return (
        <>
            <HorizontalContainer>
                <TimeContainer>
                    <VerticalContainer>
                        <Time>ETA: {moment(start).format('HH:mm')}</Time>
                        <Time>ETD: {moment(end).format('HH:mm')}</Time>
                        {duration && <span>Durée: {duration}</span>}
                    </VerticalContainer>
                </TimeContainer>
                {products.length > 0 && (
                    <>
                        <StyledDivider layout="vertical" />
                        <VerticalContainer>
                            {products.map((product) => {
                                const desc = product.operational
                                    ? product.name
                                    : `${mapCollectConfigFamilyText(product.family, true)} - ${mapTrashTypetext(
                                          product.trash_type
                                      )} - ${product.volume_m3}m³`;
                                return (
                                    <span key={product.id}>
                                        {product.quantity} x {desc}
                                    </span>
                                );
                            })}
                        </VerticalContainer>
                    </>
                )}
            </HorizontalContainer>
            {dumpster_to_take && (
                <VerticalContainer>
                    {nonNullDumpsters.length > 0 ? (
                        <>
                            {nonNullDumpsters.map(([volume, quantity]) => (
                                <span key={volume}>
                                    {mapDumpsterToTake(volume as keyof DumpsterToTake)} x {quantity}
                                </span>
                            ))}
                        </>
                    ) : (
                        <span>Aucune benne à récupérer.</span>
                    )}
                </VerticalContainer>
            )}
        </>
    );
};
