import { Divider } from 'primereact/divider';

import { EPlanningType } from '@bbng/util/types';

import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';
import { getPlanningMetrics } from './helpers';
import { OverviewHeader, OverviewLabel, OverviewLineWrapper, OverviewValue } from './style';

export const PlanningStats: React.FC = (): JSX.Element => {
    const {
        ccsService,
        ccsAdministrative,
        planningPageState,
        unnassignedCCService,
        unnassignedCCAdministrative,
        plannings
    } = usePlanningV2Store();

    const ccs = [
        ...Object.entries(ccsService).map(([key, value]) => ({ ...value, key })),
        ...Object.entries(ccsAdministrative).map(([key, value]) => ({ ...value, key }))
    ];
    const unassigned_ccs = [...unnassignedCCService, ...unnassignedCCAdministrative];

    const metrics = getPlanningMetrics([...Object.values(plannings)], ccs, planningPageState, unassigned_ccs);

    return (
        <div style={{ minWidth: '1000px' }}>
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Divider align="center">
                        <span className="p-tag">Statistiques globales</span>
                    </Divider>
                    <OverviewLineWrapper>
                        <OverviewHeader>DESCRIPTION</OverviewHeader>
                        <OverviewHeader>NOMBRES</OverviewHeader>
                        <OverviewLine label="Collectes: " value={metrics.nb_cc_service} />
                        <OverviewLine label="Collectes scindées: " value={metrics.nb_cc_service_splitted} />
                        <OverviewLine label="Collectes administratives: " value={metrics.nb_cc_administrative} />
                        <Divider style={{ marginTop: '-10px' }} />
                        <OverviewLine label="Collectes total: " value={metrics.nb_cc_total} />
                        <OverviewLabel />
                    </OverviewLineWrapper>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Divider align="center">
                        <span className="p-tag">Statistiques par statut</span>
                    </Divider>
                    <OverviewLineWrapper>
                        <OverviewHeader>DESCRIPTION</OverviewHeader>
                        <OverviewHeader>NOMBRES</OverviewHeader>
                        {planningPageState.type !== EPlanningType.DUMPSTER && <OverviewHeader>VOLUMES</OverviewHeader>}
                        <OverviewLine
                            label="A planifier: "
                            value={metrics.nb_cc_service_to_plan}
                            volume={
                                planningPageState.type === EPlanningType.BIG_BAG
                                    ? metrics.volume_cc_service_to_plan
                                    : undefined
                            }
                            unit={planningPageState.type === EPlanningType.BIG_BAG ? 'm3' : undefined}
                        />
                        <OverviewLine
                            label="Planifiées: "
                            value={metrics.nb_cc_service_planned}
                            volume={
                                planningPageState.type === EPlanningType.BIG_BAG
                                    ? metrics.volume_cc_service_planned
                                    : undefined
                            }
                            unit={planningPageState.type === EPlanningType.BIG_BAG ? 'm3' : undefined}
                        />
                        <OverviewLine
                            label="Terminées: "
                            value={metrics.nb_cc_service_finished}
                            volume={
                                planningPageState.type === EPlanningType.BIG_BAG
                                    ? metrics.volume_cc_service_finished
                                    : undefined
                            }
                            unit={planningPageState.type === EPlanningType.BIG_BAG ? 'm3' : undefined}
                        />
                        <OverviewLine
                            label="En aléas: "
                            value={metrics.nb_cc_service_hazard}
                            volume={
                                planningPageState.type === EPlanningType.BIG_BAG
                                    ? metrics.volume_cc_service_hazard
                                    : undefined
                            }
                            unit={planningPageState.type === EPlanningType.BIG_BAG ? 'm3' : undefined}
                        />
                        <OverviewLine
                            label="A payer: "
                            value={metrics.nb_cc_service_to_pay}
                            volume={
                                planningPageState.type === EPlanningType.BIG_BAG
                                    ? metrics.volume_cc_service_to_pay
                                    : undefined
                            }
                            unit={planningPageState.type === EPlanningType.BIG_BAG ? 'm3' : undefined}
                        />
                        <Divider style={{ marginTop: '-10px' }} />
                        <OverviewLine
                            label="Collectes total: "
                            value={metrics.nb_cc_total}
                            volume={
                                planningPageState.type === EPlanningType.BIG_BAG ? metrics.volume_cc_service : undefined
                            }
                            unit={planningPageState.type === EPlanningType.BIG_BAG ? 'm3' : undefined}
                        />
                    </OverviewLineWrapper>
                </div>
            </div>
            {planningPageState.type === EPlanningType.DUMPSTER && (
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <Divider align="center">
                            <span className="p-tag">Nombre de bennes par type de prestations</span>
                        </Divider>
                        <OverviewLineWrapper>
                            <OverviewHeader>DESCRIPTION</OverviewHeader>
                            <OverviewHeader>NOMBRES</OverviewHeader>
                            <OverviewLine label="Dépot: " value={metrics.nb_dumpster_deposit} />
                            <OverviewLine label="Enlèvement: " value={metrics.nb_dumpster_retrieval} />
                            <OverviewLine label="Rotation: " value={metrics.nb_dumpster_rotation} />
                            <OverviewLine label="Attente de chargement: " value={metrics.nb_dumpster_loadwait} />
                            <Divider style={{ marginTop: '-10px' }} />
                            <OverviewLine label="Total: " value={metrics.nb_dumpster_total} />
                        </OverviewLineWrapper>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <Divider align="center">
                            <span className="p-tag">Répartition par bennes</span>
                        </Divider>
                        <OverviewLineWrapper>
                            <OverviewHeader>VOLUMES</OverviewHeader>
                            <OverviewHeader>NOMBRES</OverviewHeader>
                            {Object.entries(metrics.nb_dumpster_volume).map(([key, value]) => (
                                <OverviewLine label={key + ' m3'} value={value} />
                            ))}
                        </OverviewLineWrapper>
                    </div>
                </div>
            )}
            <Divider align="center">
                <span className="p-tag">Nombre d'administratives par statut</span>
            </Divider>
            <OverviewLineWrapper>
                <OverviewHeader>DESCRIPTION</OverviewHeader>
                <OverviewHeader>NOMBRES</OverviewHeader>
                <OverviewLine label="A planifier: " value={metrics.nb_cc_administrative_to_plan} />
                <OverviewLine label="Planifiées: " value={metrics.nb_cc_administrative_planned} />
                <OverviewLine label="Terminées: " value={metrics.nb_cc_administrative_hazard} />
                <OverviewLine label="En aléas: " value={metrics.nb_cc_administrative_finished} />
            </OverviewLineWrapper>
        </div>
    );
};

const OverviewLine = ({
    label,
    value,
    volume,
    unit
}: {
    label: string;
    value: number;
    unit?: string;
    volume?: number;
}) => {
    return (
        <tr>
            <OverviewLabel>{label}</OverviewLabel>
            <OverviewValue> {value}</OverviewValue>
            <OverviewValue>
                {volume} {unit ? unit : ''}
            </OverviewValue>
        </tr>
    );
};
