import equal from 'fast-deep-equal/react';
import { useFormik } from 'formik';
import produce, { setAutoFreeze } from 'immer';
import { Button } from 'primereact/button';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { useEffect, useState } from 'react';
import React from 'react';
import moment from 'moment';

import { deepCheckEmptyObject } from '@bbng/util/misc';
import {
    EPlanningCalculMethod,
    PlanningCalculateDto,
    PlanningRoFront,
    PLANNING_CALCULATE_KEY_PUBLISHED,
    CCRo,
    PlanningRo
} from '@bbng/util/types';

import { urlApiBuilder } from '../../common/urlBuilder';
import { Dialog } from '../../components/Dialog';
import { PageLoader } from '../../components/PageLoader';
import SnackBar from '../../components/SnackBar';
import { toast } from '../../components/Toast';
import { useRequest } from '../../hooks/StatelessRequest';
import PlanningModules from '../../modules/planning';
import { PlanningPageState } from '../../pages/Planning/helpers';
import { getCollectorDuplicates } from '../planning/shifts/helpers';
import { PlanningHelpModal } from './PlanningHelpModal';
import { PlanningPreSubmitModal } from './PlanningPreSubmitModal';
import { PlanningClusterConfiguration } from './cluster-configuration';
import {
    EPlanningFormSubmitAction,
    PlanningFormErrorState,
    PlanningFormState,
    PlanningModulesErrorStates,
    PlanningModulesStates,
    calculatedAndNotSavedDiverge,
    initialErrorState,
    mapApiDataToState,
    mapPlanningFormSubmitAction,
    mapStateToCalculateDtoWithoutCluster,
    mapStateToSaveDto,
    mapStateToUnassignDto
} from './helpers';
import { GetPlanningInstanceButton } from './instance-button';
import {
    Header,
    HorizontalContainer,
    PlanningLeftHandInputsContainer,
    PlanningRightHandInputsContainer,
    ZoomContainer
} from './style';
import { GlobalConfigButton } from './global-config-board';
import { usePlanningV2Store } from '../../hooks/PlanningStoreV2';
import { ExportPlanningButton } from './export-button';
import { FiltersContainer, PlanningFilters } from './filters';
import { PlanningFilterClassNames } from './filters/helpers';

setAutoFreeze(false);

export type PlanningFormProps = {
    readOnly: boolean;
    context: PlanningPageState;
};

export const PlanningForm: React.FC<PlanningFormProps> = ({ readOnly, context }: PlanningFormProps): JSX.Element => {
    const [zoom, setZoom] = useState(4);
    const {
        plannings,
        setPlanningPageState,
        admins,
        trucksByPlanning,
        collectorsByPlanning,
        ccsService,
        ccsAdministrative,
        unnassignedCCAdministrative,
        unnassignedCCService,
        setCalculatedPlanningsState,
        calculatedPlanningsState,
        setLastAction,
        planningPageState,
        getPlannings,
        getCCService,
        getCCAdministrative,
        fetchAll,
        calculationTriggered,
        lastAction,
        calculatePlannings
    } = usePlanningV2Store();

    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [helpDialogIsOpen, setHelpDialogIsOpen] = useState<boolean>(false);
    const [preSubmitDialogIsOpen, setPreSubmitDialogIsOpen] = useState<boolean>(false);
    const [clusterConfigDialogIsOpen, setClusterConfigDialogIsOpen] = useState<boolean>(false);
    const [submitAction, setSubmitAction] = useState<EPlanningFormSubmitAction>(EPlanningFormSubmitAction.CALCULATE);
    const [selectedPlannings, setSelectedPlannings] = useState<PlanningFormState>();
    const [err, setErr] = useState<PlanningFormErrorState>(
        initialErrorState(Object.values(plannings), trucksByPlanning)
    );
    const [filterClassNames, setFilterClassNames] = useState<PlanningFilterClassNames>({
        customer : [],
        general  : [],
        family   : [],
        volume   : []
    });
    const [selectedZoneIds, setSelectedZoneIds] = useState<string[]>([]);

    const handleCalculate = React.useCallback(
        async (dto: PlanningCalculateDto) => {
            setLoading(true);
            await calculatePlannings(bbngRequest, dto);
            setLoading(false);
        },
        [err, plannings, bbngRequest]
    );

    const handlePreSubmit = React.useCallback(async () => {
        if (deepCheckEmptyObject(err) === false) {
            console.log('🧐 ~ SUBMIT FAILED', err);
            toast({
                severity : 'error',
                summary  : 'Formulaire incomplet',
                detail   : 'Le formulaire contient des erreurs. Veuillez les corriger avant de lancer un calcul.'
            });
            return;
        }
        const collectorDuplicates = getCollectorDuplicates(formik.values.shifts);
        if (collectorDuplicates.length > 0) {
            toast({
                severity : 'error',
                summary  : 'Formulaire incorrect',
                detail   : `${collectorDuplicates.map((c) => c.fullname).join(', ')} ${
                    collectorDuplicates.length > 1 ? 'sont affectés' : 'est affecté'
                } sur plusieurs tournées.`
            });
            return;
        }

        if (submitAction === EPlanningFormSubmitAction.SAVE) {
            confirmDialog({
                header  : 'Confirmation de sauvegarde',
                message : () => {
                    let msg = `Voulez-vous sauvegarder la version`;
                    if (planningPageState.redis_version_description.key === PLANNING_CALCULATE_KEY_PUBLISHED) {
                        msg += ` publiée ?`;
                    } else {
                        msg += ` brouillon du ${moment
                            .unix(planningPageState.redis_version_description.timestamp || 0)
                            .format('DD/MM/YYYY à HH:mm:ss')} - ${
                            admins[planningPageState.redis_version_description.admin_id as string].fullname
                        } ?`;
                    }
                    return msg;
                },
                accept      : () => handleSave(calculatedPlanningsState),
                acceptLabel : 'Sauvegarder',
                rejectLabel : 'Annuler'
            });
            return;
        }

        setPreSubmitDialogIsOpen(true);
    }, [err, plannings, bbngRequest, submitAction, planningPageState, calculatedPlanningsState]);

    const handleSave = React.useCallback(
        async (data: PlanningFormState) => {
            setLoading(true);
            setLastAction(EPlanningFormSubmitAction.SAVE);
            const savedPlannings = await bbngRequest<PlanningRoFront[]>({
                method  : 'PATCH',
                url     : urlApiBuilder.planningSave(),
                payload : {
                    body: mapStateToSaveDto(data, context)
                }
            });

            if (savedPlannings.success && savedPlannings.response?.data.ro) {
                const savedPlanningsFetched = savedPlannings.response?.data.ro;
                if (savedPlanningsFetched?.length > 0) {
                    const version = savedPlanningsFetched[0].planning_version_description;
                    version &&
                        setPlanningPageState((curr) => ({
                            ...curr,
                            redis_version_description: version
                        }));
                }
                await fetchAll(bbngRequest);
            }
            setLoading(false);
        },
        [err, plannings, bbngRequest, setPlanningPageState, planningPageState, fetchAll]
    );

    const handleUnassign = React.useCallback(
        async (data: PlanningFormState) => {
            setLoading(true);
            setLastAction(EPlanningFormSubmitAction.SAVE);
            const response = await bbngRequest<PlanningRo[]>({
                method  : 'PATCH',
                url     : urlApiBuilder.planningUnassign(),
                payload : {
                    body: mapStateToUnassignDto(data, context)
                }
            });

            if (response.success && response.response?.data.ro) {
                /**
                 * Once unassigned, we need to refresh all the plannings and ccs
                 */
                await getPlannings(bbngRequest);
                await getCCService(bbngRequest);
                await getCCAdministrative(bbngRequest);
            }
            setLoading(false);
        },
        [err, plannings, bbngRequest, getPlannings, getCCService, getCCAdministrative]
    );

    const getCallbackFromSubmitAction = (action: EPlanningFormSubmitAction) => {
        switch (action) {
            case EPlanningFormSubmitAction.CALCULATE:
                return (e: PlanningFormState) => {
                    if (e.settings.calcul_method === EPlanningCalculMethod.Classic) {
                        setClusterConfigDialogIsOpen(true);
                    } else {
                        setPreSubmitDialogIsOpen(false);
                        handleCalculate(
                            mapStateToCalculateDtoWithoutCluster({
                                state         : e,
                                context,
                                unassigned_cc : ([] as CCRo[]).concat(unnassignedCCService, unnassignedCCAdministrative),
                                ccsService,
                                ccsAdministrative,
                                zoneIds       : selectedZoneIds
                            })
                        );
                        setCalculatedPlanningsState(e);
                    }
                };
            case EPlanningFormSubmitAction.SAVE:
                return handleSave;
            case EPlanningFormSubmitAction.UNASSIGN:
                return handleUnassign;
        }
    };

    const formik = useFormik<PlanningFormState>({
        initialValues : mapApiDataToState([...Object.values(plannings)], trucksByPlanning, collectorsByPlanning),
        onSubmit      : handlePreSubmit
    });

    useEffect(() => {
        const formikState: PlanningFormState = mapApiDataToState(
            [...Object.values(plannings)],
            trucksByPlanning,
            collectorsByPlanning
        );
        formik.setValues(formikState);
    }, [plannings]);

    const prepareUnassign = React.useCallback(() => {
        formik.setFieldValue(
            'shifts',
            Object.fromEntries(
                Object.entries(formik.values.shifts).map(([key, value]) => [
                    key,
                    {
                        ...value,
                        selected: false
                    }
                ])
            )
        );
        setSubmitAction(EPlanningFormSubmitAction.UNASSIGN);
    }, [formik, setSubmitAction]);

    const handleChange = React.useCallback(
        (value: PlanningModulesStates, errors: PlanningModulesErrorStates, id: string): void => {
            if (equal(formik.values[id as keyof PlanningFormState], value)) return;
            const newValue = produce(formik.values, (draft) => {
                draft[id as keyof PlanningFormState] = value as any;
            });
            formik.setValues(newValue);

            if (equal(err[id as keyof PlanningFormErrorState], errors)) return;
            setErr((prev) => ({ ...prev, [id]: errors }));
        },

        [formik.setValues, setErr, err, formik.values]
    );

    if (loading) return <PageLoader loading actionType="calculate_planning" />;

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Header>
                    <PlanningLeftHandInputsContainer>
                        <div className="column-container">
                            <PlanningModules.Settings
                                id="settings"
                                value={formik.values.settings}
                                result={handleChange}
                                readOnly={readOnly}
                                displayError={formik.submitCount > 0}
                                type={context.type}
                            />
                            <HorizontalContainer>
                                <ZoomContainer>
                                    <i
                                        className="pi pi-search-minus"
                                        style={{ fontSize: '1em', cursor: 'pointer', paddingRight: '10px' }}
                                        onClick={() => setZoom(zoom - 0.1)}
                                    ></i>
                                    <input
                                        type="range"
                                        min="1"
                                        max="10"
                                        step={0.1}
                                        value={zoom}
                                        onChange={(e) => setZoom(Number(e.target.value))}
                                    />
                                    <i
                                        className="pi pi-search-plus"
                                        style={{ fontSize: '1em', cursor: 'pointer', paddingLeft: '10px' }}
                                        onClick={() => setZoom(zoom + 0.1)}
                                    ></i>
                                </ZoomContainer>
                                <PlanningFilters
                                    filterClassNames={filterClassNames}
                                    setFilterClassNames={setFilterClassNames}
                                />
                            </HorizontalContainer>
                        </div>
                    </PlanningLeftHandInputsContainer>

                    <PlanningRightHandInputsContainer>
                        {!readOnly && (
                            <div className="button-part">
                                <div className="button-row">
                                    <Button
                                        className="p-button-sm"
                                        icon="pi pi-play"
                                        label="Calculer le planning"
                                        type="submit"
                                        onClick={() => setSubmitAction(EPlanningFormSubmitAction.CALCULATE)}
                                    />
                                    <Button
                                        className="p-button-sm p-button-secondary"
                                        icon="pi pi-play"
                                        label="Désassigner des chauffeurs"
                                        type="submit"
                                        onClick={prepareUnassign}
                                        disabled={
                                            planningPageState.redis_version_description.key !==
                                            PLANNING_CALCULATE_KEY_PUBLISHED
                                        }
                                    />
                                    <Button
                                        className="p-button-sm p-button-secondary"
                                        icon="pi pi-save"
                                        label="Sauvegarder le planning"
                                        type="submit"
                                        onClick={() => setSubmitAction(EPlanningFormSubmitAction.SAVE)}
                                    />
                                </div>
                                <div className="button-row">
                                    <GlobalConfigButton />
                                    <ExportPlanningButton />
                                    <GetPlanningInstanceButton />
                                </div>
                            </div>
                        )}
                        <Button
                            className="p-button-sm p-button-secondary p-button-rounded p-button-outlined"
                            icon="pi pi-question"
                            type="button"
                            onClick={() => setHelpDialogIsOpen(true)}
                        />
                    </PlanningRightHandInputsContainer>
                </Header>
                <FiltersContainer classnames={filterClassNames}>
                    <PlanningModules.Shifts
                        id="shifts"
                        value={formik.values.shifts}
                        displayError={formik.submitCount > 0}
                        readOnly={readOnly}
                        result={handleChange}
                        zoom={zoom}
                        type={planningPageState.type}
                    />
                </FiltersContainer>
            </form>
            <Dialog
                visible={helpDialogIsOpen}
                onHide={() => setHelpDialogIsOpen(false)}
                header="Aide sur le planning"
                draggable={false}
                blockScroll
                contentStyle={{ overflow: 'scroll' }}
            >
                <PlanningHelpModal />
            </Dialog>
            <Dialog
                visible={preSubmitDialogIsOpen}
                onHide={() => setPreSubmitDialogIsOpen(false)}
                header={`Tournées à ${mapPlanningFormSubmitAction(submitAction)}`}
                draggable={false}
                blockScroll
                contentStyle={{ overflow: 'scroll' }}
            >
                <PlanningPreSubmitModal
                    data={submitAction === EPlanningFormSubmitAction.SAVE ? calculatedPlanningsState : formik.values}
                    setModalIsOpen={setPreSubmitDialogIsOpen}
                    submitCallback={getCallbackFromSubmitAction(submitAction)}
                    setSelectedPlannings={setSelectedPlannings}
                    setSelectedZoneIds={setSelectedZoneIds}
                    selectedZoneIds={selectedZoneIds}
                />
            </Dialog>
            <Dialog
                visible={clusterConfigDialogIsOpen}
                onHide={() => setClusterConfigDialogIsOpen(false)}
                header={`Configuration du calcul en clustering`}
                draggable={false}
                blockScroll
                contentStyle={{ overflow: 'scroll' }}
            >
                <PlanningClusterConfiguration
                    data={selectedPlannings}
                    setModalIsOpen={setClusterConfigDialogIsOpen}
                    submitCallback={handleCalculate}
                    context={context}
                    setCalculatedPlanningsState={setCalculatedPlanningsState}
                    selectedZoneIds={selectedZoneIds}
                />
            </Dialog>
            <ConfirmDialog />
            <SnackBar
                show={
                    (calculationTriggered && calculatedAndNotSavedDiverge(formik.values, calculatedPlanningsState)) ||
                    lastAction === EPlanningFormSubmitAction.CALCULATE
                }
                content={
                    <>
                        {lastAction === EPlanningFormSubmitAction.CALCULATE && (
                            <span>N'oubliez pas de sauvegarder le dernier calcul.</span>
                        )}
                        {calculationTriggered &&
                            calculatedAndNotSavedDiverge(formik.values, calculatedPlanningsState) && (
                                <span>
                                    Résultats du calcul modifiés, veuillez recalculer le planning avant de sauvegarder.
                                </span>
                            )}
                    </>
                }
            />
        </>
    );
};
