import { DumpsterOnSiteRo, DumpsterOnSiteSynchronizeDto, FrontRo, ProductRo } from '@bbng/util/types';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { fetchDataRelation } from '../../common/dataRelation';
import { defaultErrorToast } from '../../common/syncRequest';
import { urlApiBuilder } from '../../common/urlBuilder';
import { Dialog } from '../../components/Dialog';
import { PageLoader } from '../../components/PageLoader';
import { toast } from '../../components/Toast';
import { StatelessResponse, useRequest } from '../../hooks/StatelessRequest';
import { dumpsterOnSiteListHeaders } from './headers';
import { BoldDetail, StyledDetails, StyledFooter } from './style';

export type SynchronizeButtonProps = {
    refresh: Dispatch<SetStateAction<boolean>>;
};

export const SynchronizeButton: React.FC<SynchronizeButtonProps> = ({ refresh }) => {
    const [isPrepareSyncLoading, setPrepareSyncLoading] = React.useState(false);
    const [isExecSyncLoading, setExecSyncLoading] = React.useState(false);
    const [syncModalVisible, setSyncModalVisible] = React.useState(false);
    const [newDumpstersOnSite, setNewDumpstersOnSite] = React.useState<FrontRo<DumpsterOnSiteRo>[]>([]);
    const bbngRequest = useRequest({
        toastifyError: true
    });

    const [selectedDumpstersOnSite, setSelectedDumpstersOnSite] = React.useState<FrontRo<DumpsterOnSiteRo>[]>([]);

    const prepareSync = useCallback(async () => {
        setPrepareSyncLoading(true);
        try {
            const res = await fetchDataRelation<StatelessResponse<FrontRo<DumpsterOnSiteRo>[]>>(
                await bbngRequest({
                    url          : urlApiBuilder.dumpsterOnSitePrepareSync(),
                    method       : 'GET',
                    sync         : false,
                    retryPolling : 1_000,
                    timeout      : 1_000 * 60 * 30 // 30 minutes
                }),
                {
                    customer_id                 : true,
                    construction_site_id        : true,
                    zone_id                     : true,
                    retrieval_collect_config_id : true
                }
            );
            if (res.success) {
                const data = res.response?.data?.ro;
                if (data) {
                    const dataSelected = data.map((d) => ({ ...d, selected: true }));
                    setNewDumpstersOnSite(dataSelected);
                    setSelectedDumpstersOnSite(dataSelected);
                    setSyncModalVisible(true);
                }
            }
        } catch (e) {
            defaultErrorToast("Une erreur est survenue lors de l'export des collectes");
            console.error(e);
        }
        setPrepareSyncLoading(false);
    }, []);

    const execSync = useCallback(async () => {
        setExecSyncLoading(true);
        try {
            const dto: DumpsterOnSiteSynchronizeDto = {
                dumpsters: selectedDumpstersOnSite.map((d) => {
                    const retrievalIds: string[] = d.retrieval_collect_config_id?.map((r) => r?.['id']);
                    const dumpsterDto: DumpsterOnSiteSynchronizeDto['dumpsters'][0] = {
                        deposited_at: d.deposited_at,

                        product                     : d.product as unknown as ProductRo,
                        construction_site_id        : d.construction_site_id[0]?.id,
                        customer_id                 : d.customer_id[0]?.id,
                        deposit_collect_id          : d.deposit_collect_id[0] as unknown as string,
                        retrieval_collect_config_id : retrievalIds
                    };
                    return dumpsterDto;
                })
            };
            const res = await bbngRequest({
                url     : urlApiBuilder.dumpsterOnSiteExecSync(),
                method  : 'POST',
                payload : {
                    body: dto
                },
                sync         : false,
                timeout      : 1_000 * 60 * 30, // 30 minutes
                retryPolling : 1_000
            });
            if (res.success) {
                const count = res.response?.data?.count;
                refresh(true);
                setSyncModalVisible(false);
                toast({
                    severity : 'success',
                    summary  : 'Succès',
                    detail   : `${count} bennes sur chantier ont été créées !`
                });
            }
        } catch (e) {
            defaultErrorToast("Une erreur est survenue lors de l'export des collectes");
            console.error(e);
        }
        setExecSyncLoading(false);
    }, [selectedDumpstersOnSite, refresh]);

    if (isExecSyncLoading) {
        return <PageLoader loading actionType="create" />;
    }

    return (
        <>
            <Button
                type="button"
                loading={isPrepareSyncLoading}
                loadingIcon="pi pi-spin pi-spinner"
                label="Synchroniser l'historique"
                icon="pi pi-"
                tooltipOptions={{ position: 'left' }}
                onClick={() => prepareSync()}
            />
            <Dialog
                header="Sélectionner les bennes sur chantier à créer"
                visible={syncModalVisible}
                onHide={() => setSyncModalVisible(false)}
                footer={
                    <StyledFooter>
                        <StyledDetails>
                            <span>{newDumpstersOnSite.length} résultats</span>
                            <span>-</span>
                            <BoldDetail>{selectedDumpstersOnSite.length} sélectionnés</BoldDetail>
                        </StyledDetails>
                        <Button
                            type="button"
                            label={`Créer ${selectedDumpstersOnSite.length} bennes sur chantier`}
                            onClick={execSync}
                        />
                    </StyledFooter>
                }
            >
                <DataTable
                    emptyMessage={'Aucune benne manquante'}
                    value={newDumpstersOnSite}
                    dataKey="id"
                    selectionMode="multiple"
                    selection={selectedDumpstersOnSite}
                    onSelectionChange={(e) => setSelectedDumpstersOnSite(e.value)}
                >
                    <Column selectionMode="multiple" />
                    {dumpsterOnSiteListHeaders.map((header) => (
                        <Column key={header.name} field={header.field} header={header.name} body={header.component} />
                    ))}
                </DataTable>
            </Dialog>
        </>
    );
};
