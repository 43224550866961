import Joi from 'joi';

import { AddressCreateSchema } from '../address';
import { ECollectCharacteristic } from '../collect';
import { ContactDtoSchema } from '../contact';
import { DocumentCreateDtoSchema } from '../document/document.schema';
import { EPlanningRegion } from '../planning';

export const LineCreateDtoSchema = Joi.object({
    id       : Joi.string().required(),
    quantity : Joi.number().positive().required()
});

export const CollectConfigDetailsCreateSchema = Joi.object({
    prepaid   : Joi.boolean().required(),
    to_date   : Joi.date().iso().required(),
    comment   : Joi.string().allow(''),
    from_date : Joi.date().iso().required(),
    zone      : Joi.object({
        id        : Joi.string().required(),
        name      : Joi.string().required(),
        metropole : Joi.string()
            .valid(...Object.keys(EPlanningRegion))
            .required()
    }),
    log_contact: Joi.when('construction_site_contact', {
        is        : Joi.exist(),
        then      : Joi.array().items(ContactDtoSchema),
        otherwise : Joi.array().items(ContactDtoSchema).min(1).required()
    }),
    construction_site_contact : Joi.array().items(ContactDtoSchema),
    characteristics           : Joi.array().items(...Object.values(ECollectCharacteristic)),
    already_available_date    : Joi.object({
        to_date   : Joi.date().iso().required(),
        from_date : Joi.date().iso().required()
    }),
    waiting_time_minutes : Joi.number().min(45),
    retrieval_date       : Joi.date().iso(),

    execution_time_minutes    : Joi.number(),
    address_shipping          : AddressCreateSchema,
    construction_site_id      : Joi.array().items(Joi.string()).max(1),
    presta_id                 : Joi.array().items(Joi.string()),
    dumpster_on_site_id       : Joi.array().items(Joi.string()).max(1).optional(),
    deposit_collect_config_id : Joi.array().items(Joi.string()).max(1).optional()
});

export const CollectConfigOrderCreateSchema = Joi.object({
    lines   : Joi.array().items(LineCreateDtoSchema).min(1).required(),
    details : CollectConfigDetailsCreateSchema
});

export const OrderCreateDtoSchema = Joi.object({
    user_id     : Joi.array().items(Joi.string()).max(1),
    admin_id    : Joi.array().items(Joi.string()).length(1).required(),
    customer_id : Joi.array().items(Joi.string()).length(1).required(),

    collect_configs: Joi.array().items(CollectConfigOrderCreateSchema).required(),

    conditions_accepted           : Joi.boolean().required(),
    order_sheet_number            : Joi.string().allow(''),
    documents                     : Joi.array().items(DocumentCreateDtoSchema),
    existing_document_id          : Joi.array().items(Joi.string()).optional().allow(null),
    taken_from                    : Joi.string().optional(), // BO by default
    confirmation_email_recipients : Joi.array().when('user_id', {
        is   : [Joi.array().length(0)],
        then : Joi.array()
            .items(Joi.string().email({ tlds: false }))
            .min(1)
            .required(),
        otherwise: Joi.array().length(0).optional()
    }),
    callback_success_url : Joi.string().uri().optional(),
    callback_cancel_url  : Joi.string().uri().optional()
});

export const OrderEditDtoSchema = Joi.object({
    order_sheet_number : Joi.string().required(),
    documents          : Joi.array().items(DocumentCreateDtoSchema)
});

export const OrderInstantDeliveryDtoSchema = Joi.object({
    customer_id          : Joi.string().required(),
    products             : Joi.array().items(LineCreateDtoSchema).min(1).required(),
    documents            : Joi.array().items(DocumentCreateDtoSchema),
    construction_site_id : Joi.string().required()
});

export const OrderCalculateCartPriceDtoSchema = Joi.object({
    products             : Joi.array().items(LineCreateDtoSchema).min(1).required(),
    zone_id              : Joi.string().required(),
    construction_site_id : Joi.string().required(),
    date                 : Joi.string().required(),
    customer_id          : Joi.string().optional()
});
