import Dinero from 'dinero.js';
import moment from 'moment';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { MenuItemCommandParams } from 'primereact/menuitem';
import { Tag } from 'primereact/tag';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { displayVolumeFromProducts, formatE164ToInternational } from '@bbng/util/misc';
import {
    CCRoFront,
    CCServiceRo,
    CC_FAMILY,
    CC_STATUS,
    CollectConfigQuery,
    EPlanningType,
    FrontRo,
    IContact
} from '@bbng/util/types';

import { fetchDataRelation } from '../../common/dataRelation';
import { mapCollectConfigStatus, mapPlanningConfigType } from '../../common/enumMapper';
import { COLLECT_CONFIG_ORCHESTRATION_BASE_URL, urlApiBuilder, urlBuilder } from '../../common/urlBuilder';
import { Listing } from '../../components/Layout';
import { PageLoader } from '../../components/PageLoader';
import useQuery from '../../hooks/Query';
import { useRequest } from '../../hooks/StatelessRequest';
import RelationAutocomplete from '../../modules/common/RelationAutocomplete';
import { mapStatusToIcon, mapStatusToSeverity } from '../CollectConfigs';
import { FiltersContainer } from '../CollectConfigs/style';
import { ListingContainer } from './style';
import { Dialog } from '../../components/Dialog';
import { collectConfigDescription } from '../../components/StepOverview';
import CollectConfigCancelForm from '../../modules/collect-config-cancel-form';
import { ExportHandler } from './export-modal';

type CollectConfigRo = CCServiceRo;

type QueryParams = {
    customer_id?: string;
    type?: EPlanningType;
};

const typeOptions = Object.values(EPlanningType).map((type) => ({
    name  : mapPlanningConfigType(type),
    value : type
}));

const GROUP_ROW_KEY = 'order_number';

export const OrdersListing: React.FC = (): JSX.Element => {
    const { query } = useQuery<QueryParams>();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [customer_id, setCustomerId] = useState<string | undefined>(query.customer_id);
    const [customerName, setCustomerName] = useState<string | undefined>();
    const [type, setType] = useState<EPlanningType | undefined>(query.type);
    const bbngRequest = useRequest({
        toastifyError   : true,
        toastifySuccess : true
    });

    const [triggerPageRefresh, setTriggerPageRefresh] = useState<boolean>(false);
    useEffect(() => {
        setTriggerPageRefresh(false);
    }, [triggerPageRefresh]);

    const [collectConfigToCancel, setCollectConfigToCancel] = React.useState<CCRoFront>();
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = React.useState(false);
    const handleActionRowCancel = (e: MenuItemCommandParams) => {
        const index = e.item['rowInfo']['rowIndex'];
        const collectConfig = e.item['rowInfo']['props']['value'][index];
        setCollectConfigToCancel(collectConfig);
        setCancelDialogIsOpen(true);
    };

    const handleActionRowPaid = async (e: MenuItemCommandParams) => {
        const index = e.item['rowInfo']['rowIndex'];
        const cc = e.item['rowInfo']['props']['value'][index] as FrontRo<CollectConfigRo>;
        const order = cc.order_id[0];

        confirmDialog({
            header  : `Déclarer ${order?.number} comme payée`,
            message : `Êtes-vous sûr de vouloir déclarer la commande ${order?.number} comme payée ?`,
            accept  : async () => {
                setLoading(true);
                await bbngRequest<CollectConfigRo>({
                    method : 'PATCH',
                    url    : urlApiBuilder.orderPay(order?.id)
                });
                setLoading(false);

                setTriggerPageRefresh(true);
            },
            acceptLabel : 'Confirmer',
            rejectLabel : 'Annuler'
        });
    };

    const handleActionRowResendLink = async (e: MenuItemCommandParams) => {
        const index = e.item['rowInfo']['rowIndex'];
        const cc = e.item['rowInfo']['props']['value'][index] as FrontRo<CollectConfigRo>;
        const order = cc.order_id[0];

        confirmDialog({
            header  : `Renvoyer un lien de paiement`,
            message : `Êtes-vous sûr de vouloir renvoyer un lien de paiement pour la commande ${order?.number} ?`,
            accept  : async () => {
                setLoading(true);
                await bbngRequest<CollectConfigRo>({
                    method : 'PATCH',
                    url    : urlApiBuilder.orderResendLink(order?.id)
                });
                setLoading(false);

                setTriggerPageRefresh(true);
            },
            acceptLabel : 'Confirmer',
            rejectLabel : 'Annuler'
        });
    };

    const handleActionRowDuplicate = async (e: MenuItemCommandParams) => {
        const index = e.item['rowInfo']['rowIndex'];
        const cc = e.item['rowInfo']['props']['value'][index] as FrontRo<CollectConfigRo>;
        const order = cc.order_id[0];

        navigate(urlBuilder.orderDuplicate(order.id));
    };

    if (loading) {
        return <PageLoader loading actionType="create" />;
    }

    return (
        <ListingContainer>
            <Listing<CollectConfigQuery, CollectConfigRo>
                url={COLLECT_CONFIG_ORCHESTRATION_BASE_URL}
                addButtonUrl={urlBuilder.orderCreate()}
                endpoint="collect-configs"
                name="Commande"
                pageUrl={urlBuilder.collectConfigBase()}
                pluralName="Commandes"
                enrichData={async (data) => {
                    data.data.ro = await fetchDataRelation(data.data.ro ?? [], { order_id: true, customer_id: true });
                    return data;
                }}
                actionRows={[
                    {
                        label   : 'Renvoyer un lien de paiement',
                        icon    : 'pi pi-link',
                        command : (e) => handleActionRowResendLink(e)
                    },
                    {
                        label   : 'Déclarer comme payée',
                        icon    : 'pi pi-exclamation-triangle',
                        command : (e) => handleActionRowPaid(e)
                    },
                    {
                        label   : 'Dupliquer',
                        icon    : 'pi pi-clone',
                        command : (e) => handleActionRowDuplicate(e)
                    },
                    {
                        label   : 'Annuler',
                        icon    : 'pi pi-exclamation-triangle',
                        command : (e) => handleActionRowCancel(e)
                    }
                ]}
                queryParams={{
                    customer_id,
                    no_administrative : true,
                    presta            : 'include',
                    type
                }}
                searchPlaceholder={`Rechercher par numéro de commande, collecte ou adresse`}
                leftHandedComponent={
                    <FiltersContainer>
                        <RelationAutocomplete.Customer
                            placeholder="Filtrer par client"
                            onSelect={(customer) => {
                                setCustomerId(customer?.id);
                                setCustomerName(customer?.name);
                            }}
                            onUnselect={() => {
                                setCustomerId(undefined);
                                setCustomerName(undefined);
                            }}
                        />
                        <Dropdown
                            value={type}
                            onChange={(e) => setType(e.value)}
                            placeholder="Filtrer par type"
                            optionValue="value"
                            optionLabel="name"
                            showClear
                            options={typeOptions}
                        />
                        <ExportHandler query={query} customerName={customerName} />
                    </FiltersContainer>
                }
                headers={[
                    {
                        name      : 'Type',
                        field     : 'type',
                        component : (data: FrontRo<CollectConfigRo>) => (
                            <div>
                                <Tag
                                    className="mr-2"
                                    severity={data.type === EPlanningType.BIG_BAG ? 'success' : 'info'}
                                    value={mapPlanningConfigType(data.type)}
                                />
                            </div>
                        )
                    },
                    {
                        name      : 'Numéro commande',
                        field     : GROUP_ROW_KEY as keyof CollectConfigRo,
                        component : (data: FrontRo<CollectConfigRo>) => {
                            let details = '';
                            if (data.status === CC_STATUS.ORDER_TO_PAY) details = 'à payer';
                            if (data.prepaid) details = 'prépayée';
                            return (
                                <a href={urlBuilder.orderView(data.order_id[0]?.id)}>
                                    {data.order_number} {details && `(${details})`}
                                </a>
                            );
                        }
                    },
                    {
                        name      : 'Client',
                        field     : GROUP_ROW_KEY as keyof CollectConfigRo,
                        component : (data: FrontRo<CollectConfigRo>) => (
                            <span>
                                {data.customer_id[0]
                                    ? (data.customer_id[0] as unknown as { name: string }).name
                                    : 'Non renseigné'}
                            </span>
                        )
                    },
                    {
                        name      : 'Adresse',
                        field     : GROUP_ROW_KEY as keyof CollectConfigRo,
                        component : (data: FrontRo<CollectConfigRo>) => <span>{data.address.formatted_name}</span>
                    },
                    {
                        name      : 'Contact chantier',
                        field     : GROUP_ROW_KEY as keyof CollectConfigRo,
                        component : (data: FrontRo<CollectConfigRo>) => {
                            if (data.construction_site_contact && data.construction_site_contact?.length > 0) {
                                return formatContact(data.construction_site_contact[0]);
                            } else {
                                return <span>Aucun contact</span>;
                            }
                        }
                    },
                    {
                        name      : 'Date de collecte',
                        field     : GROUP_ROW_KEY as keyof CollectConfigRo,
                        width     : 90,
                        component : (data: FrontRo<CollectConfigRo>) => (
                            <span>{moment(data.from_date).format('DD/MM/YYYY')}</span>
                        )
                    },
                    {
                        name      : 'Créneau',
                        field     : GROUP_ROW_KEY as keyof CollectConfigRo,
                        component : (data: FrontRo<CollectConfigRo>) => (
                            <span>
                                {moment(data.from_date).format('HH:mm')} - {moment(data.to_date).format('HH:mm')}
                            </span>
                        )
                    },
                    {
                        name      : 'Numéro collecte',
                        field     : 'number' as keyof CollectConfigRo,
                        width     : 90,
                        component : (data: FrontRo<CollectConfigRo>) => (
                            <a href={urlBuilder.collectConfigView(data.id)}>{data.number}</a>
                        )
                    },
                    {
                        name      : 'Volume à collecter',
                        field     : 'products',
                        width     : 90,
                        component : (data: FrontRo<CollectConfigRo>) => {
                            const volumeToCollect = displayVolumeFromProducts(data.products, data.family);
                            return <span>{volumeToCollect ?? 'N/A'}</span>;
                        }
                    },
                    {
                        name      : 'Origine de la commande',
                        field     : 'taken_from' as keyof CollectConfigRo,
                        component : (data: FrontRo<CollectConfigRo>) => {
                            return <span>{data.order_id[0].taken_from}</span>;
                        }
                    },
                    {
                        name      : 'Statut',
                        field     : 'status' as keyof CollectConfigRo,
                        component : (data: FrontRo<CollectConfigRo>) =>
                            data.presta_id.length > 0 ? (
                                <div>
                                    <Tag
                                        className="mr-2"
                                        value={'Prestataire'}
                                        severity={'warn'}
                                        icon={'pi pi-ticket'}
                                    />
                                    {([CC_STATUS.TO_PLAN, CC_STATUS.PLANNED] as CC_STATUS[]).includes(data.status) ===
                                        false && (
                                        <>
                                            <div style={{ height: '5px', width: '5px' }} /> {/* Spacer */}
                                            <Tag
                                                className="mr-2"
                                                value={mapCollectConfigStatus(data.status)}
                                                severity={mapStatusToSeverity(data.status)}
                                                icon={mapStatusToIcon(data.status)}
                                            />
                                        </>
                                    )}
                                </div>
                            ) : (
                                <div>
                                    <Tag
                                        className="mr-2"
                                        value={mapCollectConfigStatus(data.status)}
                                        severity={mapStatusToSeverity(data.status)}
                                        icon={mapStatusToIcon(data.status)}
                                    />
                                </div>
                            )
                    },
                    {
                        name      : 'Prix HT',
                        field     : 'price' as keyof CollectConfigRo,
                        component : (data: FrontRo<CollectConfigRo>) => {
                            return (
                                <span>
                                    {Dinero({
                                        amount   : data.price.discounted_net.amount ?? 0,
                                        currency : data.price.discounted_net.currency ?? 'EUR'
                                    }).toFormat('$0.00')}
                                </span>
                            );
                        }
                    },
                    {
                        name      : 'Modifiable',
                        field     : 'freeze' as keyof CollectConfigRo,
                        component : (data: FrontRo<CollectConfigRo>) => <p> {data.freeze === true ? '🔒' : '✅'} </p>
                    }
                ]}
                disableEditAction
                disableArchiveAction
                displaySelectColumn={false}
                hideBulkMenu
                triggerPageRefresh={triggerPageRefresh}
                rowGroupMode="rowspan"
                groupRowsBy={GROUP_ROW_KEY}
                scrollable={false}
                rowHover={false}
                actionColumnField={GROUP_ROW_KEY}
                minSearchLength={1}
            />
            <ConfirmDialog />
            {collectConfigToCancel && (
                <Dialog
                    visible={cancelDialogIsOpen}
                    onHide={() => setCancelDialogIsOpen(false)}
                    header={`Annulation - ${collectConfigToCancel && collectConfigDescription(collectConfigToCancel)}`}
                    draggable={false}
                    resizable={false}
                    blockScroll
                    contentStyle={{ overflow: 'scroll' }}
                >
                    <CollectConfigCancelForm
                        submitCallback={() => setTriggerPageRefresh(true)}
                        setModalIsOpen={setCancelDialogIsOpen}
                        dataId={collectConfigToCancel.id}
                        isAdministrative={collectConfigToCancel.family === CC_FAMILY.ADMINISTRATIVE}
                    />
                </Dialog>
            )}
        </ListingContainer>
    );
};

const formatContact = (contact: IContact): JSX.Element => {
    return (
        <ContactContainer>
            <span>
                {contact.firstname} {contact.lastname}
            </span>
            <br />
            <Anchor href={contact.phone_number ? 'tel:' + formatE164ToInternational(contact.phone_number) : undefined}>
                <i className="pi pi-phone" />
                {contact.phone_number ? formatE164ToInternational(contact.phone_number) : ''}
            </Anchor>
        </ContactContainer>
    );
};

const ContactContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

const Anchor = styled.a`
    word-break: break-all;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #343699;
`;
