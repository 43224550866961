import moment from 'moment';
import { Divider } from 'primereact/divider';
import { Tooltip } from 'primereact/tooltip';
import styled from 'styled-components';

import {
    AddressRo,
    CCRo,
    CCServiceRo,
    CollectService,
    isCCService,
    PlanningShiftStep,
    PlanningShiftStepCategory,
    ProductInCCOrCO
} from '@bbng/util/types';

import { mapCollectCategoryText, mapCollectConfigFamilyText } from '../../../common/enumMapper';
import { AddressOverview } from '../../../components/AddressOverview';
import { getCollectAddressFromStep, isStepEmptying, isStepService, isStepServiceOrAdmin } from './helpers';
import { usePlanningV2Store } from '../../../hooks/PlanningStoreV2';
import { useContext } from 'react';
import { PlanningContext } from '../../../context/Planning';
import { HorizontalContainer } from './shiftContent.style';

export type ShiftContentTooltipProps = {
    step: PlanningShiftStep;
    stepId: string;
    narrow: boolean;
    splitted: boolean;
    hasComment: boolean;
    isOutOfSchedule: boolean;
    isSingleFlow: boolean;
};
export const ShiftContentTooltip: React.FC<ShiftContentTooltipProps> = ({
    step,
    stepId,
    narrow,
    splitted,
    hasComment,
    isOutOfSchedule,
    isSingleFlow
}: ShiftContentTooltipProps): JSX.Element => {
    const { trucks, collects, customers, landfills, ccsService, ccsAdministrative } = usePlanningV2Store();
    const { truck_id } = useContext(PlanningContext);
    const truck = trucks[truck_id];
    const collect = step.collect_id ? collects[step.collect_id] : undefined;
    const stepServiceOrAdmin = isStepServiceOrAdmin(step) ? step : undefined;
    const stepService = isStepService(step) ? step : undefined;
    let cc: CCRo | undefined = undefined;
    if (stepService) {
        cc = ccsService[stepService.collect_config_id];
    } else if (stepServiceOrAdmin) {
        cc = ccsAdministrative[stepServiceOrAdmin.collect_config_id];
    }
    let products: ProductInCCOrCO[] | undefined = undefined;
    if (isCCService(cc)) {
        if (stepService?.is_splitted) {
            products = cc.splitted_informations[stepService.splitted_idx].products;
        } else {
            products = cc.products;
        }
    }
    const customer = stepService?.customer_id ? customers[stepService.customer_id] : undefined;
    const landfill = isStepEmptying(step) ? landfills[step.landfill_id] : undefined;
    const address = getCollectAddressFromStep(step.category, cc, truck, landfill);
    return (
        <StyledTooltip target={`.programbox-${stepId.replace(':', '')}`} position="top">
            {(cc as CCServiceRo)?.number && (
                <>
                    <TooltipEntry margin="8px 0 0">{(cc as CCServiceRo)?.number}</TooltipEntry>
                    {(cc as CCServiceRo)?.order_number && (
                        <TooltipEntry margin="8px 0 0">{(cc as CCServiceRo)?.order_number}</TooltipEntry>
                    )}
                    <Divider />
                </>
            )}
            {address && Object.keys(address)?.length > 0 && (
                <>
                    <TooltipEntry>
                        <AddressOverview address={address as AddressRo} flex={false} />
                    </TooltipEntry>
                </>
            )}
            {narrow && <TooltipEntry margin="8px 0 0">Rue étroite</TooltipEntry>}
            {splitted && <TooltipEntry margin="8px 0 0">Collecte scindée</TooltipEntry>}
            {[PlanningShiftStepCategory.SERVICE, PlanningShiftStepCategory.ADMINISTRATIVE].includes(step.category) ? (
                <TooltipEntry margin="8px 0 0">{mapCollectConfigFamilyText(cc?.family)}</TooltipEntry>
            ) : (
                <TooltipEntry margin="8px 0 0">{mapCollectCategoryText(step.category)}</TooltipEntry>
            )}
            <Divider />
            {cc && (
                <>
                    <TooltipEntry>
                        Demandé:
                        <TimeAndProducts start={cc?.from_date} end={cc?.to_date} products={[]} />
                    </TooltipEntry>
                    <Divider />
                </>
            )}
            <TooltipEntry>
                Prévu:
                <TimeAndProducts start={step.scheduled_at} end={step.scheduled_end_at} products={products || []} />
            </TooltipEntry>
            {(collect as CollectService)?.informations?.collected_items && (
                <>
                    <Divider />
                    Réalisé
                    <TimeAndProducts
                        start={collect?.arrived_at as string}
                        end={collect?.completed_at as string}
                        products={(collect as CollectService)?.informations.collected_items}
                    />
                </>
            )}
            {customer && (
                <>
                    <Divider />
                    <TooltipEntry margin="8px 0 0">{customer?.name}</TooltipEntry>
                </>
            )}
            {hasComment && (
                <>
                    <Divider />
                    <TooltipEntry color="var(--teal-200)">
                        <HorizontalContainer>
                            <i className="pi pi-comments" />
                            {cc?.comment}
                        </HorizontalContainer>
                    </TooltipEntry>
                </>
            )}
            {isSingleFlow && (
                <>
                    <Divider />
                    <TooltipEntry color="var(--orange-200)">
                        <HorizontalContainer>
                            <i className="pi pi-exclamation-triangle" />
                            <p>La collecte contient un ou des produits triés</p>
                        </HorizontalContainer>
                    </TooltipEntry>
                </>
            )}
            {isOutOfSchedule && (
                <>
                    <Divider />
                    <TooltipEntry color="var(--orange-200)">
                        <HorizontalContainer>
                            <i className="pi pi-clock" />
                            <p>Hors créneau</p>
                        </HorizontalContainer>
                    </TooltipEntry>
                </>
            )}
        </StyledTooltip>
    );
};

type TimeAndProductsProps = { start: string; end: string; products: ProductInCCOrCO[] };
const TimeAndProducts: React.FC<TimeAndProductsProps> = ({ start, end, products }): JSX.Element => {
    return (
        <>
            <TooltipEntry>{`ETA: ${moment(start).format('HH:mm')}`}</TooltipEntry>
            <TooltipEntry>{`ETD: ${moment(end).format('HH:mm')}`}</TooltipEntry>
            {products.map((product) => (
                <TooltipEntry key={product.id} margin="4px 0 0">
                    {product.quantity} x {mapCollectConfigFamilyText(product.family)} - {product.volume_m3}m3
                </TooltipEntry>
            ))}
        </>
    );
};

const StyledTooltip = styled(Tooltip)`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const TooltipEntry = styled.div<{ margin?: string; color?: string }>`
    margin: ${(props) => props.margin || 0};
    color: ${(props) => props.color || 'white'};
`;
